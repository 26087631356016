import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createVector,
  type CreateVectorInput,
  deleteVector,
  type DeleteVectorInput,
  updateVector,
  type UpdateVectorInput,
} from '../api/vectors';

import { type DefaultMutationError } from './index';

export const useCreateVector = (
  opts?: Partial<
    UseMutationOptions<Awaited<any>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createScenarioVector'],
    mutationFn: async (input: CreateVectorInput) => {
      const { data }: AxiosResponse<any> = await createVector(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarioVectors'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateVector = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateVector'],
    mutationFn: async (input: UpdateVectorInput) => {
      const { data }: AxiosResponse = await updateVector(input);
      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarioVectors'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteVector = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteVector'],
    mutationFn: async (input: DeleteVectorInput) => {
      const { data }: AxiosResponse = await deleteVector(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarioVectors'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};
