import React from 'react';

import { Button, Dialog } from '@mui/material';

import { useGetProjectById } from '../../hooks';
import { useJoinProject } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';

type ProjectAccessDeniedDialogProps = {
  iProjectId: number;
  open: boolean;
  handleOnClose: () => void;
  handleOnSubmit: () => void;
};

const ProjectAccessDeniedDialog = ({
  iProjectId,
  open,
  handleOnClose,
  handleOnSubmit,
}: ProjectAccessDeniedDialogProps) => {
  const { orgId } = useGetRouteParams();

  const { data: project } = useGetProjectById(
    {
      orgId,
      projectId: iProjectId,
    },
    {
      enabled: !!iProjectId && !!orgId,
    }
  );

  const { mutate: joinProject } = useJoinProject({
    onSuccess: () => {
      handleOnSubmit();
    },
  });

  const handleOnJoinProject = () => {
    joinProject({
      orgId,
      projectId: iProjectId,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alertTitle"
      fullWidth
      aria-describedby="alertContent"
    >
      <div className="flex flex-col gap-6 p-6">
        <h2 className="text-xl font-bold">Project Details</h2>
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <div className="flex">
            <p className="w-[40%]">Project Name:</p>
            <p className="w-full font-bold">{project?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[40%]">Description:</p>
            <p className="w-full font-bold">{project?.description}</p>
          </div>
          <div className="flex">
            <p className="w-[40%]">Created by:</p>
            <p className="w-full font-bold">{project?.created_by.full_name}</p>
          </div>
          <div className="flex">
            <p className="w-[40%]">Project Owner:</p>
            <p className="w-full font-bold">
              {project?.members
                .filter((m) => m.role === 'project_owner')
                .map((m) => m.user.first_name + ' ' + m.user.last_name)}
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <Button onClick={handleOnClose} variant="outlined" tabIndex={1}>
            Cancel
          </Button>
          <Button
            onClick={handleOnJoinProject}
            variant="contained"
            tabIndex={2}
          >
            Join Project
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectAccessDeniedDialog;
