import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ProjectsView from '../components/projects/ProjectsView';
import { useGetUser } from '../hooks/useUserHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';

function Home() {
  const { data: user } = useGetUser();

  const { orgId } = useGetRouteParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Home | ISBE Group';
  }, []);

  useEffect(() => {
    if (!Number(orgId) && user?.organizations) {
      navigate(`/${user.organizations[0].id}/projects/`);
    }
  }, [orgId, user?.organizations]);

  return <ProjectsView />;
}

export default Home;
