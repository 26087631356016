import { Outlet } from 'react-router-dom';

import TopBar from '../common/TopBar';

const ProtectedRoute = () => (
  <div className="flex">
    <div className="w-full">
      <header className="sticky top-0 z-50 w-full">
        <TopBar />
      </header>
      <Outlet />
    </div>
  </div>
);

export default ProtectedRoute;
