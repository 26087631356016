import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Checkbox, Collapse } from '@mui/material';
import _ from 'lodash';

import { useGetModelById } from '../../hooks';
import { useGetRouteParams } from '../../hooks/useUtilsHook';

type CheckboxesCollapseProps = {
  name: string;
  title: string;
  group?: 'default' | 'user';
  items: {
    label: string;
    value: number;
  }[];
};

const CheckboxesCollapse = ({
  title,
  name,
  items,
  group = 'default',
}: CheckboxesCollapseProps) => {
  const [open, setOpen] = useState(true);
  const [checkedAll, setCheckAll] = useState(false);

  const { orgId, projectId, modelId } = useGetRouteParams();

  const { data: model } = useGetModelById(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId && !!modelId,
    }
  );

  const { register, setValue, watch } = useFormContext();

  const defaultName = `${group}.${name}`;

  const data = watch(defaultName);

  const isIndeterminate =
    !checkedAll && Object.keys(data ?? {}).some((key) => data[key]);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const preDefinedExpenses = model?.predefined_expenses ?? [];
    const userDefinedExpense = model?.userdefined_expenses ?? [];

    const expensesByGroup =
      group === 'default' ? preDefinedExpenses : userDefinedExpense;

    const defaultValues = items.reduce(
      (acc, cur) => {
        acc[cur.value] = expensesByGroup.includes(Number(cur.value));
        return acc;
      },
      {} as Record<string, boolean>
    );

    if (_.isEqual(data, defaultValues) || !defaultValues || data) {
      return;
    }
    setValue(defaultName, defaultValues);
  }, [defaultName, items, model]);

  useEffect(() => {
    const isCheckedAll = items.every((item) => data?.[item?.value]);

    setCheckAll(isCheckedAll);
  }, [data, items]);

  const handleToggleAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    const isChecked = !isIndeterminate && (e.target as any).checked;

    setCheckAll(isChecked);

    const all = items.reduce(
      (acc, key) => {
        acc[key.value] = isIndeterminate ? false : isChecked;
        return acc;
      },
      {} as Record<string, boolean>
    );

    setValue(defaultName, all);
  };

  return (
    <div className="px-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#333]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <Checkbox
          checked={checkedAll}
          onClick={handleToggleAll}
          indeterminate={isIndeterminate}
        />
        <label className="text-lg font-bold capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="border-gray-300 mb-4 ml-12 flex flex-col border-l pl-3">
          {data &&
            items.map((expenseSet) => (
              <div
                key={`${defaultName}.${expenseSet.value}`}
                className="flex justify-between"
              >
                <div className="flex items-center gap-1">
                  <Checkbox
                    id={`${defaultName}.${expenseSet.value}`}
                    checked={data[expenseSet.value] || false}
                    {...register(`${defaultName}.${expenseSet.value}`)}
                  />
                  <label htmlFor={`${defaultName}.${expenseSet.value}`}>
                    {expenseSet.label}
                  </label>
                </div>
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CheckboxesCollapse;
