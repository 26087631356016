import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIosNew } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Pencil } from 'lucide-react';

import { useGetModelById } from '../../hooks';
import { useRunModelOutput } from '../../hooks/useModelsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';

import CreateModelFormDialog from './CreateModelFormDialog';
import ModelOutputTabs from './ModelOutputTabs';

const ModelOverview = () => {
  const { modelId, projectId, orgId } = useGetRouteParams();

  const navigate = useNavigate();

  const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);

  const form = useForm();

  const { handleSubmit } = form;

  const { data: model } = useGetModelById(
    {
      modelId,
      projectId,
      orgId,
    },
    {
      enabled: !!modelId && !!projectId && !!orgId,
    }
  );

  const { mutateAsync: runModelOutput } = useRunModelOutput();

  const handleOpenCreateModelDialog = () => {
    setOpenCreateModelDialog(true);
  };

  const handleCloseCreateModelDialog = () => {
    setOpenCreateModelDialog(false);
  };

  const handleGoBack = () => {
    navigate(`/${orgId}/projects/${projectId}/models`);
  };

  const onSubmit = async (data: any) => {
    const { aircrafts, airports } = data;

    const selectedAircrafts = Object.keys(aircrafts)
      .filter((a) => aircrafts[a])
      .flat();

    const selectedAirports = Object.keys(airports)
      .filter((a) => airports[a])
      .flat();

    await runModelOutput({
      orgId,
      projectId,
      modelId,
      data: {
        filters: {
          aircrafts:
            selectedAircrafts.length > 0 ? selectedAircrafts : undefined,
          airports: selectedAirports.length > 0 ? selectedAirports : undefined,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6 pb-6">
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-6">
            <div
              onClick={handleGoBack}
              className="flex items-center gap-4 cursor-pointer"
            >
              <ArrowBackIosNew
                style={{
                  height: '16px',
                  width: '16px',
                }}
              />
              <h1 className="text-2xl font-bold">{model?.name}</h1>
            </div>
            <Pencil
              size={20}
              color="#666666"
              className="cursor-pointer"
              onClick={handleOpenCreateModelDialog}
            />
            {model && (
              <CreateModelFormDialog
                open={openCreateModelDialog}
                title={'Edit model'}
                handleOpenDialog={handleOpenCreateModelDialog}
                handleCloseDialog={handleCloseCreateModelDialog}
                defaultValues={{
                  name: model.name,
                  description: model.description,
                  dataset: model.dataset,
                }}
              />
            )}
          </div>
          {/* @todo: Bring this back when do the sharing feature
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              handleShareToHost(Number(modelId));
            }}
            style={{
              color: '#B8341B',
              borderColor: '#B8341B',
              display: 'flex',
              gap: 4,
              borderRadius: 4,
              padding: '6px 12px',
            }}
          >
            Share To Host
          </Button> */}
        </div>
        <div className="flex flex-col gap-2 border border-[#E4E7EC] px-6 py-4">
          <p className="flex-grow font-bold">Description</p>
          <p>{model?.description}</p>
        </div>
      </div>
      <div className="flex flex-col">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between">
              <h2 className="text-3xl font-bold">Overview</h2>
              <Button
                variant="contained"
                type="submit"
                style={{
                  color: '#fff',
                  height: '35px',
                  backgroundColor: '#B8341B',
                }}
              >
                Run
              </Button>
            </div>
            <ModelOutputTabs />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ModelOverview;
