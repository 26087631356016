import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from '@mui/material';

import EmailSent from '../icons/EmailSent';

type LoginByLinkSentDialogDialogProps = {
  open: boolean;
  handleOnClose: () => void;
};

const LoginByLinkSentDialog = ({
  open,
  handleOnClose,
}: LoginByLinkSentDialogDialogProps) => {
  const { watch } = useFormContext();

  const email = watch('email');

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alertTitle"
      aria-describedby="alertContent"
    >
      <div className="flex flex-col items-center gap-3 p-8">
        <EmailSent />
        <h2 className="text-xl font-bold">Check your email</h2>
        <div className="text-base text-[#666]">
          <p>
            We've sent log in link to <span className="font-bold">{email}</span>
          </p>
        </div>
      </div>
    </Dialog>
  );
};

export default LoginByLinkSentDialog;
