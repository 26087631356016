import { type TypeScenarioVector } from '../common/dataTypes';
import { mainApi } from '../utils/api';

export type CreateVectorInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  scenarioId: number;
  data: {
    name: string;
    description?: string;
    delta: number;
    delta_percentage: number;
    new_value: number;
    criterias: string;
    is_active?: true;
    scenario: number;
  };
};
export type UpdateVectorInput = {
  vectorId: number;
  orgId: number;
  modelId: number;
  projectId: number;
  scenarioId: number;
  data: TypeScenarioVector;
};

export type GetVectorByIdInput = {
  vectorId: number;
  projectId: number;
  modelId: number;
  orgId: number;
};

export type DeleteVectorInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  scenarioId: number;
  vectorId: number;
};

const getVectorById = ({
  orgId,
  projectId,
  vectorId,
  modelId,
}: GetVectorByIdInput) =>
  mainApi.get(
    `/organizations/${orgId}/projects/${projectId}/models/${modelId}/vectors/${vectorId}/`
  );

const createVector = ({
  orgId,
  projectId,
  scenarioId,
  modelId,
  data,
}: CreateVectorInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/vectors/`,
    data
  );

const updateVector = ({
  orgId,
  data,
  vectorId,
  projectId,
  scenarioId,
  modelId,
}: UpdateVectorInput) =>
  mainApi.patch(
    `/organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/vectors/${vectorId}/`,
    data
  );

const deleteVector = ({
  orgId,
  vectorId,
  modelId,
  projectId,
  scenarioId,
}: DeleteVectorInput) =>
  mainApi.delete(
    `/organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/vectors/${vectorId}/`
  );

export { createVector, getVectorById, updateVector, deleteVector };
