import React, { useState } from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import AddForm from '../AddForm';

type AddAircraftDialogProps = {
  defaultValue?: string[];
  open: boolean;
  handlePrimaryAction: (data: string[]) => void;
  handleSecondaryAction: () => void;
};

const AddAircraftDialog = ({
  defaultValue,
  open,
  handlePrimaryAction,
  handleSecondaryAction,
}: AddAircraftDialogProps) => {
  const [aircrafts, setAircrafts] = useState<string[]>([]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <h3 className="text-xl">Add Aircraft</h3>
      </DialogTitle>
      <DialogContent>
        <AddForm
          id="aircraft"
          label="Aircraft"
          defaultFormValue={defaultValue}
          data={aircrafts}
          inputProps={{
            placeholder: 'A330, A320, A321',
          }}
          setData={setAircrafts}
          handlePrimaryAction={handlePrimaryAction}
          handleSecondaryAction={handleSecondaryAction}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddAircraftDialog;
