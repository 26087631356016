import cn from '../utils/cn';

const IconButton = ({
  label,
  icon,
  onClick,
  className,
}: {
  label?: string;
  icon: React.ReactNode;
  onClick?: () => void;
  className?: React.HTMLAttributes<HTMLButtonElement>['className'];
}) => (
  <button
    onClick={onClick}
    type="button"
    className={cn('flex cursor-pointer items-center', {
      'gap-1': icon && label,
      className,
    })}
  >
    {icon}
    <p className="font-semibold">{label}</p>
  </button>
);

export default IconButton;
