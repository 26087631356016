import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import {
  generateMonthList,
  generateWeekList,
  generateYearList,
} from '../utils/helpers';

export const yearlyPeriodList = generateYearList().map((r) => r.value);

export const monthlyPeriodList = generateMonthList()
  .map((m) => generateYearList().map((y) => `${m.value}-${y.value}`))
  .flat();

export const weeklyPeriodList = generateWeekList()
  .map((w) =>
    generateMonthList()
      .map((m) => generateYearList().map((y) => `${w}-${m}-${y}`))
      .flat()
  )
  .flat();

export const ModelCompareTooltip = ({
  numberA,
  numberB,
  children,
}: {
  numberA: number;
  numberB: number;
  children: React.ReactElement;
}) => (
  <Tooltip
    disableFocusListener
    title={
      <div className="flex gap-3">
        <div className="flex flex-col">
          <span>Model A</span>
          <p>{usNumberFormatter.format(numberA)}</p>
        </div>
        <div className="flex flex-col">
          <span>Model B</span>
          <p>{usNumberFormatter.format(numberB)}</p>
        </div>
        <div className="flex flex-col">
          <span>Difference</span>
          <p>{usNumberFormatter.format(numberA - numberB)}</p>
        </div>
      </div>
    }
  >
    {children}
  </Tooltip>
);

const usNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
