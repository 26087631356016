import { authApi, unprotectedAuthApi } from '../utils/api';

export type LoginInput = {
  data: {
    username: string;
    password: string;
  };
};

export type LoginByLinkInput = {
  data: {
    email: string;
  };
};

export type LoginByLinkVerifyInput = {
  data: {
    token: string | undefined;
  };
};

export type RequestAccessInput = {
  data: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

export type ChangePasswordInput = {
  data: {
    current_password: string;
    new_password: string;
  };
};

export type ResetPasswordRequestInput = {
  data: {
    email: string;
  };
};

export type ResetPasswordVerifyInput = {
  data: {
    token: string;
  };
};

export type ResetPasswordInput = {
  data: {
    token: string;
    new_password: string;
  };
};

const login = ({ data }: LoginInput) => unprotectedAuthApi.post('login/', data);

const loginByLink = ({ data }: LoginByLinkInput) =>
  unprotectedAuthApi.post('login_by_email/', data);

const loginByEmailVerify = ({ data }: LoginByLinkVerifyInput) =>
  unprotectedAuthApi.post('login_by_email/verify/', data);

const logout = () => authApi.post('logout/');

const getUsers = () => authApi.get('users/');

const changePassword = ({ data }: ChangePasswordInput) =>
  authApi.post('change_password/', data);

const resetPasswordRequest = ({ data }: ResetPasswordRequestInput) =>
  unprotectedAuthApi.post('reset_password/request/', data);

const resetPasswordVerify = ({ data }: ResetPasswordVerifyInput) =>
  unprotectedAuthApi.post('reset_password/verify/', data);

const resetPassword = ({ data }: ResetPasswordInput) =>
  unprotectedAuthApi.post('reset_password/', data);

export {
  logout,
  login,
  loginByLink,
  loginByEmailVerify,
  getUsers,
  resetPasswordRequest,
  changePassword,
  resetPasswordVerify,
  resetPassword,
};
