import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import _ from 'lodash';
import * as z from 'zod';

import { toastPromise } from '../../common/utils';
import { useGetDatasets, useGetModelById, useUpdateModel } from '../../hooks';
import {
  useGetPreDefinedExpenses,
  useGetUserDefinedExpenses,
} from '../../hooks/useExpenseHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import {
  PredefinedExpensesForm,
  UserDefinedExpensesForm,
} from '../expense/CreateExpenseForm';
import ExpenseCollapse from '../expense/ExpenseCollapse';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const DatasetConfigurationSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const RetirementAgeConfigurationSchema = z.object({
  label: z.string(),
  value: z.string(),
});

type DatasetConfigurationSchemaType = z.infer<
  typeof DatasetConfigurationSchema
>;

type RetirementAgeConfigurationSchemaType = z.infer<
  typeof RetirementAgeConfigurationSchema
>;

type ExpenseOption = {
  label: string;
  value: number;
  type: 'default' | 'custom';
};

const DatasetConfiguration = () => {
  const form = useForm<DatasetConfigurationSchemaType>({
    resolver: zodResolver(DatasetConfigurationSchema),
  });

  const { handleSubmit, setValue, reset, watch, formState } = form;

  const { isLoading } = formState;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const { projectId, orgId, modelId } = useGetRouteParams();

  const { data: datasets } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { mutateAsync: updateModel } = useUpdateModel();

  useEffect(() => {
    if (datasets) {
      reset({
        label: datasets[0].name,
        value: datasets[0].id.toString(),
      });
    }
  }, [datasets]);

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const handleOnSubmit = () => {
    const { value } = watch();

    const datasetData = datasets?.find((d) => _.isEqual(d.id, value));

    if (!datasetData) {
      return;
    }

    const res = updateModel({
      projectId,
      orgId,
      modelId,
      data: {
        dataset: datasetData.id,
      },
    });

    toastPromise({
      promise: res,
      content: "Model's dataset updated successfully",
    });

    setValue('label', datasetData.name);
    handleCloseDatasetDialog();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Dataset</h1>
            <Button
              variant="outlined"
              style={{
                color: '#B8341B',
                borderColor: '#B8341B',
              }}
              onClick={handleOpenDatasetDialog}
            >
              Edit
            </Button>
          </div>
          <div className="w-[30%]">
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectDatasetDialog}
          onClose={handleCloseDatasetDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Select Dataset</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              {!!datasets && (
                <FormSelect
                  name="value"
                  options={datasets.map((dataset) => ({
                    label: dataset.name,
                    value: dataset.id,
                  }))}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseDatasetDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#B8341B',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const RetirementAgeConfiguration = () => {
  const form = useForm<RetirementAgeConfigurationSchemaType>({
    resolver: zodResolver(RetirementAgeConfigurationSchema),
    defaultValues: {
      value: '65',
      label: '65',
    },
  });

  const { handleSubmit, setValue, watch, formState } = form;

  const { isLoading } = formState;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const handleOnSubmit = () => {
    const { value } = watch();

    setValue('label', value);
    handleCloseDatasetDialog();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Retirement Age</h1>
            <Button
              variant="outlined"
              style={{
                color: '#B8341B',
                borderColor: '#B8341B',
              }}
              onClick={handleOpenDatasetDialog}
            >
              Edit
            </Button>
          </div>
          <div className="w-[30%]">
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectDatasetDialog}
          onClose={handleCloseDatasetDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Edit retirement age</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              <FormInput
                placeholder="Enter retirement age"
                type="number"
                name="value"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseDatasetDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#B8341B',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const ExpenseConfiguration = () => {
  const form = useForm();

  const { handleSubmit, watch } = form;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const { projectId, orgId, modelId } = useGetRouteParams();

  const navigate = useNavigate();

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: userdefinedExpenseSets } = useGetUserDefinedExpenses(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: model } = useGetModelById(
    {
      orgId,
      modelId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const modelPreDefinedExpenses = model?.predefined_expenses;
  const modelDatasetId = model?.dataset;

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const allDefault = Object.values(watch('default') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const allUser = Object.values(watch('user') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const { mutateAsync: updateModel, isPending: isUpdatingModel } =
    useUpdateModel();

  const handleSelect = (id: number) => {
    // navigate(`expenses/${id}`);
  };

  const handleOnSubmit = () => {
    if (
      allDefault.length === 0 ||
      !orgId ||
      !projectId ||
      !model ||
      !modelDatasetId
    ) {
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        name: model.name,
        predefined_expenses: allDefault.map(Number),
        userdefined_expenses: allUser.map(Number),
        dataset: modelDatasetId,
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense set updated successfully',
    });
    handleCloseDatasetDialog();
  };

  const predefinedExpenses = predefinedExpenseSets?.reduce(
    (acc, cur) => {
      const group = cur.group.name;

      acc[group] = [
        ...(acc[group] ?? []),
        {
          label: cur.name,
          value: cur.id,
          type: 'default',
        },
      ];

      return acc;
    },
    {} as Record<string, ExpenseOption[]>
  );

  const customExpenses = userdefinedExpenseSets
    ?.filter((u) => model?.userdefined_expenses?.includes(u.id))
    ?.reduce(
      (acc, cur) => {
        const group = cur.group?.name;

        acc[group] = [
          ...(acc[group] ?? []),
          {
            label: cur.name,
            value: cur.id,
            type: 'custom',
          },
        ];

        return acc;
      },
      {} as Record<string, ExpenseOption[]>
    );

  const expenses =
    predefinedExpenses && customExpenses
      ? Object.keys(predefinedExpenses).reduce(
          (acc, cur) => {
            acc[cur] = [
              ...predefinedExpenses[cur],
              ...(customExpenses[cur] ?? []),
            ];
            return acc;
          },
          {} as Record<string, ExpenseOption[]>
        )
      : [];

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">Expense</h1>
              <Button
                variant="outlined"
                style={{
                  color: '#B8341B',
                  borderColor: '#B8341B',
                }}
                onClick={handleOpenDatasetDialog}
              >
                Edit
              </Button>
            </div>
            <div className="rounded-[4px] border border-[#E4E7EC] p-6">
              {expenses &&
                modelPreDefinedExpenses &&
                Object.keys(expenses).map((group) => (
                  <ExpenseCollapse
                    title={group}
                    items={(expenses as Record<string, ExpenseOption[]>)[
                      group
                    ].filter((e: { value: number }) =>
                      modelPreDefinedExpenses.includes(e.value)
                    )}
                    handleSelect={handleSelect}
                  />
                ))}
            </div>
          </div>
          <Dialog
            maxWidth="sm"
            fullWidth
            open={openSelectDatasetDialog}
            onClose={handleCloseDatasetDialog}
          >
            <DialogContent>
              <div>
                <PredefinedExpensesForm />
                <UserDefinedExpensesForm />
              </div>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-3 px-4 pb-3">
                <Button
                  autoFocus
                  variant="outlined"
                  type="button"
                  onClick={handleCloseDatasetDialog}
                  tabIndex={4}
                  style={{
                    color: '#666666',
                    borderColor: '#CCC',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  variant="contained"
                  loading={isUpdatingModel}
                  onClick={handleOnSubmit}
                  tabIndex={3}
                >
                  Save
                </LoadingButton>
              </div>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
    </div>
  );
};

const ModelConfigurationView = () => (
  <div className="flex flex-col gap-3 overflow-y-hidden">
    <DatasetConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <RetirementAgeConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <ExpenseConfiguration />
  </div>
);

export default ModelConfigurationView;
