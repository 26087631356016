import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Collapse } from '@mui/material';
import { Plus } from 'lucide-react';

import { type TypePredefinedExpense } from '../../common/dataTypes';
import { toastPromise } from '../../common/utils';
import { useGetModelById, useUpdateModel } from '../../hooks';
import {
  useGetPreDefinedExpenses,
  useGetUserDefinedExpenses,
} from '../../hooks/useExpenseHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';

import CheckboxesCollapse from './CheckboxesCollapse';

export const PredefinedExpensesForm = () => {
  const [open, setOpen] = useState(true);

  const { orgId } = useGetRouteParams();

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const defaultExpenseSets = predefinedExpenseSets?.reduce((acc, cur) => {
    const groupOrder = cur.group.ifs_order;

    acc[groupOrder] = [...(acc[groupOrder] ?? []), cur];

    acc[groupOrder] = (acc[groupOrder] as TypePredefinedExpense[]).sort(
      (a, b) => a.ifs_order - b.ifs_order
    );

    return acc;
  }, [] as TypePredefinedExpense[][]);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#666]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="text-lg font-semibold capitalize">
          Predefined Expenses
        </label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="py-2">
          {defaultExpenseSets &&
            Array.from(Array(defaultExpenseSets.length - 1).keys()).map(
              (_, index) => {
                const items = defaultExpenseSets[index + 1];

                if (!items) {
                  return null;
                }

                return (
                  <CheckboxesCollapse
                    name={items[0].group.name}
                    title={items[0].group.name}
                    items={items.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                  />
                );
              }
            )}
        </div>
      </Collapse>
    </div>
  );
};

export const UserDefinedExpensesForm = () => {
  const [open, setOpen] = useState(true);

  const { orgId, projectId } = useGetRouteParams();

  const { data: customExpenses } = useGetUserDefinedExpenses(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleRedirectAddNewExpense = () => {
    if (window) {
      window
        .open(`/${orgId}/projects/${projectId}/expenses?add=true`, '_blank')
        ?.focus();
    }
  };

  const expenses = useMemo(
    () =>
      customExpenses?.reduce(
        (acc, cur) => {
          const group = cur.group?.name;

          if (!group) {
            return acc;
          }

          acc[group] = [
            ...(acc[group] ?? []),
            {
              label: cur.name,
              value: cur.id,
            },
          ];

          return acc;
        },
        {} as Record<string, { label: string; value: number }[]>
      ),
    [customExpenses]
  );

  return (
    <div className="my-6 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-6">
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleToggle}
          className="flex items-center gap-1 text-[#666]"
        >
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
          <label className="text-lg font-semibold capitalize">
            User-Defined Expenses
          </label>
        </button>
        <Button
          onClick={handleRedirectAddNewExpense}
          style={{
            color: '#B8341B',
          }}
        >
          <Plus size={15} />
          <p>Add</p>
        </Button>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {expenses &&
          Object.keys(expenses).map((key) => (
            <CheckboxesCollapse
              key={key}
              name={key}
              title={key}
              group="user"
              items={expenses[key].map((i) => ({
                label: i.label,
                value: i.value,
              }))}
            />
          ))}
      </Collapse>
    </div>
  );
};

const CreateExpenseForm = () => {
  const form = useForm();

  const { handleSubmit, watch } = form;
  const { orgId, projectId, modelId } = useGetRouteParams();

  const { data: model } = useGetModelById(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId && !!modelId,
    }
  );

  const { mutateAsync: updateModel, isPending: isUpdatingModel } =
    useUpdateModel();

  const allDefault = Object.values(watch('default') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const allUser = Object.values(watch('user') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const modelDatasetId = model?.dataset;

  const onSubmit = (data: any) => {
    if (
      allDefault.length === 0 ||
      !orgId ||
      !projectId ||
      !modelId ||
      !modelDatasetId
    ) {
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        dataset: modelDatasetId,
        predefined_expenses: allDefault.map(Number),
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense set updated successfully',
    });
  };

  return (
    <div className="flex flex-col pb-12">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white sticky top-[60px] z-50 flex justify-between overflow-hidden py-3">
            <h1 className="text-[28px] font-bold">
              Select Predefined Expenses and User-Defined Expenses
            </h1>
            <LoadingButton
              loading={isUpdatingModel}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </div>
          <div>
            <PredefinedExpensesForm />
            <UserDefinedExpensesForm />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateExpenseForm;
