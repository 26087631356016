import React from 'react';

const SectionLabel = ({
  label,
  description,
}: {
  label: string;
  description?: string;
}) => (
  <div className="my-4 flex flex-col justify-center gap-2 py-1 text-[#01285F]">
    <span className="text-lg font-bold">{label}</span>
    <p className="text-[#666]">{description}</p>
  </div>
);

export default SectionLabel;
