import { mainApi } from '../utils/api';

export type GetReceivedSharingInvitationsInput = {
  orgId: number;
};

export type AcceptReceivedSharingInvitationInput = {
  orgId: number;
  invitationId: number;
};

export type RejectReceivedSharingInvitationInput = {
  orgId: number;
  invitationId: number;
};

const getReceivedSharingInvitations = ({
  orgId,
}: GetReceivedSharingInvitationsInput) =>
  mainApi.get(`/organizations/${orgId}/received_sharing_invitations/`);

const acceptReceivedSharingInvitation = ({
  orgId,
  invitationId,
}: RejectReceivedSharingInvitationInput) =>
  mainApi.post(
    `/organizations/${orgId}/received_sharing_invitations/${invitationId}/accept/`
  );

const rejectReceivedSharingInvitation = ({
  orgId,
  invitationId,
}: RejectReceivedSharingInvitationInput) =>
  mainApi.post(
    `/organizations/${orgId}/received_sharing_invitations/${invitationId}/reject/`
  );

export {
  getReceivedSharingInvitations,
  acceptReceivedSharingInvitation,
  rejectReceivedSharingInvitation,
};
