import { mainApi } from '../utils/api';

export type ImportIFSInput = {
  datasetId: number;
  orgId: number;
  data: FormData;
  projectId: number;
};

export type ImportExistingIFSInput = {
  datasetId: number;
  orgId: number;
  data: {
    input_file: number;
  };
  projectId: number;
};

export type GetIFSInputInput = {
  datasetId: number;
  projectId: number;
  orgId: number;
};

export type GenerateIFSInput = {
  datasetId: number;
  projectId: number;
  orgId: number;
};

export type GetDownloadIFSInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
  fileId: number;
};

const getIFS = ({ datasetId, orgId, projectId }: GetIFSInputInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/ifs_schema/`
  );

const getDownloadIFSLink = ({
  orgId,
  projectId,
  datasetId,
  fileId,
}: GetDownloadIFSInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/input_files/${fileId}/download/`
  );

const importIFS = ({ datasetId, orgId, data, projectId }: ImportIFSInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/import_ifs/`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

const importExistingIFS = ({
  datasetId,
  orgId,
  projectId,
  data,
}: ImportExistingIFSInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/import_existing_ifs/`,
    data
  );

export { getIFS, importIFS, getDownloadIFSLink, importExistingIFS };
