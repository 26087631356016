import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, OutlinedInput } from '@mui/material';

import { type TableType } from '../common/dataTypes';
import { toastPromise } from '../common/utils';
import { useDeleteDataset, useDeleteModel, useDeleteProject } from '../hooks';
import { useGetRouteParams } from '../hooks/useUtilsHook';

type ConfirmDeleteDialogProps = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  label?: string;
  type: TableType;
  row: {
    id: number;
    name: string;
    description?: string;
    modified?: string;
  };
};

const ConfirmDeleteDialog = ({
  open,
  handleClose,
  handleOpen,
  label,
  type,
  row,
}: ConfirmDeleteDialogProps) => {
  const [confirmText, setConfirmText] = useState('');

  const { projectId, orgId } = useGetRouteParams();

  const [error, setError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: '',
  });

  const { mutateAsync: deleteDataset, isPending: isDeletingDataset } =
    useDeleteDataset({
      onSuccess: () => {
        handleClose();
      },
    });

  const { mutateAsync: deleteProject, isPending: isDeletingProject } =
    useDeleteProject({
      onSuccess: () => {
        handleClose();
      },
    });

  const { mutateAsync: deleteModel, isPending: isDeletingModel } =
    useDeleteModel({
      onSuccess: () => {
        handleClose();
      },
    });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  const handleSubmit = () => {
    if (confirmText !== row.name) {
      setError({
        isError: true,
        message: `${type} name does not match`,
      });
      return;
    }

    const id = row.id;

    if (type === 'project') {
      const res = deleteProject({
        projectId: id,
        orgId,
      });

      toastPromise({
        promise: res,
        content: `Delete ${type} ${row.name} successfully`,
      });
    } else if (type === 'dataset') {
      const res = deleteDataset({
        datasetId: id,
        orgId,
        projectId,
      });

      toastPromise({
        promise: res,
        content: `Delete ${type} ${row.name} successfully`,
      });
    } else if (type === 'model') {
      const res = deleteModel({
        modelId: id,
        orgId,
        projectId,
      });

      toastPromise({
        promise: res,
        content: `Delete ${type} ${row.name} successfully`,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (isDeletingDataset || isDeletingProject || isDeletingModel) {
          return;
        }

        handleClose();
      }}
      aria-labelledby="alertTitle"
      aria-describedby="alertContent"
    >
      <div className="flex flex-col gap-6 p-6">
        <h2 className="text-xl font-bold">
          Are you sure you want to delete this {type}?
        </h2>
        <p className="text-[#666]">
          Type "<span className="font-bold">{row.name}</span>" to delete. You
          will not be able to recover it once it deleted.
        </p>
        <div>
          <OutlinedInput fullWidth size="small" onChange={handleOnChange} />
          {error && (
            <p className="w-full pt-2 text-start text-[14px] capitalize text-[#FF816A]">
              {error.message as string}
            </p>
          )}
        </div>
        <div className="flex justify-end gap-3">
          <Button
            onClick={handleClose}
            variant="outlined"
            tabIndex={3}
            disabled={isDeletingDataset || isDeletingProject || isDeletingModel}
            style={{
              color: '#666',
              borderColor: '#B3B3B3',
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            tabIndex={2}
            loading={isDeletingDataset || isDeletingProject || isDeletingModel}
            variant="contained"
            style={{
              backgroundColor: '#B8341B',
              color: '#FFF',
            }}
          >
            Yes, delete it
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
