import React, { useState } from 'react';

import { MailPlus } from 'lucide-react';

import { useResendInvitation } from '../../hooks/useOrganizationHook';
import LoadingDialog from '../LoadingDialog';
import InviteSentDialog from '../SuccessDialog';

type ResendOrgInvitationProps = {
  orgId: number;
  invitationId: number;
};

const ResendOrgInvitation = ({
  orgId,
  invitationId,
}: ResendOrgInvitationProps) => {
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

  const { mutate: resendInvitation } = useResendInvitation({
    onSuccess: () => {
      setOpenSuccessDialog(true);
      setOpenLoadingDialog(false);
    },
  });

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const handleOnClick = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    setOpenLoadingDialog(true);

    resendInvitation({
      id: orgId,
      invitationId,
    });
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  return (
    <>
      <button onClick={handleOnClick}>
        <MailPlus width={20} height={20} color="#666666" />
      </button>
      <InviteSentDialog
        content="Invitation has been resent successfully."
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
      />
      <LoadingDialog title="Re-sending invitation" open={openLoadingDialog} />
    </>
  );
};

export default ResendOrgInvitation;
