import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ProjectModelsManagement from '../components/projects/models/ProjectModelsManagement';

function ProjectDetailView() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(pathname + '/models');
  }, []);

  return (
    <div>
      <ProjectModelsManagement />
    </div>
  );
}

export default ProjectDetailView;
