import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider, MenuItem } from '@mui/material';
import { ChevronDown, ChevronUp, Plus } from 'lucide-react';

import { type TypeScenario } from '../../common/dataTypes';
import {
  useActivateBaseScenario,
  useActivateScenario,
  useGetBaseScenario,
  useGetScenariosByDataTableName,
} from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import MenuDropdown from '../MenuDropdown';

import CreateScenarioFormDialog from './CreateScenarioFormDialog';

type ScenarioMenuDropdownProps = {
  dataTableName: string;
  label: string;
};

const ScenarioMenuDropdown = ({
  dataTableName,
  label,
}: ScenarioMenuDropdownProps) => {
  const navigate = useNavigate();

  const [openCreateScenarioDialog, setOpenCreateScenarioDialog] =
    useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { orgId, projectId, modelId } = useGetRouteParams();

  const { data: baseScenario } = useGetBaseScenario(
    {
      orgId,
      projectId,
      modelId,
      dataTableName,
    },
    {
      enabled: !!orgId && !!projectId && !!modelId && !!dataTableName,
    }
  );

  const { data: scenarios } = useGetScenariosByDataTableName(
    {
      orgId,
      projectId,
      modelId,
      dataTableName,
    },
    {
      enabled: !!orgId && !!projectId && !!modelId && !!dataTableName,
    }
  );

  const { mutate: activateScenario } = useActivateScenario();

  const { mutate: activateBaseScenario } = useActivateBaseScenario();

  const handleOpenCreateScenarioDialog = () => {
    setOpenCreateScenarioDialog(true);
  };

  const handleCloseCreateScenarioDialog = () => {
    setOpenCreateScenarioDialog(false);
  };

  const handleSubmitCreateScenarioDialog = (data: TypeScenario) => {
    navigate(`input-table/${dataTableName}?scenario_id=${data.id}`);
  };

  const handleActivateScenario = (id: number) => {
    if (id === 0) {
      activateBaseScenario({
        orgId,
        projectId,
        modelId,
        dataTableName,
      });
    }

    activateScenario({
      orgId,
      projectId,
      modelId,
      scenarioId: id,
    });
  };

  const items =
    baseScenario && scenarios
      ? [baseScenario].concat(scenarios as TypeScenario[])
      : null;

  return (
    <>
      <MenuDropdown
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        trigger={
          <div className="flex w-full justify-between gap-2 text-[#666666]">
            <p className="font-semibold">{label}</p>
            {open ? <ChevronUp width={15} /> : <ChevronDown width={15} />}
          </div>
        }
        style={{
          width: '100%',
          border: 'none !important',
          top: '10px !important',
        }}
        triggerClassName="rounded-md w-full"
      >
        <MenuItem onClick={handleOpenCreateScenarioDialog}>
          <div className="py-1 gap-2 font-semibold flex items-center ">
            <Plus />
            <p>New Scenario</p>
          </div>
        </MenuItem>
        <Divider
          style={{
            margin: 0,
          }}
        />
        {items?.map((s) => (
          <MenuItem
            onClick={() => handleActivateScenario(s.id)}
            className="flex transition hover:bg-slate-200 p-2 cursor-pointer w-full h-full gap-2"
          >
            {s.name}
          </MenuItem>
        ))}
      </MenuDropdown>
      <CreateScenarioFormDialog
        open={openCreateScenarioDialog}
        title="Create New Scenario"
        showTrigger={false}
        handleCloseDialog={handleCloseCreateScenarioDialog}
        handleSubmitDialog={handleSubmitCreateScenarioDialog}
        dataTableName={dataTableName}
      />
    </>
  );
};

export default ScenarioMenuDropdown;
