import React from 'react';

import CustomExpensesTab from '../expense/CustomExpensesTab';

export const TABS = ['Custom Expenses', 'Predefined Expenses'];

const ProjectExpensesManagement = () => (
  <div className="text-2xl font-bold px-12 pb-6">
    <CustomExpensesTab />
  </div>
);

export default ProjectExpensesManagement;
