import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { getUserById, updateUser, type UpdateUserInput } from '../api/users';
import { type TypeUser } from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useUpdateUser = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['changePassword'],
    mutationFn: async (input: UpdateUserInput) => {
      const { data }: AxiosResponse = await updateUser(input);
      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getUser'],
      });
      queryClient.refetchQueries({
        queryKey: ['getOrgMembers'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGetUser = (
  opts?: Partial<UseQueryOptions<Awaited<TypeUser>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getUser'],
    queryFn: async () => {
      const user = JSON.parse(localStorage.getItem('user') || '');

      const { data }: AxiosResponse<TypeUser> = await getUserById(user.id);

      return data;
    },
    ...opts,
  });

export const useGetUserById = (
  id: number,
  opts?: Partial<UseQueryOptions<Awaited<TypeUser>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getUserById', id],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeUser> = await getUserById(id);

      return data;
    },
    ...opts,
  });
