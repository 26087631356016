import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createIFSConfig,
  deleteDataset,
  getDatasets,
  getDownloadIFSLink,
  updateDataset,
} from '../api';
import {
  createDataset,
  type CreateDatasetInput,
  type CreateIFSConfigInput,
  type DeleteDatasetInput,
  getDatasetById,
  type GetDatasetByIdInput,
  getDatasetInputFiles,
  getDatasetInputFilesById,
  type GetDatasetInputFilesByIdInput,
  getDatasetInputFilesData,
  type GetDatasetInputFilesDataInput,
  type GetDatasetInputFilesInput,
  type GetDatasetsInput,
  getIFSConfig,
  type GetIFSConfigInput,
  type UpdateDatasetInput,
  updateIFSConfig,
  type UpdateIFSConfigInput,
} from '../api/datasets';
import { type GetDownloadIFSInput } from '../api/ifsSchema';
import {
  type TypeDataset,
  type TypeDatasetInputFile,
  type TypeIFSConfiguration,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useDeleteDataset = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteDataset'],
    mutationFn: async (input: DeleteDatasetInput) => {
      const { data }: AxiosResponse = await deleteDataset(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateDataset = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeDataset>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateDataset'],
    mutationFn: async (input: UpdateDatasetInput) => {
      const { data }: AxiosResponse<TypeDataset> = await updateDataset(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      queryClient.refetchQueries({
        queryKey: ['getIFS'],
      });
      queryClient.refetchQueries({
        queryKey: ['getDatasetById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateDataset = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: number;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createDataset'],
    mutationFn: async (input: CreateDatasetInput) => {
      const {
        data,
      }: AxiosResponse<{
        id: number;
      }> = await createDataset(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateIFSConfig = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<TypeIFSConfiguration>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createIFSConfig'],
    mutationFn: async (input: CreateIFSConfigInput) => {
      const { data }: AxiosResponse<TypeIFSConfiguration> =
        await createIFSConfig(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getIFSConfig'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateIFSConfig = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<TypeIFSConfiguration>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateIFSConfig'],
    mutationFn: async (input: UpdateIFSConfigInput) => {
      const { data }: AxiosResponse<TypeIFSConfiguration> =
        await updateIFSConfig(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetDatasetInputFilesData = (
  input: GetDatasetInputFilesDataInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, (string | number)[]>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getDatasetInputFilesData', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Record<string, (string | number)[]>> =
        await getDatasetInputFilesData(input);

      return data;
    },
    ...opts,
  });

export const useGetDatasets = (
  input: GetDatasetsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataset[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDatasets', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataset[]> = await getDatasets(input);

      const result = data.sort((p1: TypeDataset, p2: TypeDataset) => {
        if (new Date(p1.created_at) > new Date(p2.created_at)) {
          return -1;
        } else {
          return 1;
        }
      });

      return result;
    },
    ...opts,
  });

export const useGetDatasetById = (
  input: GetDatasetByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataset>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDatasetById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataset> = await getDatasetById(input);

      return data;
    },
    ...opts,
  });

export const useGetIFSConfig = (
  input: GetIFSConfigInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeIFSConfiguration>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getIFSConfig', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeIFSConfiguration> =
        await getIFSConfig(input);

      return data;
    },
    ...opts,
  });

const sortInputFiles = (inputFiles: any[]) =>
  inputFiles?.sort((a, b) => {
    const getIndex = (name: string) => name.split('_')[0].split('of')[0];

    const aIndex = Number(getIndex(a.name));
    const bIndex = Number(getIndex(b.name));

    if (aIndex < bIndex) {
      return -1;
    }

    if (aIndex > bIndex) {
      return 1;
    }

    return 0;
  });

export const useGetDatasetInputFiles = (
  input: GetDatasetInputFilesInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeDatasetInputFile[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getDatasetInputFiles', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDatasetInputFile[]> =
        await getDatasetInputFiles(input);

      return sortInputFiles(data);
    },
    ...opts,
  });

export const useGetDatasetInputFileById = (
  input: GetDatasetInputFilesByIdInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeDatasetInputFile>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getDatasetInputFileById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDatasetInputFile> =
        await getDatasetInputFilesById(input);

      return data;
    },
    ...opts,
  });

export const useGetDownloadInputFileLink = (
  input: GetDownloadIFSInput,
  opts?: Partial<UseQueryOptions<Awaited<string>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDownloadInputFileLink', input],
    queryFn: async () => {
      const { data }: AxiosResponse<string> = await getDownloadIFSLink(input);

      return data;
    },
    ...opts,
  });
