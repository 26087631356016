import React from 'react';

import { MenuItem } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';

import MenuDropdown from './MenuDropdown';

const LastModifiedFilter = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <MenuDropdown
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      trigger={
        <div className="flex w-full gap-2 px-3 text-[#666666]">
          <p className="font-semibold">Last Modified</p>
          {open ? <ChevronUp width={15} /> : <ChevronDown width={15} />}
        </div>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      triggerClassName="rounded-md border border-[#CCCCCC] pl-3 py-1.5"
    >
      <MenuItem
        style={{
          width: '160px',
        }}
      >
        Today
      </MenuItem>
      <MenuItem>7 days ago</MenuItem>
      <MenuItem>1 month ago</MenuItem>
      <MenuItem>This year</MenuItem>
      <MenuItem>Last year</MenuItem>
    </MenuDropdown>
  );
};

export default LastModifiedFilter;
