import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from '@mui/material';

import EmailSent from '../icons/EmailSent';

type PasswordChangeSentDialogProps = {
  open: boolean;
  handleOnClose: () => void;
};

const PasswordChangeSentDialog = ({
  open,
  handleOnClose,
}: PasswordChangeSentDialogProps) => {
  const { watch } = useFormContext();

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alertTitle"
      aria-describedby="alertContent"
    >
      <div className="flex flex-col items-center gap-3 p-8">
        <EmailSent />
        <h2 className="text-xl font-bold">Check your email</h2>
        <div className="text-center text-base text-[#666]">
          <p>
            We've sent instruction on how to reset your password to{' '}
            <span className="font-bold">{watch('email')}</span>
          </p>
        </div>
      </div>
    </Dialog>
  );
};

export default PasswordChangeSentDialog;
