import React, { type SyntheticEvent, useRef, useState } from 'react';

import { Button, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FileUp, MousePointer2 } from 'lucide-react';

import { useGetDatasetById } from '../../hooks';
import { useGetIFS } from '../../hooks/useIFSHook';
import {
  useGetRouteParams,
  useHandleImportIFS,
} from '../../hooks/useUtilsHook';
import LoadingDialog from '../LoadingDialog';
import {
  type StyledTabProps,
  type StyledTabsProps,
  type TabPanelProps,
} from '../model/ModelOutputTabs';
import SuccessDialog from '../SuccessDialog';
import ViewFilesDataTable from '../ViewFilesDataTable';

import AlertDialog from './AlertDialog';
import DownloadTemplatesDialog from './DownloadTemplatesDialog';
import { IFSTable } from './IFSTableSection';
import ImportExistingFilesDialog from './ImportExistingFilesDialog';

const TABS = ['Data', 'Import'];

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  gap: '12px !important',
  backgroundColor: '#F5F9FF',

  '& .MuiTabs-indicator': {
    display: 'flex',
    gap: '12px !important',
    justifyContent: 'center',
  },

  '& .MuiTabs-flexContainer': {
    paddingLeft: '48px',
    gap: '24px',
  },

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#01285F',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  padding: '0px !important',
  minWidth: 'fit-content !important',

  '&.MuiButtonBase-root': {
    padding: '0px !important',
  },
  '&.Mui-selected': {
    color: '#01285F',
    fontWeight: 500,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6 pb-4"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const ProjectViewDataset = () => {
  const [index, setIndex] = useState(0);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openImportFilesDialog, setOpenImportFilesDialog] = useState(false);

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { orgId, datasetId, projectId } = useGetRouteParams();

  const { importIfs, error, isPending } = useHandleImportIFS({
    inputFileRef,
    handleError: () => {
      setOpenErrorDialog(true);
    },
  });

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId,
      orgId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      projectId,
      orgId,
    },
    {
      enabled: !!datasetId && !!projectId && !!orgId,
    }
  );

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const handleOpenImportFilesDialog = () => {
    setOpenImportFilesDialog(true);
  };

  const handleCloseImportFilesDialog = () => {
    setOpenImportFilesDialog(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab id={report} key={report} label={report} />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        <div className="px-12 font-bold space-y-3">
          <h1 className="text-2xl font-bold">Data</h1>
          <ViewFilesDataTable />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <div className="flex flex-col px-12 gap-3">
          <h1 className="text-2xl font-bold">Import</h1>
          <div className="flex justify-between">
            {dataset && ifs && (
              <DownloadTemplatesDialog
                dataset={dataset}
                ifs={ifs}
                trigger={{
                  style: {
                    color: '#666',
                    borderColor: '#CCC',
                  },
                }}
              />
            )}
            <div className="flex gap-4">
              <Button
                variant="outlined"
                style={{
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <input
                  ref={inputFileRef}
                  type="file"
                  accept=".xlsx"
                  id="import_ifs"
                  onChange={importIfs}
                  multiple
                  className="absolute w-[140px] cursor-pointer opacity-0"
                />
                <FileUp size={20} />
                Upload File(s)
              </Button>
              <Button
                onClick={handleOpenImportFilesDialog}
                variant="contained"
                style={{
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <MousePointer2 size={20} />
                Import existing file(s)
              </Button>
            </div>
          </div>
          <IFSTable />
          <ImportExistingFilesDialog
            open={openImportFilesDialog}
            handleCloseDialog={handleCloseImportFilesDialog}
          />
          <AlertDialog
            title="Error Found: Please Review"
            open={openErrorDialog}
            content={error?.content}
            handleOnClose={() => {
              setOpenErrorDialog(false);
            }}
          />
          <SuccessDialog
            content="Input File Set imported successfully."
            open={openSuccessDialog}
            handleOnClose={() => {
              setOpenSuccessDialog(false);
            }}
          />
          <LoadingDialog title="Uploading" open={isPending} />
        </div>
      </CustomTabPanel>
    </div>
  );
};

export default ProjectViewDataset;
