import React from 'react';

import cn from '../utils/cn';

const SectionLine = ({
  className,
}: {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}) => (
  <div
    className={cn('h-[1.5px] w-full rounded-full bg-[#01285F]', className)}
  />
);

export default SectionLine;
