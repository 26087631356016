import React from 'react';

const EmailSent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
  >
    <path
      d="M24.5 24L36.5 32L48.5 24"
      stroke="#2196F3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.50016 36H13.8335"
      stroke="#2196F3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.16683 28H13.8335"
      stroke="#2196F3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8332 20.0007V15.334C13.8332 14.2294 14.7286 13.334 15.8332 13.334H57.1665C58.2711 13.334 59.1665 14.2294 59.1665 15.334V48.6673C59.1665 49.7719 58.2711 50.6673 57.1665 50.6673H15.8332C14.7286 50.6673 13.8332 49.7719 13.8332 48.6673V44.0007"
      stroke="#2196F3"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
);

export default EmailSent;
