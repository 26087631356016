import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import {
  Archive,
  EllipsisVertical,
  Pencil,
  Trash2,
  UserRoundPlus,
} from 'lucide-react';

import { type TypeProject } from '../../common/dataTypes';
import { ProjectRolesOptions } from '../../common/utils';
import { useGetProjectById, useGetProjects } from '../../hooks';
import { useGetUser } from '../../hooks/useUserHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';
import { useCurrentOrganization } from '../../utils/helpers';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import MenuDropdown from '../MenuDropdown';
import InviteFormDialog from '../organizations/InviteFormDialog';
import Spinner from '../Spinner';
import WarningDialog from '../WarningDialog';

import CreateProjectDialog from './CreateProjectDialog';
import ProjectAccessDeniedDialog from './ProjectAccessDeniedDialog';

export type ProjectRow = {
  id: number;
  name: string;
  description: string | undefined;
  status: string;
  modified: string | undefined;
  owners: string[];
};

const defaultData: ProjectRow[] = [];

const columnHelper = createColumnHelper<ProjectRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p
          onClick={() => handleSelect(info.row.original.id)}
          className="w-full"
        >
          {info.getValue()}
        </p>
      </div>
    ),
    header: () => (
      <div className="flex items-center gap-2">
        <p>Name</p>
      </div>
    ),
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div
          onClick={() => handleSelect(id)}
          className="line-clamp-5 max-w-[250px] 2xl:max-w-[350px]"
        >
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 max-w-[250px] text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Description</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const id = info.row.original.id;

      const status = info.getValue();

      return status ? (
        <div onClick={() => handleSelect(id)} className="w-[100px]">
          {status !== 'completed' && (
            <div className="flex items-center justify-center rounded-full bg-[#FFF4F2] p-1 text-sm font-bold text-primary-red">
              Not Ready
            </div>
          )}
          {status === 'completed' && (
            <div className="flex items-center justify-center rounded-full bg-[#E8F2FF] p-1 text-sm font-bold text-[#4782D6]">
              Ready
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Status</div>,
  }),
  columnHelper.accessor('modified', {
    id: 'modified',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div onClick={() => handleSelect(id)}>
          <p>{format(info.getValue() as string, 'Y/MM/dd')}</p>
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Last Modified</div>,
  }),
  columnHelper.accessor('owners', {
    id: 'owners',
    cell: (info) => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);

      const [openCreateProjectDialog, setOpenCreateProjectDialog] =
        useState(false);

      const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

      const { orgId } = useGetRouteParams();

      const open = Boolean(anchorEl);

      const projectId = info.row.original.id;

      const { data: project } = useGetProjectById(
        {
          projectId,
          orgId,
        },
        {
          enabled: !!orgId && !!projectId,
        }
      );

      const handleCloseDropdown = () => setAnchorEl(null);

      const handleOpenConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(true);
      };

      const handleCloseConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(false);
      };

      const handleOpenCreateProjectDialog = () => {
        setOpenCreateProjectDialog(true);
      };

      const handleCloseCreateProjectDialog = () => {
        setOpenCreateProjectDialog(false);
      };

      const handleOpenInviteFormDialog = () => {
        setOpenInviteFormDialog(true);
      };

      const handleCloseInviteFormDialog = () => {
        setOpenInviteFormDialog(false);
      };

      return info.getValue() ? (
        <div className="flex min-w-[200px] justify-between">
          <p
            className="max-w-[180px] grow truncate"
            onClick={() => handleSelect(projectId)}
          >
            {info.getValue().join(', ')}
          </p>
          <MenuDropdown
            trigger={
              <button>
                <EllipsisVertical width={20} height={20} />
              </button>
            }
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            style={{
              marginTop: '5px',
            }}
            open={open}
          >
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenInviteFormDialog();
              }}
            >
              <div className="flex items-center space-x-2">
                <UserRoundPlus size={15} />
                <p>Invite members</p>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenCreateProjectDialog();
              }}
            >
              <div className="flex items-center space-x-2">
                <Pencil size={15} />
                <p>Edit</p>
              </div>
            </MenuItem>
            <MenuItem>
              <div className="flex items-center space-x-2">
                <Archive size={15} />
                <p>Archive</p>
              </div>
            </MenuItem>
            <div className="!border-t !border-[#E4E7EC]" />
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenConfirmDeleteDialog();
              }}
            >
              <div className="flex items-center space-x-2 text-primary-red">
                <Trash2 size={15} />
                <p>Delete</p>
              </div>
            </MenuItem>
          </MenuDropdown>
          {project && (
            <InviteFormDialog
              to="project"
              iProjectId={project.id}
              showTrigger={false}
              roles={ProjectRolesOptions}
              open={openInviteFormDialog}
              handleClose={handleCloseInviteFormDialog}
              handleOpen={handleOpenInviteFormDialog}
            />
          )}
          {project && (
            <CreateProjectDialog
              open={openCreateProjectDialog}
              defaultValues={{
                id: project.id,
                name: project.name,
                description: project.description,
              }}
              handleClose={handleCloseCreateProjectDialog}
            />
          )}
          {project && (
            <ConfirmDeleteDialog
              type="project"
              row={{
                id: project.id,
                name: project.name,
                description: project.description,
                modified: project.updated_at,
              }}
              open={openConfirmDeleteDialog}
              handleOpen={handleOpenConfirmDeleteDialog}
              handleClose={handleCloseConfirmDeleteDialog}
            />
          )}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(projectId)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Project Owners</div>,
  }),
];

const ProjectsTable = ({
  defaultProjects,
}: {
  defaultProjects: TypeProject[] | null;
}) => {
  const [data, setData] = useState<ProjectRow[]>(defaultData);
  const [openDialog, setOpenDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(NaN);

  const navigate = useNavigate();

  const { orgId } = useGetRouteParams();

  const organization = useCurrentOrganization();

  const { data: projects, isRefetching } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId && defaultProjects?.length === 0,
    }
  );

  const { data: currentUser } = useGetUser();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenWarningDialog = () => {
    setOpenWarningDialog(true);
  };

  const handleCloseWarningDialog = () => {
    setOpenWarningDialog(false);
  };

  const handleSelect = (id: number) => {
    const selectedProject = projects?.find((project) => project.id === id);

    const selectedProjectUser = selectedProject?.members.find(
      (m) => m.user.id === currentUser?.id
    );

    const isAuthenticated = selectedProjectUser?.role === 'project_owner';

    if (!selectedProject) {
      return;
    }

    if (organization?.role === 'organization_admin' && !selectedProject.role) {
      setSelectedProjectId(id);
      setOpenDialog(true);
      return;
    }

    if (
      !isAuthenticated &&
      selectedProject?.onboarding_status !== 'completed'
    ) {
      handleOpenWarningDialog();
      return;
    }

    if (selectedProject.onboarding_status === 'configuring') {
      navigate(`create/?step=2&project_id=${id}`);
      return;
    }

    if (selectedProject.onboarding_status === 'uploading_data') {
      navigate(`create/?step=3&project_id=${id}`);
      return;
    }

    if (selectedProject.onboarding_status === 'reviewing') {
      navigate(`create/?step=4&project_id=${id}`);
      return;
    }

    navigate(id.toString());
  };

  useEffect(() => {
    if (defaultProjects && defaultProjects?.length > 0) {
      setData(
        defaultProjects.map((project) => ({
          id: project.id,
          name: project.name,
          status: project.onboarding_status,
          description: project.description,
          modified: project.updated_at,
          owners: project.members.map((member) => member.user.first_name),
        }))
      );
      return;
    }

    if (projects) {
      setData(
        projects.map((project) => ({
          id: project.id,
          name: project.name,
          status: project.onboarding_status,
          description: project.description,
          modified: project.updated_at,
          owners: project.members
            .filter((m) => m.role === 'project_owner')
            .map((member) => member.user.full_name),
        }))
      );
    }
  }, [projects, defaultProjects]);

  const table = useReactTable({
    data,
    columns: columns({
      handleSelect,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="relative">
          {isRefetching && (
            <div className="bg-gray-200 absolute flex h-full w-full items-center justify-center opacity-50">
              <Spinner />
            </div>
          )}
          {table.getRowModel().rows.map((row) => {
            const isAllowed = projects?.find(
              (p) => p.id === row.original.id
            )?.role;

            return (
              <tr
                key={row.id}
                className={cn('cursor-pointer text-[#333] transition py-3', {
                  '!text-[#999999] !bg-[#FFFFFF] disabled': !isAllowed,
                  'group hover:bg-[#F8F8F8]': isAllowed,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="border-b border-[#E4E7EC] px-6 first:border-l last:border-r"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">
            No projects available. Please create a project.
          </p>
        </div>
      )}
      <WarningDialog
        title="Access Denied"
        open={openWarningDialog}
        primaryActionText={'Ok'}
        handlePrimaryAction={handleCloseWarningDialog}
        content={
          'This project is not ready for you to access yet. Please wait for the project owner to finish setting up the project.'
        }
      />
      <ProjectAccessDeniedDialog
        iProjectId={selectedProjectId}
        open={openDialog}
        handleOnClose={handleCloseDialog}
        handleOnSubmit={handleCloseDialog}
      />
    </div>
  );
};

export default ProjectsTable;
