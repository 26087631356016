import { type TypeProject } from '../common/dataTypes';
import { type Role } from '../types';
import { mainApi, unprotectedMainApi } from '../utils/api';

export type CreateOrganizationInput = {
  data: {
    name: string;
    description: string;
  };
};

export type CreateOrganizationMemberInput = {
  id: number;
  data: {
    token: string;
    email: string;
    first_name: string;
    last_name: string;
    password: string;
  };
};

export type CreateOrganizationProjectInput = {
  id: number;
  data: {
    name: string;
    description: string;
  };
};

export type InviteMemberInput = {
  id: number;
  data: {
    email: string;
    role: Role;
    projects: number[];
  };
};

export type DeleteMemberByIdInput = { id: number; userId: number };

export type VerifyInvitationInput = {
  id: number;
  invitationId: number;
  data: {
    token: string;
  };
};

export type ResendInvitationInput = {
  id: number;
  invitationId: number;
};

export type GetOrgInvitationByIdInput = {
  id: number;
  invitationId: number;
};

export type GetOrgMemberByIdInput = {
  id: number;
  memberId: number;
};

export type DeleteOrgInvitationByIdInput = {
  id: number;
  invitationId: number;
};

export type UpdateOrganizationMemberInput = {
  id: number;
  memberId: number;
  data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    role: Role;
    organization: number;
    projects?: TypeProject[];
  };
};

export type UpdateOrganizationInvitationInput = {
  id: number;
  invitationId: number;
  data: {
    is_new_user?: number;
    role?: Role;
    email?: string;
    organization?: number;
    last_name?: string;
    projects?: number[];
  };
};

export type AcceptOrgReceivedInvitationInput = {
  id: number;
  invitationId: number;
};

export type RejectOrgReceivedInvitationInput = {
  id: number;
  invitationId: number;
};

export type GetSharedRequestByIdInput = {
  orgId: number;
  requestId: number;
};

export type ApproveSharedRequestInput = {
  orgId: number;
  reqId: number;
};

export type DisapproveSharedRequestInput = {
  orgId: number;
  reqId: number;
};

export type GetOrgReceivedInvitationsInput = {
  orgId: number;
};

const getOrganizations = () => mainApi.get('organizations/');

const getOrganizationInvitations = (id: number) =>
  mainApi.get(`organizations/${id}/invitations/`);

const getOrgReceivedInvitations = ({ orgId }: GetOrgReceivedInvitationsInput) =>
  mainApi.get(`organizations/${orgId}/received_invitations/`);

const acceptOrgReceivedInvitation = ({
  id,
  invitationId,
}: AcceptOrgReceivedInvitationInput) =>
  mainApi.post(
    `organizations/${id}/received_invitations/${invitationId}/accept/`
  );

const rejectOrgReceivedInvitation = ({
  id,
  invitationId,
}: RejectOrgReceivedInvitationInput) =>
  mainApi.post(
    `organizations/${id}/received_invitations/${invitationId}/reject/`
  );

const createOrganization = ({ data }: CreateOrganizationInput) =>
  mainApi.post('organizations/', data);

const getOrganizationById = (id: number) =>
  unprotectedMainApi.get(`organizations/${id}/`);

const verifyInvitation = ({ id, invitationId, data }: VerifyInvitationInput) =>
  unprotectedMainApi.post(
    `organizations/${id}/invitations/${invitationId}/verify/`,
    data
  );

const resendInvitation = ({ id, invitationId }: ResendInvitationInput) =>
  mainApi.post(`organizations/${id}/invitations/${invitationId}/resend/`);

const getOrgInvitationById = ({
  id,
  invitationId,
}: GetOrgInvitationByIdInput) =>
  unprotectedMainApi.get(`organizations/${id}/invitations/${invitationId}/`);

const deleteOrganizationById = (id: string) =>
  mainApi.delete(`organizations/${id}/`);

const deleteOrgInvitationById = ({
  id,
  invitationId,
}: DeleteOrgInvitationByIdInput) =>
  mainApi.delete(`organizations/${id}/invitations/${invitationId}/`);

const getOrgMembers = (id: number) =>
  mainApi.get(`organizations/${id}/members/`);

const getOrgMemberById = ({ id, memberId }: GetOrgMemberByIdInput) =>
  mainApi.get(`organizations/${id}/members/${memberId}`);

const createMember = ({ id, data }: CreateOrganizationMemberInput) =>
  unprotectedMainApi.post(`organizations/${id}/members/register/`, data);

const updateMember = ({ id, memberId, data }: UpdateOrganizationMemberInput) =>
  mainApi.patch(`organizations/${id}/members/${memberId}/`, data);

const updateInvitation = ({
  id,
  invitationId,
  data,
}: UpdateOrganizationInvitationInput) =>
  mainApi.patch(`organizations/${id}/invitations/${invitationId}/`, data);

const inviteMember = ({ id, data }: InviteMemberInput) =>
  mainApi.post(`organizations/${id}/members/invite/`, data);

const deleteMemberById = ({ id, userId }: DeleteMemberByIdInput) =>
  mainApi.delete(`organizations/${id}/members/${userId}/`);

const getSharedRequests = (id: number) =>
  mainApi.get(`organizations/${id}/sharing_requests/`);

const getSharedRequestById = ({
  orgId,
  requestId,
}: GetSharedRequestByIdInput) =>
  mainApi.get(`organizations/${orgId}/sharing_requests/${requestId}`);

const approveSharedInvitation = ({ orgId, reqId }: ApproveSharedRequestInput) =>
  mainApi.post(
    `organizations/${orgId}/sent_sharing_invitations/${reqId}/approve/`
  );

const disapproveSharedInvitation = ({
  orgId,
  reqId,
}: DisapproveSharedRequestInput) =>
  mainApi.post(
    `organizations/${orgId}/sent_sharing_invitations/${reqId}/disapprove/`
  );

export {
  acceptOrgReceivedInvitation,
  approveSharedInvitation,
  createMember,
  createOrganization,
  deleteMemberById,
  deleteOrganizationById,
  deleteOrgInvitationById,
  disapproveSharedInvitation,
  getOrganizationById,
  getOrganizationInvitations,
  getOrganizations,
  getOrgInvitationById,
  getOrgMemberById,
  getOrgMembers,
  getOrgReceivedInvitations,
  getSharedRequestById,
  getSharedRequests,
  inviteMember,
  rejectOrgReceivedInvitation,
  resendInvitation,
  updateInvitation,
  updateMember,
  verifyInvitation,
};
