import React, { useState } from 'react';

import { Button } from '@mui/material';

import CreateProjectDialog from './CreateProjectDialog';
import ProjectReview from './ProjectReview';

const ProjectSettings = () => {
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);

  const handleOpenCreateProjectDialog = () => {
    setOpenCreateProjectDialog(true);
  };

  const handleCloseCreateProjectDialog = () => {
    setOpenCreateProjectDialog(false);
  };

  return (
    <div className="px-12 pb-12 space-y-6">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Project Settings</h1>
        <Button
          variant="contained"
          size="small"
          onClick={handleOpenCreateProjectDialog}
          style={{
            backgroundColor: '#B8341B',
          }}
        >
          Edit
        </Button>
      </div>
      <ProjectReview />
      <CreateProjectDialog
        open={openCreateProjectDialog}
        handleClose={handleCloseCreateProjectDialog}
      />
    </div>
  );
};

export default ProjectSettings;
