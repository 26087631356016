import React, { type SyntheticEvent, useState } from 'react';

import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import ModelComparisonView from '../../model/ModelComparisonView';
import {
  type StyledTabProps,
  type StyledTabsProps,
  type TabPanelProps,
} from '../../model/ModelOutputTabs';

import DefaultModelsTable from './DefaultModelsTable';

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  gap: '12px !important',
  backgroundColor: '#F5F9FF',

  '& .MuiTabs-indicator': {
    display: 'flex',
    gap: '12px !important',
    justifyContent: 'center',
    backgroundColor: '#F5F9FF',
  },

  '& .MuiTabs-flexContainer': {
    paddingLeft: '48px',
    gap: '24px',
  },

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#01285F',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  padding: '0px !important',
  minWidth: 'fit-content !important',

  '&.MuiButtonBase-root': {
    padding: '0px !important',
  },
  '&.Mui-selected': {
    color: '#01285F',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

export const TABS = ['Models', 'Shared', 'Received', 'Compare Models'];

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6 px-12 py-5"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const ProjectModelsManagement = () => {
  const [index, setIndex] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <div className="text-2xl font-bold">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab id={report} key={report} label={report} />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        <DefaultModelsTable />
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <div className="font-bold text-xl">Coming soon.</div>
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="2">
        <div className="font-bold text-xl">Coming soon.</div>
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="3">
        <ModelComparisonView />
      </CustomTabPanel>
    </div>
  );
};

export default ProjectModelsManagement;
