import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { type TypeOrganization } from '../../common/dataTypes';
import { useGetOrganizations } from '../../hooks/useOrganizationHook';
import { useGetSearchResults } from '../../hooks/useUtilsHook';
import SearchInput from '../SearchInput';

import OrganizationsTable from './OrganizationsTable';

const ViewAllOrganizations = () => {
  const { data: organizations } = useGetOrganizations();

  const form = useForm();

  const { watch } = form;

  const searchResults = useGetSearchResults<TypeOrganization>({
    searchValue: watch('search'),
    data: organizations,
  });

  return (
    <div className="mx-auto w-full max-w-lg px-12 lg:px-0">
      <div className="flex flex-col gap-6 py-6">
        <h1 className="text-2xl font-bold">Projects</h1>
        <div className="flex items-center justify-between">
          <FormProvider {...form}>
            <form className="flex items-center gap-3">
              <div className="min-w-[300px]">
                <SearchInput />
              </div>
            </form>
          </FormProvider>
        </div>
        <OrganizationsTable defaultOrganizations={searchResults} />
      </div>
    </div>
  );
};

export default ViewAllOrganizations;
