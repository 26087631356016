import React, { useMemo } from 'react';

import { Tooltip } from '@mui/material';
import { DataGridPremium, type GridColDef } from '@mui/x-data-grid-premium';
import { type GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import _, { isNumber } from 'lodash';
import numbro from 'numbro';

import { ModelCompareTooltip } from '../common/dataTableFormatter';

type DataGridPremiumTableProps = {
  apiRef: React.MutableRefObject<GridApiPremium>;
  columns: {
    name: string;
    label: string;
    is_pinned: boolean;
    styles?: Record<string, Record<string, boolean | number | string | any>>[];
  }[];
  treeData?: {
    headerName: string;
    groupColumns: string[];
  };
  isLoading: boolean;
  data: Record<string, (string | number)[]> | undefined;
  base?: {
    data: Record<string, (string | number)[]> | undefined;
    mode: 'compare' | 'vector';
  };
};

const CompareCell = ({
  value,
  baseValue,
  numberFormat,
  isPinned,
}: {
  value: number;
  baseValue: number;
  isPinned: boolean;
  numberFormat: Record<string, any> | undefined;
}) => {
  let result;
  let color;

  if (!baseValue || isPinned) {
    result = value;
  } else if (Number(value) === Number(baseValue)) {
    result = '-';
  } else if (Number(value) > Number(baseValue)) {
    result = Number(value) - Number(baseValue);
    color = '#ff0000';
  } else if (Number(baseValue) > Number(value)) {
    result = Number(baseValue) - Number(value);
    color = 'rgb(46 161 46)';
  }

  return (
    <div className="text-end w-full">
      <ModelCompareTooltip
        numberA={baseValue as number}
        numberB={value as number}
      >
        <span style={{ color }}>
          {isNumber(result) ? numbro(result).format(numberFormat) : result}
        </span>
      </ModelCompareTooltip>
    </div>
  );
};

const VectorCell = ({
  baseValue,
  value,
  numberFormat,
}: {
  value: number;
  baseValue: number;
  numberFormat: Record<string, any> | undefined;
}) => (
  <Tooltip
    disableFocusListener
    title={
      <span style={{ fontSize: '0.875rem' }}>
        Base value: {numbro(baseValue).format(numberFormat ?? {})}
      </span>
    }
  >
    <div
      style={
        value === baseValue
          ? { color: '#000000' }
          : value > baseValue
            ? { color: 'rgb(46 161 46)' }
            : { color: '#ff0000' }
      }
    >
      <span>{value}</span>
    </div>
  </Tooltip>
);

const DataGridPremiumTable = ({
  columns,
  data,
  apiRef,
  isLoading,
  base,
  treeData,
}: DataGridPremiumTableProps) => {
  const cols = useMemo(
    () =>
      columns
        .filter(({ name }) => !treeData?.groupColumns.includes(name))
        .map(({ label, name, is_pinned, styles }) => {
          const mergedStyles = styles
            ? {
                numfmt: styles[styles?.length - 1]?.numfmt ?? {},
                row_filters: [...styles.map((s) => s.row_filters).flat()],
              }
            : {};

          const filters =
            styles
              ?.map((i) =>
                i.row_filters.map((r: any) => ({
                  ...r,
                  numfmt: i.numfmt,
                }))
              )
              .flat() ?? [];

          const rowFilters = mergedStyles.row_filters;

          let numberFormat = {} as Record<string, any> | undefined;

          const baseMode = base?.mode;

          return {
            field: name,
            headerName: label,
            flex: 100,
            minWidth: 140,
            align: 'right',
            headerAlign: 'left',
            hideable: false,
            sortable: false,
            pinnable: is_pinned,
            filterable: false,
            style: {
              backgroundColor: 'black',
              fontWeight: 'bold',
            },
            disableColumnMenu: true,
            renderCell: (params) => {
              const id = params.id;
              const field = params.field;
              const value = params.value;

              const baseValue = base?.data?.[field]?.[id as number];

              if (!rowFilters || rowFilters?.length === 0) {
                numberFormat = mergedStyles.numfmt;
              } else {
                const groupingKey = params.row.path?.[0];

                const rowFilter =
                  filters.find((r) => r.value === groupingKey) ??
                  filters.find((r) => params.row[r.field] === r.value);

                numberFormat = rowFilter?.numfmt;
              }

              if (!isNumber(value) && !isNumber(baseValue)) {
                return (
                  <div className="text-start items-center flex w-full">
                    {value}
                  </div>
                );
              }

              if (!baseValue) {
                return (
                  <div className="text-end w-full">
                    {numbro(value).format(numberFormat)}
                  </div>
                );
              }

              switch (baseMode) {
                case 'compare':
                  return (
                    <CompareCell
                      value={value}
                      isPinned={is_pinned}
                      baseValue={baseValue as number}
                      numberFormat={numberFormat}
                    />
                  );
                case 'vector':
                  return (
                    <VectorCell
                      value={value}
                      baseValue={baseValue as number}
                      numberFormat={numberFormat}
                    />
                  );
                default:
                  <div className="text-end w-full">{value}</div>;
              }
            },
          };
        }) as GridColDef[],
    [base, columns, treeData]
  );

  const rows = useMemo(
    () =>
      data
        ? columns.reduce(
            (acc, cur) => {
              const { name } = cur;

              data[name]?.forEach(
                (value, index) =>
                  (acc[index] = {
                    id: index,
                    ...acc[index],
                    [name]: value,
                  })
              );

              return acc;
            },
            [] as Record<string, string | number>[]
          )
        : [],
    [data, columns]
  );

  const groupRows = rows?.flatMap((a) => ({
    ...(treeData?.groupColumns ? _.omit(a, treeData?.groupColumns) : a),
    path: treeData?.groupColumns.map((c) => a[c]).filter((i) => i !== 'all'),
  }));

  return (
    <div className="h-[50vh]">
      <DataGridPremium
        treeData={!!treeData}
        groupingColDef={
          treeData
            ? {
                headerName: treeData.headerName,
              }
            : {}
        }
        getTreeDataPath={(row) =>
          treeData ? (row.path?.length ? row.path : ['']) : [row.id]
        }
        apiRef={apiRef}
        rows={groupRows}
        columns={cols}
        pagination={false}
        disableSelectionOnClick
        loading={isLoading}
        getRowClassName={(params) => {
          const maxLevel = treeData?.groupColumns.length;
          const groupLevel = params.row.path?.length;

          if (maxLevel === 3) {
            switch (groupLevel) {
              case 1:
                return 'first-level-group-row';
              case 2:
                return 'second-level-group-row';
              default:
                return '';
            }
          } else if (maxLevel === 2) {
            switch (groupLevel) {
              case 1:
                return 'second-level-group-row';
              default:
                return '';
            }
          }

          return '';
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#01285F',
            color: '#FFFFFF !important',
            fontWeight: 'bold',
          },

          '& .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: '#01285F',
          },

          '& .MuiDataGrid-row.first-level-group-row': {
            backgroundColor: '#DCEBFE',
          },

          '& .MuiDataGrid-row.second-level-group-row': {
            backgroundColor: '#F5F9FF',
          },
        }}
        disableAggregation
        density="compact"
        initialState={{ aggregation: { model: { gross: 'sum' } } }}
        pinnedColumns={{
          left: cols.filter((col) => col.pinnable).map((col) => col.field),
        }}
        defaultGroupingExpansionDepth={1}
        experimentalFeatures={{ rowPinning: true }}
      />
    </div>
  );
};

export default DataGridPremiumTable;
