import React from 'react';

import ScenarioInputTable from '../scenario/ScenarioInputTable';

const ModelScenarioOverview = () => (
  <div className="flex flex-col justify-between gap-4 pb-3">
    <h2 className="text-3xl font-bold">Scenario</h2>
    <ScenarioInputTable />
  </div>
);

export default ModelScenarioOverview;
