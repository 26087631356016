import React, { type FC } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Link as MuiLink, type LinkProps } from '@mui/material';

const Link: FC<LinkProps> = (props: any) => (
  <MuiLink {...props} component={ReactRouterLink} to={props.href ?? '#'} />
);

export default Link;
