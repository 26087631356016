import { type Granularity } from '../types';
import { mainApi } from '../utils/api';

export type CalculateEndPeriod = {
  granularity: Granularity;
  start_period: string;
  duration: number;
};

const calculateEndPeriod = (data: CalculateEndPeriod) =>
  mainApi.post(`utils/calculate_end_period/`, data);

export { calculateEndPeriod };
