import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  getDataTableByName,
  type GetDataTableByNameInput,
  getDataTables,
  type GetDataTablesInput,
} from '../api/dataTables';
import { type TypeDataTable } from '../common/dataTypes';

import { type DefaultQueryError } from './index';

export const useGetDataTables = (
  input: GetDataTablesInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataTable[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDataTables', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataTable[]> =
        await getDataTables(input);

      return data;
    },
    ...opts,
  });

export const useGetDataTableByName = (
  input: GetDataTableByNameInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataTable>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDataTableByName', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataTable> =
        await getDataTableByName(input);

      return data;
    },
    ...opts,
  });
