import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { useGetRouteParams } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';

type ExpenseItem = {
  label: string;
  value: number;
  type?: 'default' | 'custom';
};

type ExpenseCollapseProps = {
  title: string;
  items: ExpenseItem[];
  handleSelect?: (id: number) => void;
};

const ExpenseCollapse = ({
  title,
  items,
  handleSelect: iHandleSelect,
}: ExpenseCollapseProps) => {
  const [open, setOpen] = useState(true);

  const { orgId, projectId } = useGetRouteParams();

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (id: number) => {
    if (iHandleSelect) {
      iHandleSelect(id);
      return;
    }

    navigate(`/${orgId}/projects/${projectId}/expenses/${id}`);
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="font-bold capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="border-gray-300 mb-4 ml-[11px] mt-2 flex flex-col overflow-y-hidden border-l pl-4">
          {items.map((e: any) => (
            <div key={e.value} className="flex justify-between">
              <div
                className={cn(
                  'flex transition w-full items-center gap-1 rounded-[4px]'
                )}
              >
                <button
                  type="button"
                  className="w-full p-2 text-start font-normal flex gap-2 items-center"
                  onClick={() => handleSelect(e.value)}
                >
                  <p>{e.label}</p>
                  {e.type === 'custom' && (
                    <span className="rounded-md font-bold bg-[#F5F5F5] text-[#666666] px-[10px] py-1 text-sm">
                      Custom
                    </span>
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default ExpenseCollapse;
