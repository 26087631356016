import React from 'react';

const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
  >
    <path
      d="M32.4997 58.6663C47.2273 58.6663 59.1663 46.7273 59.1663 31.9997C59.1663 17.2721 47.2273 5.33301 32.4997 5.33301C17.7721 5.33301 5.83301 17.2721 5.83301 31.9997C5.83301 46.7273 17.7721 58.6663 32.4997 58.6663Z"
      stroke="#2196F3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.167 33.333L27.167 41.333L45.8337 22.6663"
      stroke="#2196F3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Success;
