import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { autocompletion } from '@codemirror/autocomplete';
import { python } from '@codemirror/lang-python';
import CodeMirror, { EditorState, minimalSetup } from '@uiw/react-codemirror';

import { useGetExpenseFormulaVariables } from '../hooks/useExpenseHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';

const FormQueryInput = ({ name }: { name: string }) => {
  const [value, setValue] = useState('');

  const { setValue: setFormValue, watch } = useFormContext();

  const { orgId, projectId } = useGetRouteParams();

  const defaultValue = watch(name);

  const { data: formulaVariables } = useGetExpenseFormulaVariables(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const onChange = useCallback(
    (val: React.SetStateAction<string>, viewUpdate: any) => {
      setValue(val);
    },
    []
  );

  useEffect(() => {
    setFormValue(name, value, {
      shouldDirty: true,
    });
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <CodeMirror
      value={value}
      height="fit-content"
      extensions={[
        python(),
        minimalSetup(),
        autocompletion({
          override: [
            (context) => {
              const word = context.matchBefore(/[\w$]+/);
              if (!word) {
                return null;
              }

              return {
                from: word.from,
                options:
                  formulaVariables?.map((o) => ({
                    label: '$' + o,
                    type: 'variable',
                  })) ?? [],
                validFor: /^\w*$/,
              };
            },
          ],
        }),
        EditorState.transactionFilter.of((tr) =>
          tr.newDoc.lines > 1 ? [] : [tr]
        ),
      ]}
      onChange={onChange}
      basicSetup={{
        lineNumbers: false,
        autocompletion: true,
        foldGutter: false,
        highlightActiveLineGutter: false,
      }}
    />
  );
};

export default FormQueryInput;
