import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Pencil } from 'lucide-react';

import { useGetDataTables } from '../../hooks/useDataTablesHook';
import {
  useGetActiveScenarios,
  useGetBaseScenario,
  useGetScenarioById,
  useGetScenarioVectors,
} from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';

import ScenarioMenuDropdown from './ScenarioMenuDropdown';

type DataRow = {
  id: string;
  inputTable: string;
  scenario: number | null;
  vectors?: string[];
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('inputTable', {
    cell: (info) => {
      const { orgId, projectId, modelId } = useGetRouteParams();

      const dataTableName = info.row.original.id;
      const scenarioId = Number(info.row.original.scenario);

      const { data: baseScenario } = useGetBaseScenario(
        {
          orgId,
          projectId,
          modelId,
          dataTableName,
        },
        {
          enabled: !!orgId && !!projectId && !!modelId && !!dataTableName,
        }
      );

      const { data: scenario } = useGetScenarioById(
        {
          scenarioId,
          modelId,
          orgId,
          projectId,
        },
        {
          enabled: !!scenarioId && !!modelId && !!orgId && !!projectId,
        }
      );

      const activeScenario = scenario ?? baseScenario;

      const navigate = useNavigate();

      const handleOpenEdit = () => {
        navigate(
          `input-table/${dataTableName}?scenario_id=${activeScenario?.id}`
        );
      };

      return (
        <div className="py-2 text-start" onClick={handleOpenEdit}>
          {info.getValue() ?? '--'}
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Input table</p>
      </div>
    ),
  }),
  columnHelper.accessor('scenario', {
    id: 'scenario',
    cell: (info) => {
      const { orgId, projectId, modelId } = useGetRouteParams();

      const scenarioId = Number(info.getValue());

      const dataTableName = info.row.original.id;

      const { data: scenario } = useGetScenarioById(
        {
          scenarioId,
          modelId,
          projectId,
          orgId,
        },
        {
          enabled: !!scenarioId && !!modelId && !!projectId && !!orgId,
        }
      );

      const { data: baseScenario } = useGetBaseScenario(
        {
          orgId,
          projectId,
          modelId,
          dataTableName,
        },
        {
          enabled: !!orgId && !!projectId && !!modelId && !!dataTableName,
        }
      );

      return (
        <ScenarioMenuDropdown
          dataTableName={dataTableName}
          label={scenario?.name ?? baseScenario?.name ?? ''}
        />
      );
    },
    header: () => <div>Scenario</div>,
  }),
  columnHelper.accessor('vectors', {
    id: 'vectors',
    cell: (info) => {
      const scenarioId = Number(info.row.original.scenario);
      const dataTableName = info.row.original.id;

      const { modelId, projectId, orgId } = useGetRouteParams();

      const { data: vectors } = useGetScenarioVectors(
        {
          scenarioId,
          modelId,
          projectId,
          orgId,
        },
        {
          enabled: !!scenarioId && !!modelId && !!projectId && !!orgId,
        }
      );

      const { data: baseScenario } = useGetBaseScenario(
        {
          orgId,
          projectId,
          modelId,
          dataTableName,
        },
        {
          enabled: !!orgId && !!projectId && !!modelId && !!dataTableName,
        }
      );

      const { data: scenario } = useGetScenarioById(
        {
          scenarioId,
          modelId,
          orgId,
          projectId,
        },
        {
          enabled: !!scenarioId && !!modelId && !!orgId && !!projectId,
        }
      );

      const activeScenario = scenario ?? baseScenario;

      const navigate = useNavigate();

      const handleOpenEdit = () => {
        navigate(
          `input-table/${dataTableName}?scenario_id=${activeScenario?.id}`
        );
      };

      const scenarioVectors = vectors?.filter((v) => v.is_active);

      return (
        <div className="flex justify-between">
          <p className="truncate max-w-[60%]">
            {scenarioVectors?.length
              ? scenarioVectors.map((vector) => vector.name).join(', ')
              : '--'}
          </p>
          <Pencil onClick={handleOpenEdit} size={16} />
        </div>
      );
    },
    header: () => <div>Vectors</div>,
  }),
];

const ScenarioInputTable = () => {
  const { projectId, orgId, modelId } = useGetRouteParams();

  const { data: activeScenarios } = useGetActiveScenarios(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const { data: dataTables } = useGetDataTables(
    {
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!projectId && !!orgId && !!modelId,
    }
  );

  const data = useMemo(
    () =>
      dataTables
        ? dataTables.map((dataTable) => {
            const scenario = activeScenarios?.find(
              (s) => s.data_table_name === dataTable.name
            );

            return {
              id: dataTable.name,
              inputTable: dataTable.display_name ?? dataTable.name ?? 'Unknown',
              scenario: Number(scenario?.id),
            };
          })
        : [],
    [activeScenarios, dataTables]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border border-[#E4E7EC] bg-[#F8F8F8]"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    'border border-[#E4E7EC] px-6 py-2 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer py-2">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border border-[#E4E7EC] px-6 text-[#333]"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScenarioInputTable;
