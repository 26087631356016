import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@mui/material';
import { Plus } from 'lucide-react';

import { type TypeDataset } from '../../common/dataTypes';
import { useGetDatasets } from '../../hooks';
import {
  useGetRouteParams,
  useGetSearchResults,
} from '../../hooks/useUtilsHook';
import CreateDatasetDialog from '../dataset/CreateDatasetDialog';
import DatasetsTable from '../dataset/DatasetsTable';
import LastModifiedFilter from '../LastModifiedFilter';
import SearchInput from '../SearchInput';

const ProjectDatasetsManagement = () => {
  const { projectId, orgId } = useGetRouteParams();

  const [openCreateDatasetDialog, setOpenCreateDatasetDialog] = useState(false);

  const form = useForm();

  const { watch } = form;

  const handleCloseCreateDatasetDialog = () => {
    setOpenCreateDatasetDialog(false);
  };

  const { data: datasets } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const searchResults = useGetSearchResults<TypeDataset>({
    searchValue: watch('search'),
    data: datasets,
  });

  return (
    <div className="flex w-full flex-col gap-6 px-12 pb-6">
      <h1 className="text-2xl font-bold">Dataset</h1>
      <div className="flex items-center justify-between">
        <FormProvider {...form}>
          <form className="flex items-center gap-3">
            <div className="w-[300px]">
              <SearchInput />
            </div>
            <LastModifiedFilter />
          </form>
        </FormProvider>
        <Button
          onClick={() => {
            setOpenCreateDatasetDialog(true);
          }}
          style={{
            color: 'white',
            backgroundColor: '#B8341B',
          }}
        >
          <Plus size={20} />
          <p>New</p>
        </Button>
        <CreateDatasetDialog
          open={openCreateDatasetDialog}
          handleClose={handleCloseCreateDatasetDialog}
        />
      </div>
      <DatasetsTable defaultDatasets={searchResults} />
    </div>
  );
};

export default ProjectDatasetsManagement;
