import React, { type SyntheticEvent, useMemo, useState } from 'react';

import {
  Box,
  Button,
  styled,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@mui/material';
import {
  type ColumnsStylesInterface,
  type GridExcelExportOptions,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownToLine } from 'lucide-react';

import { useGetModelById, useGetModelOutput } from '../../hooks/useModelsHook';
import { useGetProjectOutputSchema } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import DataGridPremiumTable from '../DataGridPremiumTable';

import { type Report } from './ModelOutputTabs';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

type StyledTabProps = {
  label: string;
  id: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#B8341B',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  '&.MuiButtonBase-root': {
    padding: '10px 10px !important',
  },
  '&.Mui-selected': {
    color: '#B8341B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className=""
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

type ModelComparisonOutputTabsProps = {
  baseId: number;
  targetId: number;
};

const ModelComparisonOutputTabs = ({
  baseId,
  targetId,
}: ModelComparisonOutputTabsProps) => {
  const [index, setIndex] = useState(0);
  const [report, setReport] = useState<Report>('financials');

  const { orgId, projectId, modelId } = useGetRouteParams();

  const { data: model } = useGetModelById(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const { data: baseModelOutput, isFetching: isFetchingModelOutput } =
    useGetModelOutput(
      {
        modelId: baseId,
        orgId,
        projectId,
      },
      {
        enabled: !!baseId && !!orgId && !!projectId,
      }
    );

  const { data: modelOutput, isFetching: isFetchingBaseModelOutput } =
    useGetModelOutput(
      {
        modelId: targetId,
        orgId,
        projectId,
      },
      {
        enabled: !!orgId && !!projectId && !!targetId,
      }
    );

  const { data: outputSchema } = useGetProjectOutputSchema(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const reports = Object.keys(modelOutput ?? {}) as Report[];

  const apiRef = useGridApiRef();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const value = event.currentTarget.id as Report;

    setIndex(newValue);
    setReport(value);
  };

  const dataTable = useMemo(
    () =>
      outputSchema?.sections
        ?.find((s) => s.name === report)
        ?.data_tables.find((d) => d.name === report),
    [outputSchema, report]
  );

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};

    dataTable?.columns.forEach((colDef) => {
      colStyles[colDef.name] = {
        numFmt: '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: `${model?.name}_${report}`,
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {reports && (
        <div className="flex items-center justify-between">
          <Tabs value={index} onChange={handleChange}>
            {outputSchema?.sections?.map((s) => (
              <Tab id={s.name} key={s.name} label={s.display_name} />
            ))}
          </Tabs>
          <div className="flex items-center gap-1">
            <Button
              size="small"
              onClick={handleExportToExcel}
              variant="outlined"
              className="space-x-1 font-semibold"
            >
              <ArrowDownToLine />
              <label>Export to Excel</label>
            </Button>
          </div>
        </div>
      )}
      {report && (
        <div className="py-6">
          <CustomTabPanel value={report} index={report}>
            <DataGridPremiumTable
              apiRef={apiRef}
              columns={
                dataTable?.columns.map((c) => ({
                  label: c.display_name,
                  name: c.name,
                  is_pinned: c.is_pinned,
                })) ?? []
              }
              isLoading={isFetchingBaseModelOutput || isFetchingModelOutput}
              base={{
                data: baseModelOutput?.[report],
                mode: 'compare',
              }}
              data={modelOutput?.[report]}
            />
          </CustomTabPanel>
        </div>
      )}
    </Box>
  );
};

export default ModelComparisonOutputTabs;
