import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function PasswordResetFail() {
  return (
    <Container>
      <Typography component="h1" variant="h5" align="center">
        The link is invalid or expired.
      </Typography>
    </Container>
  );
}

export default PasswordResetFail;
