import React, { useEffect } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Plus } from 'lucide-react';
import { z } from 'zod';

import { type TypeScenario } from '../../common/dataTypes';
import { useGetDataTableByName } from '../../hooks/useDataTablesHook';
import {
  useCreateScenario,
  useUpdateScenarioById,
} from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import FormInput from '../FormInput';

type CreateScenarioFormDialogProps = {
  open: boolean;
  title: string;
  showTrigger?: boolean;
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
  };
  defaultValues?: {
    id: number;
    name: string;
    description?: string;
  };
  dataTableName?: string;
  handleCloseDialog: () => void;
  handleSubmitDialog?: (data: TypeScenario) => void;
  handleOpenDialog?: () => void;
};

const CreateScenarioSchema = z.object({
  name: z.string().trim(),
  description: z.string().trim().optional(),
  dataTable: z.string().trim(),
});

type CreateScenarioSchemaType = z.infer<typeof CreateScenarioSchema>;

const CreateScenarioFormDialog = ({
  open,
  title,
  trigger,
  showTrigger = true,
  defaultValues,
  handleOpenDialog,
  dataTableName,
  handleCloseDialog,
  handleSubmitDialog,
}: CreateScenarioFormDialogProps) => {
  const form = useForm<CreateScenarioSchemaType>({
    resolver: zodResolver(CreateScenarioSchema),
  });

  const { handleSubmit, reset } = form;
  const { projectId, inputTableName, orgId, modelId } = useGetRouteParams();

  const { data: dataTable } = useGetDataTableByName(
    {
      dataTableName: inputTableName ?? dataTableName ?? '',
      orgId,
      projectId,
      modelId,
    },
    {
      enabled:
        (!!inputTableName || !!dataTableName) &&
        !!orgId &&
        !!projectId &&
        !!modelId,
    }
  );

  const { mutate: createScenario, isPending: isCreatingScenario } =
    useCreateScenario({
      onSuccess: (data) => {
        reset();
        handleCloseDialog();
        handleSubmitDialog?.(data);
      },
    });

  const { mutate: updateScenario, isPending: isUpdatingScenario } =
    useUpdateScenarioById({
      onSuccess: () => {
        reset();
        handleCloseDialog();
      },
    });

  useEffect(() => {
    if (dataTable) {
      reset({
        ...defaultValues,
        dataTable: dataTable.name,
      });
    }
  }, [defaultValues, dataTable]);

  const onSubmit: SubmitHandler<CreateScenarioSchemaType> = (data) => {
    if (!dataTable) {
      return;
    }

    if (defaultValues) {
      updateScenario({
        orgId,
        projectId,
        modelId,
        id: defaultValues.id,
        data: {
          name: data.name,
          description: data.description,
          data_table_name: dataTable.name,
          model: modelId,
        },
      });
      return;
    }

    createScenario({
      orgId,
      projectId,
      modelId,
      data: {
        name: data.name,
        description: data.description,
        data_table_name: dataTable.name,
        model: modelId,
      },
    });
  };

  return (
    <>
      {showTrigger && (
        <button
          onClick={handleOpenDialog}
          className="text-start"
          type="button"
          style={trigger?.style}
        >
          {trigger ? (
            trigger.label
          ) : (
            <Plus width={20} height={20} color="#E8F2FF" />
          )}
        </button>
      )}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="px-6 pt-2 text-2xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div className="z-50 px-6">
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-2 space-y-6"
              >
                <div className="space-y-4">
                  <FormInput label="Scenario Name" name="name" required />
                  <FormInput
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                  />
                  {!defaultValues && (
                    <>
                      <FormInput
                        label="Input Table"
                        name="dataTable"
                        disabled
                      />
                    </>
                  )}
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isCreatingScenario || isUpdatingScenario}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    {defaultValues ? 'Save' : 'Create'}
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateScenarioFormDialog;
