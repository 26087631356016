import { mainApi } from '../utils/api';

export type GetDataTablesInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetDataTableByNameInput = {
  orgId: number;
  projectId: number;
  dataTableName: string;
  modelId: number;
};

const getDataTables = ({ orgId, projectId, modelId }: GetDataTablesInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/data_table_schemas/`
  );

const getDataTableByName = ({
  orgId,
  projectId,
  dataTableName,
  modelId,
}: GetDataTableByNameInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/data_table_schemas?data_table_name=${dataTableName}`
  );

export { getDataTables, getDataTableByName };
