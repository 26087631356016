import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { Avatar, Button } from '@mui/material';
import { CloudUpload } from 'lucide-react';
import * as z from 'zod';

import { toastPromise } from '../common/utils';
import { useChangePassword } from '../hooks/useAuthHook';
import { useGetUser, useUpdateUser } from '../hooks/useUserHook';
import { handleQueryError } from '../utils/api';
import cn from '../utils/cn';

import FormInput from './FormInput';

const PersonalInfoSchema = z.object({
  first_name: z.string().trim().optional(),
  last_name: z.string().trim().optional(),
  profile_pic: z.custom<FileList>(),
});

type PersonalInfoSchemaType = z.infer<typeof PersonalInfoSchema>;

const PersonalInfoForm = () => {
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const { data: user } = useGetUser();
  const [isDragOver, setIsDragOver] = useState(false);

  const form = useForm<PersonalInfoSchemaType>({
    resolver: zodResolver(PersonalInfoSchema),
  });

  const { handleSubmit, reset, formState, setValue, watch } = form;

  const { dirtyFields } = formState;

  useEffect(() => {
    if (user) {
      reset({
        first_name: user.first_name,
        last_name: user.last_name,
      });

      setImageSrc(user.profile_pic);
    }
  }, [user]);

  const { mutateAsync: updateUser } = useUpdateUser();

  const profilePic = watch('profile_pic');

  const onSubmit: SubmitHandler<PersonalInfoSchemaType> = (data) => {
    const currentFileName = user?.profile_pic?.split('/').pop();

    if (
      !user ||
      (!Object.keys(dirtyFields ?? {})?.length &&
        currentFileName === profilePic?.[0].name)
    ) {
      return;
    }

    const res = updateUser({
      id: user.id,
      data: {
        ...data,
        profile_pic: data.profile_pic?.[0],
      },
    });

    toastPromise({
      promise: res,
      content: 'Your changes has been updated successfully',
    });
  };

  const onDrop = () => {
    setIsDragOver(false);
  };

  const onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleUploadImage = (e: { target: { files: any } }) => {
    const formData = new FormData();

    Array.from(e.target.files as File[]).forEach((file: File) => {
      formData.append('avatar', file);
    });

    setValue('profile_pic', e.target.files);

    const reader = new FileReader();

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(e.target.files?.[0]);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 rounded-[4px] border border-[#E4E7EC] p-6"
      >
        <div className="flex flex-col">
          <h2 className="text-xl font-bold">Personal info</h2>
          <p className="text-[#666]">
            Update your photo and personal details here.
          </p>
        </div>

        <div className="grid grid-cols-5 pt-6">
          <label className="col-span-2 flex items-center font-semibold text-[#333333]">
            Name
          </label>
          <div className="col-span-3 flex gap-3 lg:pr-40">
            <FormInput name="first_name" fullWidth placeholder="First name" />
            <FormInput name="last_name" fullWidth placeholder="Last name" />
          </div>
        </div>

        <div className="grid grid-cols-5 border-t border-[#E4E7EC] pt-6">
          <label className="col-span-2 flex items-center font-semibold text-[#333333]">
            Email address
          </label>
          <div className="col-span-3 lg:pr-40">
            <FormInput name="email" fullWidth disabled value={user?.email} />
          </div>
        </div>

        <div className="grid grid-cols-5 border-t border-[#E4E7EC] pt-6">
          <div className="col-span-2">
            <label className="flex items-center font-semibold text-[#333333]">
              Your photo
            </label>
            <p className="text-[14px] text-[#666]">
              This will be displayed on your profile
            </p>
          </div>
          <div className="col-span-3 flex gap-6 lg:pr-40">
            <Avatar
              alt="avatar"
              src={imageSrc as string}
              style={{
                width: '70px',
                height: '70px',
              }}
            />
            <div
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragLeave={onDrop}
              className={cn(
                'relative transition flex w-full flex-col items-center gap-3 rounded-[4px] border border-[#CCC] py-6 text-[#666]',
                {
                  'bg-[#F5F9FF] border-dashed border-[#2196F3] text-[#2196F3]':
                    isDragOver,
                }
              )}
            >
              <input
                type="file"
                accept=".png,.jpg"
                onChange={handleUploadImage}
                className="absolute h-full w-full opacity-0"
              />
              <CloudUpload width={30} height={30} />
              <div className="space-y-1 text-center">
                <p>
                  <span className="font-semibold text-primary-red">
                    Click to upload
                  </span>{' '}
                  or drag and drop
                </p>
                <p>PNG or JPG (max. 800x800px)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#B8341B',
            }}
          >
            Update info
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const ChangePasswordSchema = z
  .object({
    current_password: z.string().trim().min(1, 'Current password is required'),
    new_password: z.string().trim().min(1, 'New password is required'),
    confirm_new_password: z
      .string()
      .trim()
      .min(1, 'Confirm new password is required'),
  })
  .refine((data) => data.new_password === data.confirm_new_password, {
    path: ['confirm_new_password'],
    message: 'Confirm password does not match',
  });

type ChangePasswordSchemaType = z.infer<typeof ChangePasswordSchema>;

const ChangePasswordForm = () => {
  const [error, setError] = React.useState<Record<string, string>>({});

  const form = useForm<ChangePasswordSchemaType>({
    resolver: zodResolver(ChangePasswordSchema),
  });

  const { handleSubmit, setError: setFormError, reset, clearErrors } = form;

  const { mutateAsync: changePassword } = useChangePassword({
    onSuccess() {
      clearErrors();
      setError({});
      reset();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  useEffect(() => {
    if (error) {
      Object.keys(error).forEach((key) => {
        setFormError(key as keyof ChangePasswordSchemaType, {
          type: 'custom',
          message: error[key],
        });
      });
    }
  }, [error]);

  const onSubmit: SubmitHandler<ChangePasswordSchemaType> = async (data) => {
    const { current_password, new_password } = data;

    const res = changePassword({
      data: {
        current_password,
        new_password,
      },
    });

    toastPromise({
      promise: res,
      content: 'Your password has been updated successfully',
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 rounded-[4px] border border-[#E4E7EC] p-6"
      >
        <div className="flex flex-col">
          <h2 className="text-xl font-bold">Change password</h2>
        </div>

        <div className="grid grid-cols-5">
          <label className="col-span-2 flex items-center font-semibold text-[#333333]">
            Current password
          </label>
          <div className="col-span-3 flex gap-3 lg:pr-40">
            <FormInput name="current_password" type="password" fullWidth />
          </div>
        </div>

        <div className="grid grid-cols-5 border-t border-[#E4E7EC] pt-6">
          <label className="col-span-2 flex items-center font-semibold text-[#333333]">
            New password
          </label>
          <div className="col-span-3 lg:pr-40">
            <FormInput name="new_password" type="password" fullWidth />
          </div>
        </div>

        <div className="grid grid-cols-5 border-t border-[#E4E7EC] pt-6">
          <label className="col-span-2 flex items-center font-semibold text-[#333333]">
            Confirm new password
          </label>
          <div className="col-span-3 lg:pr-40">
            <FormInput name="confirm_new_password" type="password" fullWidth />
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#B8341B',
            }}
          >
            Change password
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const AccountSettings = () => (
  <div className="flex flex-col gap-6 px-12 py-6 pb-20">
    <h1 className="text-[28px] font-bold">Account Settings</h1>
    <PersonalInfoForm />
    <ChangePasswordForm />
  </div>
);

export default AccountSettings;
