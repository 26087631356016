import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as z from 'zod';

import { type CreateModelInput } from '../../api/models';
import { useCreateModel, useGetDatasets, useUpdateModel } from '../../hooks';
import { useGetModels } from '../../hooks/useModelsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const CreateModelSchema = z.object({
  name: z.string().min(1, "Model name can't be empty").trim(),
  description: z.string().trim().optional(),
  dataset: z.number(),
  copy_scenarios_from: z.number().or(z.string()).optional(),
  isCreateNew: z.boolean().default(false),
  newDatasetName: z.string().trim().optional(),
  newDatasetDescription: z.string().trim().optional(),
});

type CreateModelSchemaType = z.infer<typeof CreateModelSchema>;

type CreateModelFormDialogProps = {
  open: boolean;
  title: string;
  defaultValues?: {
    name: string;
    description: string;
    dataset: number;
  };
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
};

const CreateModelFormDialog = ({
  open,
  title,
  handleOpenDialog,
  handleCloseDialog,
  defaultValues,
}: CreateModelFormDialogProps) => {
  const [error, setError] = useState();

  const { projectId, orgId, modelId } = useGetRouteParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: datasets } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: models } = useGetModels(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const form = useForm<CreateModelSchemaType>({
    resolver: zodResolver(CreateModelSchema),
    defaultValues: defaultValues
      ? {
          name: defaultValues.name,
          description: defaultValues.description,
          dataset: defaultValues.dataset,
        }
      : {
          name: '',
          description: '',
          copy_scenarios_from: NaN,
          dataset: NaN,
        },
  });

  const { handleSubmit, formState, watch, reset, register } = form;
  const { isSubmitting } = formState;

  const isCreateNewDataset = watch('isCreateNew');

  useEffect(() => {
    reset({
      ...defaultValues,
      copy_scenarios_from: NaN,
    });
  }, [defaultValues]);

  useEffect(() => {
    if (datasets) {
      reset({
        dataset: datasets[0].id,
      });
    }
  }, [datasets]);

  const { mutate: createModel } = useCreateModel({
    onSuccess(data) {
      searchParams.set('model_id', data.id?.toString());

      setSearchParams(searchParams);

      reset();
      handleCloseDialog();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  const { mutate: updateModel } = useUpdateModel({
    onSuccess() {
      reset();
      handleCloseDialog();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  const onSubmit: SubmitHandler<CreateModelSchemaType> = async () => {
    const watchData = watch();

    const {
      name,
      description,
      dataset,
      newDatasetName,
      newDatasetDescription,
      copy_scenarios_from,
      isCreateNew,
    } = watchData;

    const data = {
      name,
      description,
      dataset: Number(dataset),
      project: projectId,
      copy_dataset: isCreateNew,
      new_dataset_name: newDatasetName,
      new_dataset_description: newDatasetDescription,
    } as CreateModelInput['data'];

    if (Number(copy_scenarios_from)) {
      data['copy_scenarios_from'] = Number(copy_scenarios_from);
    }

    if (defaultValues) {
      updateModel({
        orgId,
        projectId,
        modelId,
        data: {
          ...data,
        },
      });
      return;
    }

    createModel({
      orgId,
      projectId,
      data,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCloseDialog();
        reset();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="w-full">
        <h2 className="pt-2 text-2xl font-bold">{title}</h2>
      </DialogTitle>
      <DialogContent>
        <div>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className="mb-2 space-y-4">
              <div className="space-y-4">
                <>
                  <FormInput
                    label="Model Name"
                    name="name"
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                  <FormInput
                    label="Model Description"
                    name="description"
                    multiline
                    rows={5}
                  />
                </>
                {models && models?.length > 0 && !defaultValues && (
                  <FormSelect
                    label="Copy scenario from another model"
                    name="copy_scenarios_from"
                    defaultValue={'none'}
                    options={[
                      {
                        value: 'none',
                        label: "Don't copy",
                      },
                      ...models.map((model) => ({
                        value: model.id,
                        label: model.name,
                      })),
                    ]}
                  />
                )}
                {datasets && !defaultValues && (
                  <FormSelect
                    label="Select Dataset"
                    name="dataset"
                    defaultValue={datasets[0]?.id}
                    options={datasets
                      .filter((d) => d.is_data_ready)
                      .map((dataset) => ({
                        value: dataset.id,
                        label: dataset.name,
                      }))}
                  />
                )}
                {!defaultValues && (
                  <div className="flex items-center gap-1">
                    <Checkbox {...register('isCreateNew')} />
                    <p>Create new dataset base on this dataset.</p>
                  </div>
                )}
                {isCreateNewDataset && (
                  <>
                    <FormInput
                      label="Dataset Name"
                      name="newDatasetName"
                      inputProps={{
                        maxLength: 30,
                      }}
                      required
                    />
                    <FormInput
                      label="Dataset Description"
                      name="newDatasetDescription"
                      multiline
                      rows={5}
                    />
                  </>
                )}
              </div>
              <div className="flex-end flex justify-end gap-3">
                <Button
                  onClick={handleCloseDialog}
                  variant="outlined"
                  style={{
                    color: '#666',
                    borderColor: '#B3B3B3',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  style={{
                    backgroundColor: '#2196F3',
                    textTransform: 'capitalize',
                    color: '#FFF',
                  }}
                >
                  {defaultValues ? 'Save' : 'Create'}
                </LoadingButton>
              </div>
            </form>
          </FormProvider>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModelFormDialog;
