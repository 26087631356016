import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { getSentInvitations } from '../api';
import { type GetSentInvitationsInput } from '../api/sentInvitations';
import { type TypeSentSharedInvitation } from '../common/dataTypes';

import { type DefaultQueryError } from './index';

export const useGetSentInvitations = (
  input: GetSentInvitationsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeSentSharedInvitation[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getSentInvitations'],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSentSharedInvitation[]> =
        await getSentInvitations(input);

      return data;
    },
    ...opts,
  });
