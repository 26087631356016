import React, { useEffect, useMemo } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { z } from 'zod';

import {
  useCreateUserDefinedExpense,
  useGetPreDefinedExpenses,
} from '../../hooks/useExpenseHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

type CreateScenarioFormDialogProps = {
  open: boolean;
  title: string;
  handleOpen: () => void;
  handleClose: () => void;
  defaultValues?: {
    name: string;
    category?: string;
    description?: string;
    isCustom?: boolean;
  };
};

const CreateScenarioSchema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  group: z.number({ message: 'Group is required' }),
  description: z.string().trim().optional(),
  isCustom: z.boolean().optional(),
});

export type AddExpenseFormSchemaType = z.infer<typeof CreateScenarioSchema>;

const AddExpenseFormDialog = ({
  open,
  title,
  defaultValues,
  handleOpen,
  handleClose,
}: CreateScenarioFormDialogProps) => {
  const { orgId, projectId } = useGetRouteParams();

  const form = useForm<AddExpenseFormSchemaType>({
    resolver: zodResolver(CreateScenarioSchema),
    defaultValues,
  });

  const { formState, handleSubmit, reset } = form;

  const { isSubmitting } = formState;

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const groups = useMemo(
    () =>
      Array.from(new Set(predefinedExpenseSets?.map((p) => p.group.name)))
        .map((e) => {
          const expense = predefinedExpenseSets?.find(
            (g) => g.group.name === e
          );

          if (!expense) {
            return null;
          }

          return {
            label: expense.group.name,
            value: expense.group.id,
          };
        })
        .filter((e) => e) as { label: string; value: number }[],
    [predefinedExpenseSets]
  );

  const { mutate: createCustomExpense, isPending: isCreatingCustomExpense } =
    useCreateUserDefinedExpense({
      onSuccess: () => {
        reset();
      },
    });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleCloseDialog = () => {
    handleClose();
    reset();
  };

  const submit: SubmitHandler<AddExpenseFormSchemaType> = (data) => {
    createCustomExpense({
      orgId,
      projectId,
      data: {
        ...data,
        formula: '0',
        project: projectId,
      },
    });
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="px-6 pt-2 text-2xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div className="z-50 px-6">
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(submit)} className="mb-2 space-y-6">
                <div className="space-y-2">
                  <FormInput label="Name" name="name" fullWidth />
                  {groups && (
                    <FormSelect
                      name="group"
                      label="Category"
                      options={groups}
                    />
                  )}
                  <FormInput
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                  />
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting || isCreatingCustomExpense}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    {defaultValues ? 'Save' : 'Create'}
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddExpenseFormDialog;
