import React from 'react';

import _ from 'lodash';

import {
  useGetOrgInvitations,
  useGetOrgMembers,
} from '../../hooks/useOrganizationHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import UsersTable from '../UsersTable';

const roles = [
  {
    label: 'Account Admin',
    value: 'organization_admin',
  },
  {
    label: 'Project Owner',
    value: 'project_owner',
  },
  {
    label: 'Project Member',
    value: 'project_member',
  },
];

const UserManagement = () => {
  const { orgId } = useGetRouteParams();

  const { data: org_invitations } = useGetOrgInvitations(orgId, {
    enabled: !!orgId,
  });

  const { data: org_members } = useGetOrgMembers(orgId, {
    enabled: !!orgId,
  });

  const totalUsersAmount =
    Number(org_invitations?.length) + Number(org_members?.length);

  const activeUsersAmount = Number(org_members?.length);

  const accountAdminsAmount = Number(
    org_members?.filter((member) => member.role === 'organization_admin').length
  );

  return (
    <div className="mx-auto flex max-w-lg flex-col space-y-6 px-2 py-6">
      <div className="flex w-full justify-between">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold text-[#333]">User Management</h1>
          <p className="text-[#666]">Coming soon.</p>
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Total users</p>
          <p className="text-4xl font-semibold text-primary-red">
            {!_.isNaN(totalUsersAmount) ? totalUsersAmount : '-'}
          </p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Active users</p>
          <p className="text-4xl font-semibold text-primary-red">
            {!_.isNaN(activeUsersAmount) ? activeUsersAmount : '-'}
          </p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Account Admins</p>
          <p className="text-4xl font-semibold text-primary-red">
            {!_.isNaN(accountAdminsAmount) ? accountAdminsAmount : '-'}
          </p>
        </div>
      </div>
      <div className="w-full">
        <UsersTable />
      </div>
    </div>
  );
};

export default UserManagement;
