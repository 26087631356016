import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ArrowUpward } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import {
  useGetOrgInvitations,
  useGetOrgMembers,
} from '../../hooks/useOrganizationHook';
import { useGetProjectMembers } from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { type ProjectRole, type UserStatus } from '../../types';
import cn from '../../utils/cn';
import { transformToLabel, useCurrentMembership } from '../../utils/helpers';
import ConfirmDeleteUserDialog from '../userManagement/ConfirmDeleteUserDialog';
import ResendOrgInvitation from '../userManagement/ResendOrgInvitation';

export type DataRow = {
  id: number;
  user: {
    id: number;
    name?: string | null;
    avatarImage: string;
    email: string;
  };
  role: ProjectRole;
  lastActive: string | null;
  status: UserStatus;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('user', {
    cell: (info) => {
      const { email, name, avatarImage } = info.getValue();

      return (
        <div className="group flex items-center gap-1 py-2 text-start">
          <div className="flex items-center gap-2">
            <Avatar alt="User avatar" src={avatarImage} />
            <div className="flex flex-col">
              <div className="flex w-full justify-between gap-3">
                <p
                  className={cn('font-semibold', {
                    'opacity-70': !name,
                  })}
                >
                  {name ?? 'Unknown'}
                </p>
              </div>
              <p className="text-sm text-[#666]">{email}</p>
            </div>
          </div>
        </div>
      );
    },
    header: (info) => (
      <div className="flex items-center gap-1">
        <p>User</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('lastActive', {
    id: 'lastActive',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-start">--</div>
      ),
    header: () => <div>Last active</div>,
  }),
  columnHelper.accessor('role', {
    id: 'role',
    cell: (info) => {
      const role = info.getValue();

      return (
        <div className="flex max-w-[150px] items-center justify-between rounded-[4px] bg-[#F8F8F8] p-2 capitalize">
          <p className="text-sm">{transformToLabel(role)}</p>
        </div>
      );
    },
    header: () => <div>Role</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const { data: currentUser } = useGetUser();

      const { orgId } = useGetRouteParams();

      const membership = useCurrentMembership();

      const { data: org_invitations } = useGetOrgInvitations(orgId, {
        enabled:
          !!orgId && membership.organization?.role === 'organization_admin',
      });

      const { data: org_members } = useGetOrgMembers(orgId, {
        enabled:
          !!orgId && membership.organization?.role === 'organization_admin',
      });

      const row = info.row.original.user;

      const user = {
        ...row,
        type:
          status === 'pending'
            ? 'invitation'
            : ('member' as 'invitation' | 'member'),
      };

      const member = org_members?.find((m) => m.user.id === user.id);

      const invitation = org_invitations?.find(
        (i: { email: string }) => i.email === user.email
      );

      if (member) {
        user['id'] = member.id;
      } else if (invitation) {
        user['id'] = invitation.id;
      }

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'active' | 'pending';
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return (
        <div className="flex items-center justify-between">
          {status === 'active' ? (
            <StatusLabel value="active" label="Active" />
          ) : null}
          {status === 'pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {row.id !== currentUser?.id && (
            <div className="flex gap-4">
              {invitation && (
                <ResendOrgInvitation
                  invitationId={invitation.id}
                  orgId={invitation.organization}
                />
              )}
              <ConfirmDeleteUserDialog user={user} level={'project'} />
            </div>
          )}
        </div>
      );
    },
    header: () => <div>Status</div>,
  }),
];

const ProjectMembersTable = () => {
  const [data, setData] = useState<DataRow[]>([]);

  const { projectId, orgId } = useGetRouteParams();

  const membership = useCurrentMembership();

  const { data: members } = useGetProjectMembers(
    {
      projectId,
      orgId,
    },
    {
      enabled:
        !!orgId &&
        !!projectId &&
        membership.organization?.role === 'organization_admin',
    }
  );

  const form = useForm();
  const { watch } = form;

  const selectedUsers = watch('selectedUsers');

  useEffect(() => {
    const dataRows: DataRow[] = [];

    if (members && members?.length > 0) {
      members.forEach((m) =>
        dataRows.push({
          id: m.id,
          lastActive: m.date_joined,
          user: {
            id: m.user.id,
            name: `${m.user.first_name} ${m.user.last_name}`,
            avatarImage: m.user.profile_pic,
            email: m.user.email,
          },
          role: m.role,
          status: 'active',
        })
      );
    }

    setData(dataRows);
  }, [members]);

  const isIndeterminate = selectedUsers
    ? Object.keys(selectedUsers).some((key) => selectedUsers[key])
    : false;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <FormProvider {...form}>
        <form>
          <table className="w-full">
            {!isIndeterminate && (
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="!rounded-md bg-[#F8F8F8]">
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        className={cn(
                          'first:border-l last:border-r border-t border-b px-6 py-2 text-start text-sm text-[#4D4D4D]',
                          {
                            'w-[45%]': index === 0,
                          }
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="cursor-pointer px-6 py-3">
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className={cn(
                        'first:border-l last:border-r border-b border-[#E4E7EC] px-6 text-[#333]',
                        {
                          'w-[45%]': index === 0,
                        }
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!table.getRowModel().rows.length && (
            <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
              <p className="text-lg text-[#999]">
                No users available. Please invite a user.
              </p>
            </div>
          )}
        </form>
      </FormProvider>
      <div className="h-4" />
    </div>
  );
};

export default ProjectMembersTable;
