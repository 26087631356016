import React, { type HtmlHTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Pencil } from 'lucide-react';

import IconButton from '../../../common/IconButton';

import EditAircraftForm from './EditAircraftForm';

type AircraftData = {
  aircraft: string;
  airports: string[];
};

type EditAircraftDialogProps = {
  trigger?: {
    label: React.ReactNode;
    className?: HtmlHTMLAttributes<HTMLButtonElement>['className'];
  };
  defaultValues: AircraftData;
};

const EditAircraftDialog = ({
  trigger,
  defaultValues,
}: EditAircraftDialogProps) => {
  const [open, setOpen] = useState(false);

  const { watch, setValue } = useFormContext();

  const aircrafts = watch('aircrafts') as AircraftData[];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditAircraft = (data: {
    origin: string;
    aircraft: string;
    airports: string[];
  }) => {
    const updatedAircrafts = aircrafts
      .map((item, index) => {
        const isAircraftExists = aircrafts.some(
          (a) => a.aircraft === data.aircraft
        );

        if (item.aircraft === data.aircraft && item.aircraft !== data.origin) {
          return {
            ...item,
            airports: Array.from(new Set([...data.airports, ...item.airports])),
          };
        } else if (
          item.aircraft === data.aircraft &&
          item.aircraft === data.origin
        ) {
          return {
            ...item,
            airports: data.airports,
          };
        }

        if (item.aircraft !== data.aircraft && item.aircraft === data.origin) {
          if (isAircraftExists) {
            return null;
          }

          return {
            aircraft: data.aircraft,
            airports: data.airports,
          };
        }

        return item;
      })
      .filter((item) => item !== null);

    setValue(
      'aircrafts',
      updatedAircrafts.map((item, index) => ({
        ...item,
        hierarchy: index + 1,
      }))
    );
    setOpen(false);
  };

  return (
    <>
      <IconButton
        icon={<Pencil width={20} height={20} color="#666" />}
        onClick={handleOpen}
      />
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <h3 className="text-xl">Edit aircraft and airports</h3>
        </DialogTitle>
        <DialogContent>
          <EditAircraftForm
            defaultValues={defaultValues}
            handlePrimaryAction={handleEditAircraft}
            handleSecondaryAction={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditAircraftDialog;
