import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';
import { Plus } from 'lucide-react';

import { useGetUserDefinedExpenses } from '../../hooks/useExpenseHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';

import AddExpenseFormDialog from './AddExpenseFormDialog';
import ExpenseCollapse from './ExpenseCollapse';

const CustomExpensesTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openAddNew = searchParams.get('add');

  const [openCreateExpenseDialog, setOpenCreateExpenseDialog] = useState(
    !!openAddNew ?? false
  );

  const { orgId, projectId } = useGetRouteParams();

  const form = useForm();

  const { data: userDefinedExpenses } = useGetUserDefinedExpenses(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const expenses = useMemo(
    () =>
      userDefinedExpenses?.reduce(
        (acc, cur) => {
          const group = cur.group?.name;

          acc[group] = [
            ...(acc[group] ?? []),
            {
              label: cur.name,
              value: cur.id,
            },
          ];

          return acc;
        },
        {} as Record<string, { label: string; value: number }[]>
      ),
    [userDefinedExpenses]
  );

  const handleOpenCreateExpenseDialog = () => {
    setOpenCreateExpenseDialog(true);
  };

  const handleCloseCreateExpenseDialog = () => {
    searchParams.delete('add');
    setSearchParams(searchParams);

    setOpenCreateExpenseDialog(false);
  };

  return (
    <FormProvider {...form}>
      <form className="flex flex-col gap-4">
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold">Custom Expenses</h1>
          <>
            <Button
              onClick={handleOpenCreateExpenseDialog}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
              }}
            >
              <div className="flex cursor-pointer items-center gap-1">
                <Plus width={20} height={20} />
                <label className="cursor-pointer">Add</label>
              </div>
            </Button>
            <AddExpenseFormDialog
              title="Add Custom Expense"
              open={openCreateExpenseDialog}
              handleOpen={handleOpenCreateExpenseDialog}
              handleClose={handleCloseCreateExpenseDialog}
            />
          </>
        </div>
        <div>
          {expenses &&
            Object.keys(expenses).map((group) => (
              <ExpenseCollapse title={group} items={expenses[group]} />
            ))}
        </div>
      </form>
    </FormProvider>
  );
};

export default CustomExpensesTab;
