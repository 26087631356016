import React, { type SyntheticEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import {
  type ColumnsStylesInterface,
  type GridExcelExportOptions,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { type GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ArrowDownToLine } from 'lucide-react';

import { type OutputSChemaDataTable } from '../../common/dataTypes';
import { useGetDatasetById } from '../../hooks';
import { useGetIFSConfig } from '../../hooks/useDatasetsHook';
import {
  useGetModelById,
  useGetModelOutput,
  useGetModels,
} from '../../hooks/useModelsHook';
import { useGetProjectOutputSchema } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import DataGridPremiumTable from '../DataGridPremiumTable';

import CustomMenuDropdown from './CustomMenuDropdown';

export type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

export type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export type StyledTabProps = {
  label: string;
  id: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#B8341B',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  '&.MuiButtonBase-root': {
    padding: '10px 10px !important',
  },
  '&.Mui-selected': {
    color: '#B8341B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

export const Reports = {
  average_expense: 'Average Expenses',
  average_rate: 'Average Rate',
  stats: 'Stats',
  kpis: 'KPIs',
  financials: 'Financials',
  crew_requirements: 'Crew Requirements',
  movement_matrix: 'Movement Matrix',
  training: 'Training',
  seniority_distribution: 'Seniority Distribution',
};

export type Report = keyof typeof Reports;

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const ModelTable = ({
  dataTable,
  apiRef,
}: {
  apiRef: React.MutableRefObject<GridApiPremium>;
  dataTable: OutputSChemaDataTable;
}) => {
  const report = dataTable.name;

  const { orgId, projectId, modelId } = useGetRouteParams();

  const { formState } = useFormContext();

  const { isSubmitting } = formState;

  const {
    data: modelOutput,
    isFetching: isFetchingModelOutput,
    isRefetching: isRefetchingModelOutput,
  } = useGetModelOutput(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  let treeData;

  if (report === 'financials') {
    treeData = {
      headerName: 'Expense',
      groupColumns: ['expense_group_name', 'expense_name', 'crew_type'],
    };
  } else if (report === 'kpis') {
    treeData = {
      headerName: 'KPIs',
      groupColumns: ['stats_name', 'crew_type'],
    };
  } else if (report === 'average_expense') {
    treeData = {
      headerName: 'Average Expense',
      groupColumns: ['expense_name', 'crew_type'],
    };
  }

  return (
    <DataGridPremiumTable
      apiRef={apiRef}
      treeData={treeData}
      columns={dataTable.columns.map((c) => ({
        name: c.name,
        label: c.display_name,
        is_pinned: c.is_pinned,
        styles: c.styles,
      }))}
      isLoading={
        isFetchingModelOutput || isRefetchingModelOutput || isSubmitting
      }
      data={modelOutput?.[report]}
    />
  );
};

export const ModelTablesTab = ({
  apiRef,
  report,
}: {
  report: Report;
  apiRef: React.MutableRefObject<GridApiPremium>;
}) => {
  const { orgId, projectId } = useGetRouteParams();

  const { data: outputSchema } = useGetProjectOutputSchema(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const dataTables = outputSchema?.sections.find(
    (s) => s.name === report
  )?.data_tables;

  return (
    <>{dataTables?.map((d) => <ModelTable dataTable={d} apiRef={apiRef} />)}</>
  );
};

const ModelOutputTabs = () => {
  const [index, setIndex] = useState(0);
  const [checkVariance, setCheckVariance] = useState(false);
  const [report, setReport] = useState<Report>('financials');

  const { orgId, projectId, modelId, datasetId } = useGetRouteParams();

  const { data: models } = useGetModels(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const baseModel = models?.find((m) => m.is_base);

  const { data: baseModelOutput } = useGetModelOutput(
    {
      modelId: Number(baseModel?.id) ?? NaN,
      orgId,
      projectId,
    },
    {
      enabled: !!baseModel?.id && !!orgId && !!projectId,
    }
  );

  const { data: model } = useGetModelById(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: ifsConfig } = useGetIFSConfig(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: outputSchema } = useGetProjectOutputSchema(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const aircrafts =
    ifsConfig?.aircrafts.map((a) => ({
      label: a.name,
      value: a.name,
    })) ?? [];

  const airports =
    ifsConfig?.aircrafts
      .map((a) =>
        a.airports.map((ai) => ({
          label: ai,
          value: ai,
        }))
      )
      .flat() ?? [];

  const apiRef = useGridApiRef();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const value = event.currentTarget.id as Report;

    setIndex(newValue);
    setReport(value);
  };

  const handleOnToggleVariance = () => {
    setCheckVariance(!checkVariance);
  };

  const dataTable = outputSchema?.sections
    ?.find((s) => s.name === report)
    ?.data_tables.find((d) => d.name === report);

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};
    dataTable?.columns.forEach((colDef) => {
      colStyles[colDef.name] = {
        numFmt: '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: `${dataset?.name}_${model?.name}_${report}`,
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center justify-between">
        <Tabs value={index} onChange={handleChange}>
          {outputSchema?.sections?.map((s) => (
            <Tab id={s.name} key={s.name} label={s.display_name} />
          ))}
        </Tabs>
        <div className="flex items-center gap-1">
          {/* {!model?.is_base && (
            <div className="flex items-center">
              <label className="text-sm font-semibold text-[#333]">
                Variance
              </label>
              <Switch
                checked={checkVariance}
                onChange={handleOnToggleVariance}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
          )} */}
          <Button
            size="small"
            onClick={handleExportToExcel}
            variant="outlined"
            className="space-x-1 font-semibold"
          >
            <ArrowDownToLine />
            <label>Export to Excel</label>
          </Button>
        </div>
      </div>
      <div className="flex w-[40%] gap-4">
        <CustomMenuDropdown
          name={'aircrafts'}
          label={'Aircraft'}
          data={aircrafts}
        />
        <CustomMenuDropdown
          name={'airports'}
          label={'Airport'}
          data={airports}
        />
      </div>
      {report && (
        <div>
          <CustomTabPanel value={report} index={report}>
            <ModelTablesTab apiRef={apiRef} report={report} />
          </CustomTabPanel>
        </div>
      )}
    </div>
  );
};

export default ModelOutputTabs;
