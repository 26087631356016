import React from 'react';

const OutlineLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="73"
    height="44"
    viewBox="0 0 73 44"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.1104 25.4309L35.0169 33.9637L43.7218 41.4615L68.9487 3.3983L25.1104 25.4309ZM63.5077 3.89451L12.4707 14.544L23.4693 24.0174L63.5077 3.89451ZM70.4587 0.401027L10.6611 12.8785C9.61128 13.0976 9.26847 14.4254 10.081 15.1253L22.6444 25.9465V40.3915C22.6444 41.3871 23.7217 42.0096 24.5842 41.5125L34.2491 35.942L43.0235 43.4997C43.6137 44.008 44.516 43.8834 44.9463 43.2341L72.0569 2.32896C72.6953 1.3657 71.8467 0.112669 70.7157 0.347408L70.6854 0.287109L70.4587 0.401027ZM24.6444 39.1694V27.6692L32.6434 34.559L24.6444 39.1694Z"
      fill="white"
    />
    <rect
      width="20.2263"
      height="1.80351"
      rx="0.901756"
      transform="matrix(0.898919 -0.438115 0.504089 0.863652 0.159912 36.0352)"
      fill="#B8341B"
    />
    <rect
      width="11.1385"
      height="1.80351"
      rx="0.901756"
      transform="matrix(0.898919 -0.438115 0.504089 0.863652 7.79956 38.2773)"
      fill="#B8341B"
    />
    <rect
      width="3.67344"
      height="1.80351"
      rx="0.901756"
      transform="matrix(0.898919 -0.438115 0.504089 0.863652 14.5679 40.332)"
      fill="#B8341B"
    />
  </svg>
);

export default OutlineLogo;
