import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import * as z from 'zod';

import { type TypeDataset } from '../../common/dataTypes';
import { useUpdateDataset } from '../../hooks/useDatasetsHook';
import { useGetIFS } from '../../hooks/useIFSHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';
import UploadIFSFilesTabs from '../UploadIFSFilesTabs';
import WarningDialog from '../WarningDialog';

const ProjectCreateDatasetSchema = z.object({
  name: z.string().trim().trim().min(1, 'Dataset name is required'),
  description: z.string().trim().optional(),
});

type ProjectCreateProjectSchemaType = z.infer<
  typeof ProjectCreateDatasetSchema
>;

type ProjectCreateDatasetFormProps = {
  handleOnCancel?: () => void;
  handleOnSubmit?: () => void;
  defaultValues?: TypeDataset;
};

const ProjectCreateDatasetForm = ({
  defaultValues,
  handleOnCancel,
  handleOnSubmit,
}: ProjectCreateDatasetFormProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const [error, setError] = useState(Object);

  const { orgId, projectId } = useGetRouteParams();

  const datasetId = Number(defaultValues?.id);

  const step = Number(searchParams.get('step'));

  const form = useForm<ProjectCreateProjectSchemaType>({
    resolver: zodResolver(ProjectCreateDatasetSchema),
    defaultValues,
  });

  const { handleSubmit, reset, formState } = form;

  const { isValid: isFormValid, isDirty } = formState;

  const { data: ifs } = useGetIFS(
    {
      orgId,
      projectId,
      datasetId,
    },
    {
      enabled: !!datasetId && !!projectId && !!orgId,
    }
  );

  const isDisabled = ifs?.files.find((file) => !file.is_imported);

  const isValid = isFormValid && !isDisabled;

  const { mutate: updateDataset, isPending: isUpdatingProject } =
    useUpdateDataset({
      onSuccess: () => {
        handleCloseWarningDialog();
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  useEffect(() => {
    if (defaultValues) {
      searchParams.set('dataset_id', defaultValues.id.toString());
      setSearchParams(searchParams);

      reset(defaultValues);
    }
  }, [defaultValues]);

  const handleOpenWarningDialog = () => setOpenWarningDialog(true);

  const handleCloseWarningDialog = () => setOpenWarningDialog(false);

  const handleUpdateDataset = async () => {
    if (!orgId || !projectId) {
      return;
    }

    const data = form.getValues();

    updateDataset({
      orgId,
      projectId,
      datasetId,
      data: {
        ...data,
        project: projectId,
      },
    });
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    handleOnSubmit?.();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
        <div className="space-y-4">
          <FormInput
            label="Name"
            fullWidth
            inputProps={{
              maxLength: 100,
            }}
            placeholder="Name of dataset"
            name="name"
          />
          <FormInput
            label="Description"
            fullWidth
            multiline
            rows={4}
            placeholder="(Optional)"
            name="description"
          />
        </div>
        <div className="flex justify-end">
          <LoadingButton
            type="button"
            variant="contained"
            loading={isUpdatingProject}
            disabled={!isDirty || isUpdatingProject}
            onClick={() => {
              if (isDirty) {
                handleOpenWarningDialog();
              }
            }}
          >
            Save
          </LoadingButton>
        </div>
        {typeof error.detail === 'string' && (
          <Alert severity="error">{error.detail}</Alert>
        )}
        <UploadIFSFilesTabs />
        <div className="flex justify-end">
          <div className="flex gap-3">
            <Button color="inherit" onClick={handleOnCancel}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                searchParams.set('step', (step - 1).toString());
                setSearchParams(searchParams);
              }}
              variant="outlined"
              style={{
                color: '#666',
                borderColor: '#CCC',
              }}
            >
              Previous
            </Button>
            <Button variant="outlined" onClick={handleOnCancel}>
              Upload files later
            </Button>
            <Button variant="contained" disabled={!isValid} type="submit">
              Next
            </Button>
          </div>
        </div>
        <WarningDialog
          open={openWarningDialog}
          primaryActionText={'Submit'}
          handlePrimaryAction={() => {
            handleUpdateDataset();
          }}
          secondaryActionText="Cancel"
          handleSecondaryAction={handleCloseWarningDialog}
          content={
            <div className="space-y-2">
              <p>
                Saving this dataset will generate a new Input File Set. As a
                result, any previously imported files will be removed. This
                action cannot be undone, and you will need to re-import any
                files you wish to keep.{' '}
              </p>
              <p>
                Please confirm if you want to proceed with saving the dataset
                and generating a new Input File Set, or cancel to maintain your
                current files and settings.
              </p>
            </div>
          }
        />
      </form>
    </FormProvider>
  );
};

export default ProjectCreateDatasetForm;
