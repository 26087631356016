import { mainApi } from '../utils/api';

export type UpdateUserInput = {
  id: number;
  data: {
    first_name?: string;
    last_name?: string;
    profile_pic?: File;
  };
};

const updateUser = ({ id, data }: UpdateUserInput) =>
  mainApi.patch(`users/${id}/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const getUserById = (id: number) => mainApi.get(`users/${id}/`);

export { updateUser, getUserById };
