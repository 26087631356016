import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import MuiDrawer from '@mui/material/Drawer';
import { type CSSObject, styled, type Theme } from '@mui/material/styles';

import { useGetProjectById } from '../../hooks';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';

import ScenariosDrawerSection from './ScenariosDrawerSection';

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  zIndex: 30,
  overflowX: 'hidden',
  borderRight: 'none',
  backgroundColor: '#01285F',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  zIndex: 30,
  borderRight: 'none',
  width: `calc(${theme.spacing(8)} + 1px)`,
  backgroundColor: '#01285F',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  backgroundColor: '#01285F',

  '& .MuiPaper-root': {
    top: 'auto !important',
  },

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),

  '& .MuiDrawer-docked': {
    backgroundColor: 'none',
  },
}));

type DrawerItemProps = {
  open: boolean;
  route: string;
  label: string;
};

const DrawerItem = ({ label, route, open }: DrawerItemProps) => {
  const location = useLocation();

  const { orgId, projectId } = useGetRouteParams();

  return (
    <Link
      to={`/${orgId}/projects/${projectId}/${route}`}
      className={cn(
        'flex w-full transition gap-2 text-white rounded-md p-3 text-start hover:bg-[#21438C]',
        {
          'bg-[#21438C] font-semibold': location.pathname
            .split('/')
            .includes(route),
        }
      )}
    >
      <p
        className={cn('transition opacity-100', {
          'opacity-0 pointer-events-none hidden': !open,
        })}
      >
        {label}
      </p>
    </Link>
  );
};

export default function CustomDrawer({ open }: { open: boolean }) {
  const { projectId, orgId, inputTableName } = useGetRouteParams();

  const dataTableId = inputTableName;

  const navigate = useNavigate();

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  return (
    <Drawer variant="permanent" open={open}>
      <div
        className={cn('transition flex flex-col opacity-100', {
          '!opacity-0 pointer-events-none h-0': !open,
        })}
      >
        {project && (
          <div className="!whitespace-normal break-words border-b border-[#6A88BB] bg-background p-3 text-start ">
            <button
              className="text-lg font-semibold text-[#FFAD01]"
              onClick={() => {
                navigate(`/${orgId}/projects/${projectId}/models`);
              }}
            >
              {project.name}
            </button>
          </div>
        )}
        {!!dataTableId && <ScenariosDrawerSection />}
        {!!project && !dataTableId && (
          <div className="flex flex-col gap-2 px-3 py-2">
            <DrawerItem open={open} route="models" label="Models" />
            <DrawerItem open={open} route="datasets" label="Datasets" />
            <DrawerItem open={open} route="expenses" label="Custom Expenses" />
            <div className="h-[1px] w-full bg-[#6A88BB]" />
            <DrawerItem open={open} route="members" label="Members" />
            <DrawerItem open={open} route="settings" label="Settings" />
          </div>
        )}
      </div>
    </Drawer>
  );
}
