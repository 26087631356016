import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { AxiosError, type AxiosResponse } from 'axios';
import _ from 'lodash';

import { type CalculateEndPeriod, calculateEndPeriod } from '../api/utils';
import { ErrorContent } from '../components/dataset/IFSTableSection';

import { type DefaultMutationError } from './index';
import { useGetIFS, useImportIFS } from './useIFSHook';

const calculateEndPeriodFn = async (input: CalculateEndPeriod) => {
  const { data }: AxiosResponse<any> = await calculateEndPeriod(input);

  return data;
};

export const useCalculateEndPeriod = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof calculateEndPeriodFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['calculateEndPeriod'],
    mutationFn: (input: CalculateEndPeriod) => calculateEndPeriodFn(input),
    ...opts,
  });

type UseImportIFSProps = {
  inputFileRef: React.MutableRefObject<HTMLInputElement | null>;
  handleError?: () => void;
};

export const useHandleImportIFS = ({
  inputFileRef,
  handleError,
}: UseImportIFSProps) => {
  const [error, setError] = useState<{
    content: React.ReactNode;
  } | null>(null);

  const { projectId, orgId, datasetId } = useGetRouteParams();

  const { mutate: importIFS, isPending } = useImportIFS({
    onSuccess: () => {},
    onError: (error) => {
      if (error instanceof AxiosError) {
        setError({
          content: ErrorContent(error.response.data as string[]),
        });
      }
      handleError?.();
    },
  });

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId,
      orgId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const importIfs = async (e: { target: { value: any; files: any } }) => {
    if (!projectId || !ifs) {
      return;
    }

    const formData = new FormData();

    Array.from(e.target.files as File[]).forEach((file: File) => {
      formData.append('ifs', file);
    });

    importIFS({
      datasetId,
      projectId,
      orgId,
      data: formData,
    });

    e.target.value = '';

    if (inputFileRef?.current?.value) {
      inputFileRef.current = null;
    }
  };

  return {
    importIfs,
    isPending,
    error,
  };
};

export const useGetRouteParams = () => {
  const [searchParams] = useSearchParams();

  const {
    org_id,
    project_id,
    dataset_id,
    expense_id,
    model_id,
    request_id,
    user_id,
    input_table_name,
    scenario_id,
    token,
    invitation_id,
  } = useParams();

  const orgId = Number(org_id || searchParams.get('org_id'));
  const projectId = Number(project_id || searchParams.get('project_id'));
  const datasetId = Number(dataset_id || searchParams.get('dataset_id'));
  const expenseId = Number(expense_id || searchParams.get('expense_id'));
  const modelId = Number(model_id || searchParams.get('model_id'));
  const requestId = Number(request_id || searchParams.get('request_id'));
  const userId = Number(user_id || searchParams.get('user_id'));
  const scenarioId = Number(scenario_id || searchParams.get('scenario_id'));

  const invitationId = Number(
    invitation_id || searchParams.get('invitation_id')
  );

  const inputTableName =
    input_table_name || searchParams.get('input_table_name');

  return {
    datasetId,
    expenseId,
    modelId,
    orgId,
    projectId,
    requestId,
    userId,
    invitationId,
    inputTableName,
    scenarioId,
    token: token || searchParams.get('token'),
  };
};

export const useGetSearchResults = <T>({
  searchValue,
  data,
  filterFields = ['name', 'description'],
}: {
  searchValue: string;
  data: T[] | undefined;
  filterFields?: string[];
}) => {
  const [searchResults, setSearchResults] = useState<T[] | null>(null);

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  useEffect(() => {
    if (!!debouncedSearchTerm?.length && data) {
      const filteredDatasets = data.filter((i) =>
        filterFields.some((field) =>
          _.includes(
            field
              .split('.')
              .reduce((acc: any, curr) => acc[curr], i)
              ?.trim()
              .toLowerCase(),
            debouncedSearchTerm.trim().toLowerCase()
          )
        )
      );

      setSearchResults(filteredDatasets);
      return;
    }

    setSearchResults(null);
  }, [debouncedSearchTerm, data]);

  return searchResults;
};
