import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import * as z from 'zod';

import CreateProjectForm from './CreateProjectForm';

const CreateProjectSchema = z.object({
  name: z.string().trim().trim().min(1, 'Project name is required'),
  description: z.string().trim().optional(),
});

type CreateProjectSchemaType = z.infer<typeof CreateProjectSchema>;

type CreateProjectDialogProps = {
  open: boolean;
  handleClose: () => void;
  trigger?: {
    className?: React.HTMLAttributes<HTMLButtonElement>['className'];
    label: React.ReactNode;
  };
  defaultValues?: CreateProjectSchemaType & {
    id: number;
  };
};

const CreateProjectDialog = ({
  defaultValues,
  trigger,
  open,
  handleClose,
}: CreateProjectDialogProps) => (
  <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
    <DialogTitle>
      {defaultValues ? `Edit ${defaultValues.name}` : 'Create New Project'}
    </DialogTitle>
    <CreateProjectForm
      handleOnCancel={handleClose}
      handleOnSubmit={handleClose}
      defaultValues={defaultValues}
    />
  </Dialog>
);

export default CreateProjectDialog;
