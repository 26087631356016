import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, type SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  styled,
} from '@mui/material';
import { AxiosError } from 'axios';
import { UserPlus, X } from 'lucide-react';
import * as z from 'zod';

import { useGetProjects } from '../../hooks';
import {
  useGetOrgInvitations,
  useInviteOrganizationMember,
} from '../../hooks/useOrganizationHook';
import { useGetProjectMembers } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { type Role } from '../../types';
import cn from '../../utils/cn';
import { transformToLabel } from '../../utils/helpers';
import SuccessDialog from '../SuccessDialog';

const Select = styled(MuiSelect)({
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },

  '& .MuiSelect-select': {},
  '& .MuiSelect-nativeInput': {},
});

const InviteFromSchema = z.object({
  email: z.string().trim().email('Invalid email address'),
  role: z.string({
    message: 'Please select a role',
  }),
  projects: z.record(z.string(), z.boolean()).array(),
});

const EmailSchema = z.string().trim().email('Invalid email address');

type EmailSchemaType = z.infer<typeof EmailSchema>;

const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
});

const InputChips = ({
  emails,
  onRemoveEmail,
}: {
  emails: string[];
  onRemoveEmail: (email: string) => void;
}) => (
  <>
    {emails.map((email) => (
      <div
        key={email}
        className="flex w-fit items-center gap-2 rounded-[4px] border-2 border-[#CCCCCC] p-1"
      >
        <p className="text-xs">{email}</p>
        <X
          className="cursor-pointer"
          width={20}
          height={20}
          color="#CCCCCC"
          onClick={() => {
            onRemoveEmail(email);
          }}
        />
      </div>
    ))}
  </>
);

type InviteFormDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  roles: {
    label: string;
    value: string;
  }[];
  to?: 'organization' | 'project';
  showTrigger?: boolean;
  iProjectId?: number;
  iUser?: {
    id: number;
    email: string;
  };
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
  };
};

const InviteFormDialog = ({
  open,
  handleClose,
  handleOpen,
  roles,
  trigger,
  iProjectId,
  iUser,
  showTrigger = true,
  to = 'organization',
}: InviteFormDialogProps) => {
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [emails, setEmails] = useState<string[]>([]);

  const ref = useRef<HTMLInputElement>(null);

  const form = useForm();

  const {
    handleSubmit,
    formState,
    setError,
    setValue,
    reset,
    register,
    getValues,
    watch,
  } = form;

  const role = watch('role') as Role;

  const { isSubmitting, errors } = formState;

  const { orgId, projectId: routeProjectId } = useGetRouteParams();

  const projectId = iProjectId || routeProjectId;

  const { data: members } = useGetProjectMembers(
    {
      orgId,
      projectId: iProjectId || projectId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { data: invitations } = useGetOrgInvitations(orgId ?? NaN, {
    enabled: !!orgId,
  });

  const projectInvitations = useMemo(
    () =>
      projectId
        ? invitations?.filter((i) =>
            i.projects.map((p) => p.id).includes(projectId)
          )
        : [],
    [invitations, projectId]
  );

  const { data: projects } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { mutate: inviteMember } = useInviteOrganizationMember({
    onSuccess() {
      handleCloseDialog();
      reset();
      handleOpenSuccessDialog();
    },
    onError(error) {
      if (error instanceof AxiosError) {
        setError('email', {
          message: error.response.data['email'][0],
        });
      }
    },
  });

  useEffect(() => {
    setValue('role', 'project_member');
  }, []);

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const handleCloseDialog = () => {
    reset();
    setEmails([]);
    setErrorMessage(null);
    handleClose();
  };

  const handleAddEmail = (email: EmailSchemaType) => {
    const { error } = EmailSchema.safeParse(email);

    if (error) {
      setError('email', {
        message: error.errors[0].message,
      });
      return;
    }

    setEmails((prev) => [...prev, email]);

    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleRemoveEmail = (email: string) => {
    setEmails((prev) => prev.filter((e) => e !== email));
  };

  const onSubmit: SubmitHandler<any> = async () => {
    const formProjects = getValues('projects');

    if (!projectId) {
      if (!emails.length && !ref.current?.value) {
        setErrorMessage('You must enter at least one email');
        return;
      } else if (
        Object.keys(formProjects).filter((p) => formProjects[p]).length === 0
      ) {
        setErrorMessage('You must select at least one project');
        return;
      }
    }

    const projectIds = projectId
      ? [projectId]
      : Object.keys(formProjects)
          .filter((key) => formProjects[key])
          .map(Number);

    const currentEmail = ref.current?.value;

    if (!emails.length && currentEmail) {
      inviteMember({
        id: orgId,
        data: {
          email: currentEmail,
          role,
          projects: projectIds,
        },
      });
      return;
    }

    for (const email of emails) {
      inviteMember({
        id: orgId,
        data: {
          email,
          role,
          projects: projectIds,
        },
      });
    }
  };

  return (
    <>
      {showTrigger && (
        <button
          type="button"
          onClick={handleOpen}
          style={{
            color: 'white',
            backgroundColor: '#B8341B',
            display: 'flex',
            gap: 4,
            borderRadius: 4,
            padding: '6px 12px',
            ...trigger?.style,
          }}
        >
          {trigger?.label ?? (
            <div className="flex w-fit items-center gap-2">
              <UserPlus size={20} />
              <p className="w-fit">Invite users</p>
            </div>
          )}
        </button>
      )}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-2xl font-bold">
            Invite members to{' '}
            {to === 'project' && !iProjectId ? `${to}(s)` : to}
          </h2>
        </DialogTitle>
        <DialogContent>
          <div>
            <FormProvider {...form}>
              <form className="mb-2 space-y-6">
                <div className="flex flex-col gap-2">
                  <div
                    className={cn(
                      'flex items-center justify-between rounded-[4px] border-2 border-[#2196F3] px-2',
                      {
                        'border-primary-red': errors.email,
                      }
                    )}
                  >
                    <div className="flex w-full flex-wrap items-center gap-1 py-2">
                      <InputChips
                        emails={emails}
                        onRemoveEmail={handleRemoveEmail}
                      />
                      <input
                        ref={ref}
                        name="email"
                        placeholder='Type email and press "Enter" or ","'
                        value={iUser?.email}
                        disabled={!!iUser}
                        onKeyDown={(e) => {
                          const keyCode = e.key;

                          const isEmpty =
                            e.currentTarget.value.replace(',', '').length === 0;

                          if (isEmpty && ref.current) {
                            ref.current.value = '';
                            return;
                          }

                          if (keyCode === 'Enter' || keyCode === ',') {
                            handleAddEmail(
                              e.currentTarget.value.replace(',', '')
                            );
                          }
                        }}
                        className="grow outline-none"
                      />
                    </div>
                    <Select
                      {...register('role')}
                      defaultValue="project_member"
                      size="small"
                      className="bottom-0"
                      placeholder="Please select"
                    >
                      {roles.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <span className="capitalize">{option.label}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {errors['email'] && (
                    <p className="px-2 text-sm text-primary-red">
                      {errors['email'].message as string}
                    </p>
                  )}
                </div>
                {!iProjectId && (
                  <div className="px-3">
                    <FormGroup>
                      {projects?.map((project) => (
                        <FormControlLabel
                          style={{
                            width: 'fit-content',
                          }}
                          key={project.id}
                          control={
                            <Checkbox {...register(`projects.${project.id}`)} />
                          }
                          label={project.name}
                        />
                      ))}
                    </FormGroup>
                  </div>
                )}
                {members &&
                  members.map((m) => (
                    <div key={m.id} className="flex justify-between">
                      <div className="flex items-center gap-3">
                        <Avatar src={m.user.profile_pic} alt="member avatar" />
                        <div className="flex flex-col">
                          <label className="font-semibold">
                            {m.user.first_name} {m.user.last_name}
                          </label>
                          <p className="text-xs text-[#666]">{m.user.email}</p>
                        </div>
                      </div>
                      <Select
                        id="demo-simple-select"
                        size="small"
                        defaultValue={m.role}
                        {...register(`members.${m.id}.role`)}
                      >
                        <MenuItem value="project_owner">Project Owner</MenuItem>
                        <MenuItem value="project_member">
                          Project Member
                        </MenuItem>
                      </Select>
                    </div>
                  ))}
                {projectInvitations &&
                  projectInvitations.map((i) => (
                    <div key={i.id} className="flex justify-between">
                      <div className="flex items-center gap-3 opacity-50">
                        <Avatar src="#" alt={i.email} />
                        <div className="flex flex-col">
                          <label className="font-semibold">Unknown</label>
                          <p className="text-xs text-[#666]">{i.email}</p>
                        </div>
                      </div>
                      <Select
                        id="demo-simple-select"
                        size="small"
                        defaultValue={i.role}
                      >
                        <MenuItem value={i.role}>
                          {transformToLabel(i.role)}
                        </MenuItem>
                        <Divider />
                        <MenuItem>Resend invite</MenuItem>
                        <MenuItem>Delete</MenuItem>
                      </Select>
                    </div>
                  ))}
                {errorMessage && (
                  <div className="w-full text-center">
                    <p className="text-primary-red">{errorMessage}</p>
                  </div>
                )}
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    disabled={isSubmitting}
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={handleSubmit((event) => onSubmit(event))}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Send
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
      <SuccessDialog
        content="Invitation sent successfully."
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
      />
    </>
  );
};

export default InviteFormDialog;
