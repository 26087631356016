import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { login, loginByEmailVerify, loginByLink, logout } from '../api';
import {
  changePassword,
  type ChangePasswordInput,
  type LoginByLinkInput,
  type LoginByLinkVerifyInput,
  type LoginInput,
  resetPassword,
  type ResetPasswordInput,
  resetPasswordRequest,
  type ResetPasswordRequestInput,
  resetPasswordVerify,
  type ResetPasswordVerifyInput,
} from '../api/auth';
import { type TypeUser } from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

const logoutFn = async () => {
  const res = await logout();

  return res;
};

const loginByLinkFn = async (input: LoginByLinkInput) => {
  const {
    data,
  }: AxiosResponse<{
    id: string;
    username: string;
  }> = await loginByLink(input);

  return data;
};

export const useLogin = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        user: TypeUser;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['login'],
    mutationFn: async (input: LoginInput) => {
      const {
        data,
      }: AxiosResponse<{
        user: TypeUser;
      }> = await login(input);

      return data;
    },
    onSuccess(data, variables, context) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: data.user.id,
        })
      );

      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });

export const useResetPasswordRequest = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        email: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['resetPasswordRequest'],
    mutationFn: async (input: ResetPasswordRequestInput) => {
      const { data } = await resetPasswordRequest(input);

      return data;
    },
    ...opts,
  });

export const useResetPasswordVerify = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        token: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['resetPasswordVerify'],
    mutationFn: async (input: ResetPasswordVerifyInput) => {
      const { data } = await resetPasswordVerify(input);

      return data;
    },
    ...opts,
  });

export const useResetPassword = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async (input: ResetPasswordInput) => {
      const { data }: AxiosResponse = await resetPassword(input);

      return data;
    },
    ...opts,
  });

export const useChangePassword = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['changePassword'],
    mutationFn: async (input: ChangePasswordInput) => {
      const { data }: AxiosResponse = await changePassword(input);

      return data;
    },
    ...opts,
  });

export const useLoginByLink = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof loginByLinkFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['loginByLinkFn'],
    mutationFn: (input: LoginByLinkInput) => loginByLinkFn(input),
    ...opts,
  });

export const useLoginByVerifyLink = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{ user: TypeUser }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['loginByLinkVerifyFn'],
    mutationFn: async (input: LoginByLinkVerifyInput) => {
      const {
        data,
      }: AxiosResponse<{
        user: TypeUser;
      }> = await loginByEmailVerify(input);

      return data;
    },
    onSuccess(data, variables, context) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: data.user.id,
        })
      );
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });

export const useLogout = (
  opts?: Partial<
    UseMutationOptions<Awaited<ReturnType<typeof logoutFn>>, DefaultQueryError>
  >
) =>
  useMutation({
    mutationKey: ['logoutFn'],
    mutationFn: () => logoutFn(),
    ...opts,
  });

export default {
  useLogin,
  useLoginByLink,
  useLoginByVerifyLink,
  useLogout,
};
