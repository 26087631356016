import React, { useState } from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { type LaborGroup } from '../../types';

import AddForm from './AddForm';

type AddPositionDialogProps = {
  open: boolean;
  defaultValue?: string[];
  group: LaborGroup;
  handlePrimaryAction: (data: string[]) => void;
  handleSecondaryAction: () => void;
};

const AddPositionDialog = ({
  group,
  open,
  defaultValue,
  handlePrimaryAction,
  handleSecondaryAction,
}: AddPositionDialogProps) => {
  const [positions, setPositions] = useState<string[]>([]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <h3 className="text-xl">Add Position</h3>
        <p className="text-sm text-[#666]">
          Add one or more positions for{' '}
          <span className="font-bold capitalize">{group}</span>
        </p>
      </DialogTitle>
      <DialogContent>
        <AddForm
          id="position"
          defaultFormValue={defaultValue}
          data={positions}
          label="Positions"
          inputProps={{
            placeholder: 'Waiter, Cashier, Cook',
          }}
          setData={setPositions}
          handlePrimaryAction={handlePrimaryAction}
          handleSecondaryAction={handleSecondaryAction}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddPositionDialog;
