import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { CircleCheck } from 'lucide-react';

import { useGetRouteParams } from '../../hooks/useUtilsHook';

const CompleteOnboarding = () => {
  const { orgId, projectId } = useGetRouteParams();

  const navigate = useNavigate();

  const handleSecondaryAction = () => {
    navigate(`/${orgId}/projects`);
  };

  const handlePrimaryAction = () => {
    navigate(`/${orgId}/projects/${projectId}/models?add=true`);
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center mt-[5%] h-full">
      <CircleCheck size={90} className="text-white bg-[#12B823] rounded-full" />
      <h1 className="text-3xl font-bold">Success!</h1>
      <div className="text-center">
        <p>Your new project is successfully created.</p>
        <p>You can now create your model.</p>
      </div>
      <div className="flex justify-center gap-2 w-[25%]">
        <Button variant="outlined" fullWidth onClick={handleSecondaryAction}>
          Do it later
        </Button>
        <Button variant="contained" fullWidth onClick={handlePrimaryAction}>
          Create new model
        </Button>
      </div>
    </div>
  );
};

export default CompleteOnboarding;
