import React from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import { BASE_URL_AUTH } from '../common/urls';
import routes from '../routes';

function PasswordChange() {
  const [password, setPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');

  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    React.useState<string>('');

  const [busyChangePassword, setBusyChangePassword] = React.useState(false);
  const [error, setError] = React.useState(Object);

  const [openDialogPasswordChangeDone, setOpenDialogPasswordChangeDone] =
    React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = 'Password Change | ISBE Group';
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'newPasswordConfirmation':
        setNewPasswordConfirmation(value);
        break;
      default:
        break;
    }
  };

  const validate = () => {
    if (newPasswordConfirmation !== newPassword) {
      setError({
        new_password_confirmation: ['Password confirmation does not match.'],
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    const data = {
      current_password: password,
      new_password: newPassword,
    };

    setBusyChangePassword(true);
    return axios
      .post(BASE_URL_AUTH + 'password-change/', data)
      .then(() => {
        handleOpenDialogChangePasswordDone();
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data);
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setBusyChangePassword(false);
      });
  };

  const handleOpenDialogChangePasswordDone = () => {
    setOpenDialogPasswordChangeDone(true);
  };

  const handleCloseDialogChangePasswordDone = (
    event: object,
    reason: string
  ) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenDialogPasswordChangeDone(false);
  };

  return (
    <div>
      <Box sx={{ paddingTop: 2 }}>
        <Container maxWidth="sm">
          <Typography variant="h4">Change Your Password</Typography>

          <form>
            <TextField
              variant="filled"
              fullWidth
              margin="normal"
              label="Current Password"
              name="password"
              type="password"
              onChange={handleChange}
              error={error.current_password ? true : false}
              helperText={
                error.current_password ? error.current_password[0] : ''
              }
            />
            <TextField
              variant="filled"
              fullWidth
              margin="normal"
              label="New Password"
              name="newPassword"
              type="password"
              onChange={handleChange}
              error={error.new_password ? true : false}
              helperText={error.new_password ? error.new_password[0] : ''}
            />
            <TextField
              variant="filled"
              fullWidth
              margin="normal"
              label="Confirm New Password"
              name="newPasswordConfirmation"
              type="password"
              onChange={handleChange}
              error={error.new_password_confirmation ? true : false}
              helperText={
                error.new_password_confirmation
                  ? error.new_password_confirmation[0]
                  : ''
              }
            />

            {typeof error.detail === 'string' && (
              <Alert severity="error">{error.detail}</Alert>
            )}

            <Box sx={{ mt: 8 }}>
              <LoadingButton
                loading={busyChangePassword}
                type="submit"
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                Change Password
              </LoadingButton>
            </Box>
          </form>
        </Container>
      </Box>

      <Dialog
        open={openDialogPasswordChangeDone}
        onClose={handleCloseDialogChangePasswordDone}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Password Changed!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your password has been changed, please login again with your new
            password.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate(routes.login)} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PasswordChange;
