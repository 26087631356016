import React from 'react';

const Warning = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="warning-triangle">
      <path
        id="Vector"
        d="M20.0429 21.5H3.95705C2.41902 21.5 1.45658 19.8364 2.22324 18.5031L10.2662 4.51533C11.0352 3.17792 12.9648 3.17791 13.7338 4.51532L21.7768 18.5031C22.5434 19.8364 21.581 21.5 20.0429 21.5Z"
        fill="#FFC854"
      />
      <path
        id="Vector 2"
        d="M12 9.5V13.5"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        id="Vector_2"
        d="M12 17.51L12.01 17.4989"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default Warning;
