import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

type ErrorsAlertDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const ErrorsAlertDialog = ({ open, handleClose }: ErrorsAlertDialogProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="py-5">
        <h2 className="text-xl font-semibold">Cannot save!</h2>
      </DialogTitle>
      <DialogContent>
        <div className="space-y-6">
          <div>
            <p className="text-[#666]">
              Please complete the following sections before saving:
            </p>
            <ul className="list-disc pl-6">
              {Object.keys(errors).map((key) => (
                <li key={key} className=" font-semibold">
                  {errors[key]?.message as string}
                </li>
              ))}
            </ul>
          </div>
          <p className="text-[#666]">
            Once all required fields are filled out, you will be able to save
            your changes.
          </p>
          <div className="flex-end flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{
                color: 'white',
                backgroundColor: '#B8341B',
                border: 'none',
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorsAlertDialog;
