import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider, MenuItem } from '@mui/material';
import { Checkbox as MuiCheckbox, styled } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';

import MenuDropdown from '../MenuDropdown';

const Checkbox = styled(MuiCheckbox)({
  padding: 0,
});

type CustomMenuDropdownProps = {
  name: string;
  label: string;
  data: {
    label: string;
    value: string | number;
  }[];
};

const CustomMenuDropdown = ({ name, label, data }: CustomMenuDropdownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { register, watch, resetField } = useFormContext();

  const selectedRecords = Object.values(watch(name) ?? {}).filter(
    (v) => v
  ).length;

  const handleClearFilters = () => {
    resetField(name);
  };

  return (
    <MenuDropdown
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      trigger={
        <div className="flex w-full gap-2 px-3 text-[#666666]">
          <p className="font-semibold">{label}</p>
          <div className="rounded-[4px] px-2 bg-[#F5F5F5]">
            {selectedRecords} selected
          </div>
          {open ? <ChevronUp width={15} /> : <ChevronDown width={15} />}
        </div>
      }
      style={{ width: '100%' }}
      triggerClassName="rounded-md border border-[#CCCCCC] pl-3 py-1.5"
    >
      {data.map((d) => {
        const isSelected = watch(`${name}.${d.value}`);

        return (
          <label
            htmlFor={d.value.toString()}
            className="flex transition hover:bg-slate-200 p-2 cursor-pointer w-full h-full gap-2"
          >
            <Checkbox
              checked={isSelected}
              id={d.value.toString()}
              {...register(`${name}.${d.value}`)}
            />
            <p>{d.label}</p>
          </label>
        );
      })}
      <Divider
        style={{
          margin: 0,
        }}
      />
      <MenuItem onClick={handleClearFilters}>
        <p className="px-4 py-1 text-primary-red font-semibold flex items-center ">
          Clear filters
        </p>
      </MenuItem>
    </MenuDropdown>
  );
};

export default CustomMenuDropdown;
