import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import { BASE_URL_AUTH } from '../common/urls';

function AccountActivation() {
  const [newPassword, setNewPassword] = React.useState<string>('');

  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    React.useState<string>('');

  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState(Object);

  React.useEffect(() => {
    document.title = 'Set Password | ISBE Group';
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'newPasswordConfirmation':
        setNewPasswordConfirmation(value);
        break;
      default:
        break;
    }
  };

  const validate = () => {
    if (newPasswordConfirmation !== newPassword) {
      setError({
        new_password_confirmation: ['Password confirmation does not match.'],
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    const data = {
      new_password: newPassword,
    };

    setBusy(true);
    return axios
      .post(BASE_URL_AUTH + 'account-activation/', data)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data);
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <div>
      <Box sx={{ paddingTop: 2 }}>
        <Container maxWidth="sm">
          <Typography variant="h4">Activate Your Account</Typography>

          <form>
            <TextField
              variant="filled"
              fullWidth
              margin="normal"
              label="New Password"
              name="newPassword"
              type="password"
              onChange={handleChange}
              error={error.new_password ? true : false}
              helperText={error.new_password ? error.new_password[0] : ''}
            />
            <TextField
              variant="filled"
              fullWidth
              margin="normal"
              label="Confirm New Password"
              name="newPasswordConfirmation"
              type="password"
              onChange={handleChange}
              error={error.new_password_confirmation ? true : false}
              helperText={
                error.new_password_confirmation
                  ? error.new_password_confirmation[0]
                  : ''
              }
            />

            {typeof error.detail === 'string' && (
              <Alert severity="error">{error.detail}</Alert>
            )}

            <Box sx={{ mt: 8 }}>
              <LoadingButton
                loading={busy}
                type="submit"
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                Confirm
              </LoadingButton>
            </Box>
          </form>
        </Container>
      </Box>
    </div>
  );
}

export default AccountActivation;
