import { type PositionItem } from '../types';

export const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const LaborGroup = [
  {
    label: 'Pilots',
    value: 'pilot',
  },
  {
    label: 'Flight Attendants',
    value: 'flight_attendant',
  },
];

export const granularityLabels = ['Yearly', 'Monthly', 'Weekly'];

export const positionItems = {
  pilot: [
    {
      label: 'Captain',
      value: 'captain',
      default: true,
    },
    {
      label: 'First Officer',
      value: 'firstOfficer',
      default: true,
    },
    {
      label: 'Second Officer',
      value: 'secondOfficer',
      default: true,
    },
    {
      label: 'Instructors',
      value: 'instructors',
    },
    {
      label: 'Examiners',
      value: 'examiners',
    },
    {
      label: 'Trainees',
      value: 'trainees',
    },
    {
      label: 'Management Pilots',
      value: 'managementPilots',
    },
  ] as PositionItem[],
  flight_attendant: [
    {
      label: 'Purser',
      value: 'purser',
      default: true,
    },
    {
      label: 'Cabin Manager',
      value: 'cabinManager',
      default: true,
    },
    {
      label: 'Cabin Crew',
      value: 'cabinCrew',
      default: true,
    },
    {
      label: 'Chef',
      value: 'chef',
    },
    {
      label: 'Instructors',
      value: 'instructors',
    },
    {
      label: 'Trainees',
      value: 'trainees',
    },
  ] as PositionItem[],
};
