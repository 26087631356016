import { type UseFormSetError } from 'react-hook-form';

import axios, { AxiosError } from 'axios';

import { BASE_URL_AUTH, BASE_URL_MAIN } from '../common/urls';
import { type DefaultQueryError } from '../hooks';

const baseAuthURL = BASE_URL_AUTH;
const baseMainURL = BASE_URL_MAIN;

export const createApi = (baseURL: string, useInterceptors: boolean = true) => {
  const api = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axios.defaults.withCredentials = true;

  if (useInterceptors) {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error instanceof AxiosError) {
          const res = error.response;

          if (res) {
            console.error(res.data);

            if (
              res.status === 401 &&
              !/^\/login\/?$/.test(window.location.pathname)
            ) {
              localStorage.removeItem('user');
              const loginPath = '/login';
              window.location.pathname = loginPath;
            }
          } else {
            console.error(JSON.stringify(error, null, 2));
          }
        }

        throw error;
      }
    );
  }

  return api;
};

export const authApi = createApi(baseAuthURL);
export const unprotectedAuthApi = createApi(baseAuthURL, false);
export const unprotectedMainApi = createApi(baseMainURL, false);
export const mainApi = createApi(baseMainURL);

export const handleUnknownError = (setError: UseFormSetError<any>, t: any) => {
  setError('root', {
    message: t(`error.unknown`) as string,
  });
};

export const handleQueryError = (
  error: DefaultQueryError,
  setError: (value: any) => void
) => {
  if (error instanceof AxiosError && error.response) {
    setError(error.response.data);
    return;
  }
  setError(error);
};
