import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { useGetProjects } from '../hooks';
import {
  useGetOrgInvitations,
  useGetOrgMembers,
} from '../hooks/useOrganizationHook';
import { useGetUser } from '../hooks/useUserHook';
import { useGetRouteParams, useGetSearchResults } from '../hooks/useUtilsHook';
import { type Role, type UserStatus } from '../types';
import cn from '../utils/cn';
import { useCurrentMembership, useCurrentOrganization } from '../utils/helpers';

import CustomMenuDropdown from './model/CustomMenuDropdown';
import InviteFormDialog from './organizations/InviteFormDialog';
import ConfirmDeleteUserDialog from './userManagement/ConfirmDeleteUserDialog';
import ResendOrgInvitation from './userManagement/ResendOrgInvitation';
import SearchInput from './SearchInput';

export type DataRow = {
  id: number;
  user: {
    id: number;
    name?: string | null;
    avatarImage: string;
    email: string;
    role: Role;
  };
  lastActive: string | null;
  status: UserStatus;
};

const roles = [
  {
    label: 'Account Admin',
    value: 'organization_admin',
  },
  {
    label: 'Project Owner',
    value: 'project_owner',
  },
  {
    label: 'Project Member',
    value: 'project_member',
  },
];

const columnHelper = createColumnHelper<DataRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('user', {
    cell: (info) => {
      const { email, role, name, avatarImage } = info.getValue();

      return (
        <div className="group flex items-center gap-1 py-2 text-start">
          <div
            className="flex items-center gap-2"
            onClick={() => handleSelect(info.row.original.id)}
          >
            <Avatar alt="User avatar" src={avatarImage} />
            <div className="flex flex-col">
              <div className="flex w-full justify-between gap-3">
                <p
                  className={cn('font-semibold', {
                    'opacity-70': !name,
                  })}
                >
                  {name ?? 'Unknown'}
                </p>
                {role === 'organization_admin' && (
                  <p className="flex items-center rounded-full bg-[#FFE8B9] px-3 py-0 text-xs font-bold uppercase">
                    {role}
                  </p>
                )}
              </div>
              <p className="text-sm text-[#666]">{email}</p>
            </div>
          </div>
        </div>
      );
    },
    header: (info) => {
      const { setValue } = useFormContext();

      const handleOnClick = () => {
        const updatedSelectUsers = info.table.options.data.reduce(
          (acc, curr) => {
            acc[curr.id] = true;
            return acc;
          },
          {} as Record<string, boolean>
        );

        setValue('selectedUsers', updatedSelectUsers);
      };

      return (
        <div className="flex items-center gap-1">
          <p>User</p>
          <ArrowUpward
            style={{
              height: '20px',
              width: '20px',
            }}
          />
        </div>
      );
    },
  }),
  columnHelper.accessor('lastActive', {
    id: 'lastActive',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div className="w-full" onClick={() => handleSelect(id)}>
          {format(info.getValue() as string, 'Y/MM/dd h:mm aaa')}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 w-full text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Last active</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const { data: currentUser } = useGetUser();

      const { orgId } = useGetRouteParams();

      const membership = useCurrentMembership();

      const { data: org_invitations } = useGetOrgInvitations(orgId, {
        enabled:
          !!orgId && membership.organization?.role === 'organization_admin',
      });

      const { data: org_members } = useGetOrgMembers(orgId, {
        enabled:
          !!orgId && membership.organization?.role === 'organization_admin',
      });

      const row = info.row.original.user;

      const user = {
        ...row,
        type:
          status === 'pending'
            ? 'invitation'
            : ('member' as 'invitation' | 'member'),
      };

      const member = org_members?.find((m) => m.user.id === user.id);

      const invitation = org_invitations?.find(
        (i: { email: string }) => i.email === user.email
      );

      if (member) {
        user['id'] = member.id;
      } else if (invitation) {
        user['id'] = invitation.id;
      }

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'active' | 'pending';
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return (
        <div className="flex items-center justify-between">
          {status === 'active' ? (
            <StatusLabel value="active" label="Active" />
          ) : null}
          {status === 'pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {row.id !== currentUser?.id && (
            <div className="flex gap-4">
              {invitation && user.type === 'invitation' && (
                <ResendOrgInvitation
                  invitationId={invitation.id}
                  orgId={invitation.organization}
                />
              )}
              <ConfirmDeleteUserDialog
                user={user}
                trigger={{
                  className: 'text-[#666666]',
                }}
                level={'organization'}
              />
            </div>
          )}
        </div>
      );
    },
    header: () => <div>Status</div>,
  }),
];

const UsersTable = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

  const currentOrg = useCurrentOrganization();

  const { orgId } = useGetRouteParams();

  const { data: org_invitations } = useGetOrgInvitations(orgId, {
    enabled: !!orgId,
  });

  const { data: org_members } = useGetOrgMembers(orgId, {
    enabled: !!orgId,
  });

  const { data: projects } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const form = useForm();
  const { watch } = form;

  const selectedUsers = watch('selectedUsers');

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: watch('search'),
    data,
    filterFields: ['user.name', 'user.email'],
  });

  useEffect(() => {
    const dataRows: DataRow[] = [];

    if (org_invitations && org_invitations?.length > 0) {
      org_invitations.forEach((i) =>
        dataRows.push({
          id: i.id,
          user: {
            id: i.id,
            name: null,
            avatarImage: '',
            email: i.email,
            role: i.role,
          },
          lastActive: null,
          status: 'pending',
        })
      );
    }

    if (org_members && org_members?.length > 0) {
      org_members.forEach((m) =>
        dataRows.push({
          id: m.id,
          lastActive: m.date_joined,
          user: {
            id: m.user.id,
            name: `${m.user.first_name} ${m.user.last_name}`,
            avatarImage: m.user.profile_pic,
            role: m.role,
            email: m.user.email,
          },
          status: 'active',
        })
      );
    }

    setData(dataRows);
  }, [org_invitations, org_members]);

  const isIndeterminate = selectedUsers
    ? Object.keys(selectedUsers).some((key) => selectedUsers[key])
    : false;

  const navigate = useNavigate();

  const handleSelect = (id: number) => {
    const member = org_members?.find((m) => m.id === id);
    const invitation = org_invitations?.find((i) => i.id === id);

    const type = member ? 'member' : invitation ? 'invitation' : null;

    if (type) {
      searchParams.set('type', type);
      setSearchParams(searchParams);
    }

    navigate({
      pathname: `${id}`,
      search: searchParams.toString(),
    });
  };

  const table = useReactTable({
    data: searchResults ?? data,
    columns: columns({ handleSelect }),
    getCoreRowModel: getCoreRowModel(),
  });

  const isOrgAdmin = currentOrg?.role === 'organization_admin';

  const handleOpenInviteFormDialog = () => {
    setOpenInviteFormDialog(true);
  };

  const handleCloseInviteFormDialog = () => {
    setOpenInviteFormDialog(false);
  };

  const projectOptions =
    projects?.map((p) => ({
      value: p.id,
      label: p.name,
    })) ?? [];

  return (
    <div className="w-full">
      <FormProvider {...form}>
        <form className="flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-wrap grow gap-2">
              <div className="w-full max-w-[350px]">
                <SearchInput />
              </div>
              <CustomMenuDropdown
                name="projects"
                label="Project"
                data={projectOptions}
              />
              <CustomMenuDropdown name="roles" label="Role" data={roles} />
              <CustomMenuDropdown
                name="status"
                label="Status"
                data={
                  [
                    {
                      value: 'active',
                      label: 'Active',
                    },
                    {
                      value: 'pending',
                      label: 'Pending',
                    },
                  ] as {
                    value: UserStatus;
                    label: string;
                  }[]
                }
              />
            </div>
            <div className="">
              {isOrgAdmin && (
                <InviteFormDialog
                  roles={roles}
                  open={openInviteFormDialog}
                  handleClose={handleCloseInviteFormDialog}
                  handleOpen={handleOpenInviteFormDialog}
                />
              )}
            </div>
          </div>
          <table className="w-full">
            {!isIndeterminate && (
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="!rounded-md bg-[#F8F8F8]">
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        className={cn(
                          'first:border-l last:border-r border-t border-b px-6 py-2 text-start text-sm text-[#4D4D4D]',
                          {
                            'w-[45%]': index === 0,
                          }
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="cursor-pointer px-6 py-3">
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className={cn(
                        'first:border-l last:border-r border-b border-[#E4E7EC] px-6 text-[#333]',
                        {
                          'w-[45%]': index === 0,
                        }
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!table.getRowModel().rows.length && (
            <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
              <p className="text-lg text-[#999]">
                No users available. Please invite a user.
              </p>
            </div>
          )}
        </form>
      </FormProvider>
      <div className="h-4" />
    </div>
  );
};

export default UsersTable;
