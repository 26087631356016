import React, { type CSSProperties, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@mui/icons-material';
import { MenuItem, Switch } from '@mui/material';
import { EllipsisVertical, Pencil } from 'lucide-react';

import { type TypeScenarioVector } from '../../common/dataTypes';
import { useUpdateVector } from '../../hooks';
import { useGetScenarioVectors } from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { useDeleteVector } from '../../hooks/useVectorHook';
import cn from '../../utils/cn';
import MenuDropdown from '../MenuDropdown';
import SimpleConfirmDeleteDialog from '../SimpleConfirmDeleteDialog';
import CreateVectorFormDialog from '../vector/CreateVectorFormDialog';

const getVectorDirection = (vector: TypeScenarioVector) => {
  if (
    (vector.delta && vector.delta > 0) ||
    (vector.delta_percentage && vector.delta_percentage > 0)
  ) {
    return 'increase';
  }

  if (
    (vector.delta && vector.delta < 0) ||
    (vector.delta_percentage && vector.delta_percentage < 0)
  ) {
    return 'decrease';
  }

  if (vector.new_value) {
    return 'set';
  }
};

const DraggableVector = ({
  vector,
  handleOnActivate,
}: {
  vector: TypeScenarioVector;
  handleOnActivate?: () => void;
}) => {
  const [checked, setChecked] = useState(vector.is_active);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [openDialogCreateVector, setOpenDialogCreateVector] = useState(false);

  const open = Boolean(anchorEl);

  const {
    transform,
    transition,
    setNodeRef,
    isDragging,
    attributes,
    listeners,
  } = useSortable({
    id: vector.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  const [searchParams] = useSearchParams();
  const scenarioId = Number(searchParams.get('scenario_id')) ?? NaN;

  const { projectId, modelId, orgId } = useGetRouteParams();

  const { mutate: updateVector } = useUpdateVector({
    onSuccess() {
      handleOnActivate?.();
    },
  });

  const { mutate: deleteVector } = useDeleteVector();

  const direction = getVectorDirection(vector);

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleOpenDialogCreateVector = () => setOpenDialogCreateVector(true);

  const handleToggleCheck = () => {
    setChecked(!checked);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDelete = () => {
    if (orgId && vector && projectId) {
      deleteVector({
        orgId,
        projectId,
        modelId,
        scenarioId,
        vectorId: vector.id,
      });
    }
  };

  useEffect(() => {
    if (!orgId) {
      return;
    }

    if (checked) {
      updateVector({
        orgId,
        vectorId: vector.id,
        projectId,
        scenarioId,
        modelId,
        data: {
          ...vector,
          scenario: scenarioId,
          is_active: true,
        },
      });
    } else {
      updateVector({
        orgId,
        vectorId: vector.id,
        projectId,
        scenarioId,
        modelId,
        data: {
          ...vector,
          scenario: scenarioId,
          is_active: false,
        },
      });
    }
  }, [checked]);

  return (
    <div
      ref={setNodeRef}
      className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] p-5"
      style={style}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <button type="button" {...attributes} {...listeners}>
            <DragHandle
              style={{
                color: '#666',
              }}
            />
          </button>
          <p className="font-bold">{vector.name}</p>
        </div>
        <div className="flex items-center gap-3">
          <Switch
            checked={checked}
            onChange={handleToggleCheck}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <button
            type="button"
            onClick={() => {
              handleCloseDropdown();
              handleOpenDialogCreateVector();
            }}
          >
            <Pencil size={20} color="#666666" />
          </button>
          <MenuDropdown
            trigger={
              <button className="w-fit" type="button">
                <EllipsisVertical color="#666666" width={20} height={20} />
              </button>
            }
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            style={{
              marginTop: '5px',
              marginLeft: '-5px',
            }}
            open={open}
          >
            <MenuItem>View</MenuItem>
            <MenuItem>Archive</MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenConfirmDelete(true);
              }}
            >
              Delete
            </MenuItem>
          </MenuDropdown>
        </div>
      </div>
      <div className="flex flex-col">
        <p>{vector.description}</p>
      </div>
      <div className="flex grow items-center gap-3 bg-[#FFF] !text-sm">
        <span
          className={cn('font-bold transition uppercase text-[#333]', {
            'text-[#B3B3B3]': !checked,
          })}
        >
          {direction}
        </span>
        {/* <span
          className={cn(
            'rounded-full bg-[#FFF9EB] transition px-3 py-1 font-semibold text-[#C08100]',
            {
              'text-[#999] bg-[#F5F5F5]': !checked,
            }
          )}
        >
          {vector.field_name}
        </span> */}
        {direction === 'set' && (
          <p
            className={cn('space-x-3 transition', {
              'text-[#B3B3B3]': !checked,
            })}
          >
            <span className="font-semibold uppercase text-[#333]">to</span>
            <span className="rounded-full bg-[#EFFCFF] px-3 py-1 font-semibold">
              {vector.new_value}%
            </span>
          </p>
        )}
        {(direction === 'increase' || direction === 'decrease') && (
          <p
            className={cn('space-x-3 transition', {
              '!text-[#B3B3B3]': !checked,
            })}
          >
            <span className="font-bold uppercase">by</span>
            <span className="rounded-full bg-[#EFFCFF] px-3 py-1 font-semibold">
              {vector.delta
                ? vector.delta?.toString().replace('-', '')
                : vector.delta_percentage?.toString().replace('-', '') + '%'}
            </span>
          </p>
        )}
        <p
          className={cn('space-x-3 transition', {
            'text-[#B3B3B3]': !checked,
          })}
        >
          <span className="font-bold uppercase">on</span>
          <span>aircraft_type</span>
          <span>=</span>
          <span
            className={cn(
              'rounded-full bg-[#E8F2FF] transition px-3 py-1 font-semibold capitalize text-[#01285F]',
              {
                'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
              }
            )}
          >
            {
              vector?.criterias?.find((c) => c.field_name === 'aircraft_type')
                ?.value
            }
          </span>
          <span className="font-bold uppercase">AND</span>
          <span>timeline in</span>
          <span
            className={cn(
              'rounded-full bg-[#FFF4F2] px-3 py-1 transition font-semibold capitalize text-primary-red',
              {
                'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
              }
            )}
          >
            {vector?.start_period}
          </span>
          <span>to</span>
          <span
            className={cn(
              'rounded-full bg-[#FFF4F2] px-3 transition py-1 font-semibold capitalize text-primary-red',
              {
                'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
              }
            )}
          >
            {vector?.end_period}
          </span>
        </p>
      </div>
      <SimpleConfirmDeleteDialog
        open={openConfirmDelete}
        handleOnClose={handleCloseConfirmDelete}
        handleOnSubmit={handleDelete}
      />
      <CreateVectorFormDialog
        open={openDialogCreateVector}
        handleCloseDialog={() => {
          setOpenDialogCreateVector(false);
        }}
        handleOpenDialog={() => {
          setOpenDialogCreateVector(true);
        }}
        handleSubmitDialog={handleOnActivate}
        title="Edit Vector"
        defaultValues={{
          id: vector.id,
          name: vector.name,
          description: vector.description,
          aircraft_type:
            vector.criterias.find((f) => f.field_name === 'aircraft_type')
              ?.value ?? '',
          crew_type:
            vector.criterias.find((f) => f.field_name === 'crew_type')?.value ??
            '',
          start_period: vector.start_period,
          end_period: vector.end_period,
          type: vector.delta ? 'delta' : 'percentage',
          action:
            (vector.delta
              ? (vector.delta as number)
              : (vector.delta_percentage as number)) > 0
              ? 'increase'
              : 'decrease',
          amount: vector.delta
            ? Math.abs(vector.delta)
            : Math.abs(vector.delta_percentage as number),
        }}
        showTrigger={false}
      />
    </div>
  );
};

type VectorSectionProps = {
  handleOnActivate?: () => void;
};

const VectorsSection = ({ handleOnActivate }: VectorSectionProps) => {
  const { projectId, orgId, modelId, scenarioId } = useGetRouteParams();
  const [data, setData] = useState<TypeScenarioVector[]>([]);

  const { data: vectors } = useGetScenarioVectors(
    {
      scenarioId,
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!scenarioId && !!orgId && !!projectId && !!modelId,
    }
  );

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => data.map(({ id }) => id),
    [data]
  );

  useEffect(() => {
    if (!vectors) {
      return;
    }
    setData(vectors);
  }, [vectors]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);

        const newData = arrayMove(data, oldIndex, newIndex).map(
          (item, index) => ({
            ...item,
            hierarchy: index + 1,
          })
        );

        return newData;
      });
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="flex flex-col gap-3 bg-[#FFF]">
        {data && (
          <SortableContext items={data} strategy={verticalListSortingStrategy}>
            {data.map((vector) => (
              <DraggableVector
                key={vector.id}
                vector={vector}
                handleOnActivate={handleOnActivate}
              />
            ))}
          </SortableContext>
        )}
      </div>
    </DndContext>
  );
};

export default VectorsSection;
