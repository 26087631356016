'use client';

import { type ReactNode } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60 * 1000,
    },
  },
});

type Props = {
  children: ReactNode;
};

const ClientProvider = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}> {children} </QueryClientProvider>
);

export default ClientProvider;
