import { mainApi } from '../utils/api';

export type CreateScenarioInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    name: string;
    description?: string;
    data_table_name: string;
    model: number;
  };
};

export type UpdateScenariosInput = {
  orgId: number;
  projectId: number;
  id: number;
  data: {
    scenarios: number[];
  };
};

export type UpdateScenarioByIdInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  id: number;
  data: {
    name: string;
    description?: string;
    data_table_name: string;
    model: number;
  };
};

export type GetScenarioDataInput = {
  scenarioId: number;
  orgId: number;
  modelId: number;
  projectId: number;
  dataName?: string;
};

export type DeleteScenarioInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  scenarioId: number;
};

export type CreateScenarioVectorInput = {
  orgId: number;
  projectId: number;
  scenarioId: number;
  data: {
    name: string;
    description?: string;
    delta: number;
    delta_percentage: number;
    new_value: number;
    criterias: string;
    is_active?: true;
    scenario: number;
  };
};

export type GetScenariosByModelIdInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetScenariosByDatasetIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetScenariosByDataTableIdInput = {
  orgId: number;
  projectId: number;
  dataTableId: number;
};

export type GetScenarioVectorsInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  scenarioId: number;
};

export type GetScenarioByIdInput = {
  orgId: number;
  modelId: number;
  projectId: number;
  scenarioId: number;
};

export type CloneScenarioInput = {
  orgId: number;
  modelId: number;
  projectId: number;
  scenarioId: number;
};

export type GetScenariosInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetScenariosByDataTableNameInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  dataTableName: string;
};

export type ActivateScenarioInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  scenarioId: number;
};

export type GetActiveScenarioInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetActiveScenarioByDataTableNameInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  dataTableName: string;
};

export type GetBaseScenarioInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  dataTableName: string;
};

export type GetBaseScenarioDataInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  dataTableName: string;
};

export type ActivateBaseScenarioInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  dataTableName: string;
};

const getScenarios = ({ orgId, projectId, modelId }: GetScenariosInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/`
  );

const getScenariosByDataTableName = ({
  orgId,
  projectId,
  modelId,
  dataTableName,
}: GetScenariosByDataTableNameInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/?data_table_name=${dataTableName}`
  );

const getScenarioById = ({
  orgId,
  projectId,
  modelId,
  scenarioId,
}: GetScenarioByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/`
  );

const createScenario = ({
  orgId,
  projectId,
  modelId,
  data,
}: CreateScenarioInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/`,
    data
  );

const updateScenarioById = ({
  orgId,
  projectId,
  modelId,
  id,
  data,
}: UpdateScenarioByIdInput) =>
  mainApi.put(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${id}/`,
    data
  );

const cloneScenario = async ({
  orgId,
  projectId,
  modelId,
  scenarioId,
}: CloneScenarioInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/clone/`
  );

const activateScenario = ({
  orgId,
  projectId,
  modelId,
  scenarioId,
}: ActivateScenarioInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/make_active/`
  );

const getActiveScenarios = ({
  orgId,
  projectId,
  modelId,
}: GetActiveScenarioInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/active_scenarios/`
  );

const getActiveScenarioByDataTableName = ({
  orgId,
  projectId,
  modelId,
  dataTableName,
}: GetActiveScenarioByDataTableNameInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/active_scenarios/?data_table_name=${dataTableName}`
  );

const deleteScenarioById = ({
  orgId,
  projectId,
  modelId,
  scenarioId,
}: DeleteScenarioInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/`
  );

const getScenarioVectors = ({
  scenarioId,
  orgId,
  projectId,
  modelId,
}: GetScenarioVectorsInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/vectors/`
  );

const getScenarioData = ({
  scenarioId,
  orgId,
  modelId,
  projectId,
}: GetScenarioDataInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/${scenarioId}/data/`
  );

const getBaseScenario = ({
  orgId,
  projectId,
  modelId,
  dataTableName,
}: GetBaseScenarioInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/base/?data_table_name=${dataTableName}`
  );

const getBaseScenarioData = ({
  orgId,
  projectId,
  modelId,
  dataTableName,
}: GetBaseScenarioDataInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/base/data/?data_table_name=${dataTableName}`
  );

const activateBaseScenario = ({
  orgId,
  projectId,
  modelId,
  dataTableName,
}: ActivateBaseScenarioInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/base/make_active/?data_table_name=${dataTableName}`
  );

export {
  activateScenario,
  activateBaseScenario,
  cloneScenario,
  createScenario,
  deleteScenarioById,
  getActiveScenarioByDataTableName,
  getActiveScenarios,
  getScenarioById,
  getBaseScenario,
  getBaseScenarioData,
  getScenarioData,
  getScenarios,
  getScenariosByDataTableName,
  getScenarioVectors,
  updateScenarioById,
};
