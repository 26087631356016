import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { type TypeProject } from '../../common/dataTypes';
import { useGetDatasetById, useGetProjectById } from '../../hooks';
import { useGetIFSConfig } from '../../hooks/useDatasetsHook';
import { useCompleteOnboarding } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { generateMonthList, generateWeekList } from '../../utils/helpers';
import ReviewIFSFilesTabs from '../ReviewIFSFilesTabs';

import { handleGoBackPreviousStep } from './ProjectCreateView';
import ProjectReview from './ProjectReview';

type ProjectReviewConfirmProps = {
  handleOnSubmit?: (data: TypeProject) => void;
};

const ProjectReviewConfirm = ({
  handleOnSubmit,
}: ProjectReviewConfirmProps) => {
  const [searchParams] = useSearchParams();

  const monthList = useMemo(() => generateMonthList(), []);
  const weekList = useMemo(() => generateWeekList(), []);

  const { orgId, datasetId, projectId } = useGetRouteParams();

  const step = searchParams.get('step');

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: ifsConfig } = useGetIFSConfig(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { mutateAsync: completeOnboarding } = useCompleteOnboarding();

  const aircrafts = useMemo(() => ifsConfig?.aircrafts, [ifsConfig?.aircrafts]);

  const startMonthOrWeek = ifsConfig?.start_period.split('-')[0];
  const endMonthOrWeek = ifsConfig?.end_period.split('-')[0];

  const startMonthOrWeekLabel =
    ifsConfig?.granularity === 'monthly'
      ? monthList.find((m) => m.value === startMonthOrWeek)?.label
      : weekList.find((w) => w.value === startMonthOrWeek)?.label;

  const endMonthOrWeekLabel =
    ifsConfig?.granularity === 'monthly'
      ? monthList.find((m) => m.value === endMonthOrWeek)?.label
      : weekList.find((w) => w.value === endMonthOrWeek)?.label;

  const onSubmit = async () => {
    if (!projectId || !orgId) {
      return;
    }

    const res = await completeOnboarding({
      projectId,
      orgId,
    });

    handleOnSubmit?.(res);
  };

  return (
    <div className="bg-white space-y-6">
      <ProjectReview />
      <div className="flex flex-col gap-3">
        <h1 className="text-xl font-bold">Dataset</h1>
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <div className="flex">
            <p className="w-[20%]">Dataset Name:</p>
            <p className="w-full font-bold">{dataset?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[20%]">Description:</p>
            <p className="w-full font-bold">{dataset?.description}</p>
          </div>
        </div>
      </div>
      <div>{dataset && <ReviewIFSFilesTabs dataset={dataset} />}</div>
      <div className="flex justify-end">
        <div className="flex gap-2">
          <Button
            variant="outlined"
            onClick={() => {
              if (step) {
                handleGoBackPreviousStep(step);
              }
            }}
            style={{
              color: '#666',
              borderColor: '#CCC',
              fontWeight: 600,
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            style={{
              fontWeight: 600,
            }}
            onClick={onSubmit}
          >
            Finalize project
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectReviewConfirm;
