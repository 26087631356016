import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { ChevronRight } from 'lucide-react';

import {
  useGetDatasetById,
  useGetModelById,
  useGetProjectById,
} from '../hooks';
import { useGetDataTableByName } from '../hooks/useDataTablesHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';
import routes from '../routes';
import cn from '../utils/cn';
import { useCurrentOrganization, useCurrentProject } from '../utils/helpers';

import CustomDrawer from './drawer/CustomDrawer';

const TriggerButton = ({
  open,
  handleDrawerClose,
  handleDrawerOpen,
}: {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}) => (
  <button
    onClick={open ? handleDrawerClose : handleDrawerOpen}
    className={cn(
      'absolute shadow-md -left-2 z-50 bg-white w-5 h-5 flex items-center justify-center overflow-hidden rounded-full border border-[#E2E8F0]  p-1 transition'
    )}
  >
    {open ? (
      <ArrowBackIosNew
        style={{
          height: '16px',
          width: '16px',
        }}
      />
    ) : (
      <ArrowForwardIos
        style={{
          height: '16px',
          width: '16px',
        }}
      />
    )}
  </button>
);

const BreadCrumbs = ({
  open,
  handleOpenDrawer,
  handleCloseDrawer,
}: {
  open: boolean;
  handleOpenDrawer: () => void;
  handleCloseDrawer: () => void;
}) => {
  const navigate = useNavigate();

  const { projectId, orgId, modelId, datasetId, inputTableName } =
    useGetRouteParams();

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { data: model } = useGetModelById(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: inputDataTable } = useGetDataTableByName(
    {
      orgId,
      projectId,
      dataTableName: inputTableName ?? '',
      modelId,
    },
    {
      enabled: !!inputTableName && !!orgId && !!projectId && !!modelId,
    }
  );

  return (
    <div className="bg-white sticky top-[60px] z-50 flex items-center">
      <TriggerButton
        open={open}
        handleDrawerClose={handleCloseDrawer}
        handleDrawerOpen={handleOpenDrawer}
      />
      <div className="flex w-full px-12 py-4">
        {project && (
          <div className="flex items-center gap-2 text-[#666666]">
            <Link
              to={`/${orgId}/projects`}
              className="hover:text-black transition"
            >
              Projects
            </Link>
            <ChevronRight size={16} color="#666666" />
            <Link
              to={`/${orgId}/projects/${project.id}`}
              className="hover:text-black transition"
            >
              {project.name}
            </Link>
          </div>
        )}
        {model && (
          <>
            <div className="flex items-center gap-2 pl-2 text-[#666666]">
              <ChevronRight size={16} color="#666666" />
              <p
                onClick={() => {
                  navigate(`/${orgId}/projects/${projectId}/models`);
                }}
                className="hover:text-black cursor-pointer transition"
              >
                Models
              </p>
            </div>
            <div className="flex items-center gap-2 pl-2 text-[#666666]">
              <ChevronRight size={16} color="#666666" />
              <p
                onClick={() => {
                  navigate(
                    `/${orgId}/projects/${projectId}/models/${model.id}`
                  );
                }}
                className="hover:text-black cursor-pointer transition"
              >
                {model.name}
              </p>
            </div>
          </>
        )}
        {dataset && (
          <>
            <div className="flex items-center gap-2 pl-2 text-[#666666]">
              <ChevronRight size={16} color="#666666" />
              <p
                onClick={() => {
                  navigate(`/${orgId}/projects/${projectId}/datasets`);
                }}
                className="hover:text-black cursor-pointer transition"
              >
                Datasets
              </p>
            </div>
            <div className="flex items-center gap-2 pl-2 text-[#666666]">
              <ChevronRight size={16} color="#666666" />
              <p
                onClick={() => {
                  navigate(
                    `/${orgId}/projects/${projectId}/datasets/${dataset.id}`
                  );
                }}
                className="hover:text-black cursor-pointer transition"
              >
                {dataset.name}
              </p>
            </div>
          </>
        )}

        {inputDataTable && (
          <>
            <div className="flex items-center gap-2 pl-2 text-[#666666]">
              <ChevronRight size={16} color="#666666" />
              <p
                onClick={() => {
                  navigate(
                    `/${orgId}/projects/${projectId}/models/${modelId}?tab=scenario`
                  );
                }}
                className="hover:text-black cursor-pointer transition"
              >
                Scenario
              </p>
            </div>
            <div className="flex items-center gap-2 pl-2 text-[#666666]">
              <ChevronRight size={16} color="#666666" />
              <p
                onClick={() => {
                  navigate(
                    `/${orgId}/projects/${projectId}/models/${modelId}/input-table/${inputDataTable.name}/`
                  );
                }}
                className="hover:text-black cursor-pointer transition"
              >
                {inputDataTable.display_name}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const AuthorizedRoute = () => {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  const projectId = useGetRouteParams();

  const currentOrg = useCurrentOrganization();
  const currentProject = useCurrentProject();

  if (
    currentOrg &&
    currentOrg.role === 'organization_admin' &&
    currentProject &&
    !currentProject.role
  ) {
    return (
      <Navigate to={routes.projects.index} state={{ from: location }} replace />
    );
  }

  if (currentProject && currentProject.onboarding_status === 'configuring') {
    return (
      <Navigate
        to={`/${currentOrg?.id}/projects/create/?step=2&project_id=${currentProject.id}`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    currentProject &&
    currentProject.onboarding_status === 'uploading_data'
  ) {
    return (
      <Navigate
        to={`/${currentOrg?.id}/projects/create/?step=3&project_id=${currentProject.id}`}
        state={{ from: location }}
        replace
      />
    );
  } else if (
    currentProject &&
    currentProject.onboarding_status === 'reviewing'
  ) {
    {
      return (
        <Navigate
          to={`/${currentOrg?.id}/projects/create/?step=4&project_id=${currentProject.id}`}
          state={{ from: location }}
          replace
        />
      );
    }
  }

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <div className="w-full">
      <div className="flex w-full">
        <div className="relative flex">
          {projectId && <CustomDrawer open={open} />}
        </div>
        <div className="w-full">
          <BreadCrumbs
            open={open}
            handleOpenDrawer={handleOpenDrawer}
            handleCloseDrawer={handleCloseDrawer}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthorizedRoute;
