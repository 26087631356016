import React, { useState } from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import AddForm from '../AddForm';

type AddAirportDialogProps = {
  open: boolean;
  handlePrimaryAction: (data: string[]) => void;
  handleSecondaryAction: () => void;
};

const AddAirportDialog = ({
  open,
  handlePrimaryAction,
  handleSecondaryAction,
}: AddAirportDialogProps) => {
  const [airports, setAirports] = useState<string[]>([]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <h3 className="text-xl">Add Airports</h3>
      </DialogTitle>
      <DialogContent>
        <AddForm
          id="airport"
          label="Airports"
          data={airports.map((airport) => airport)}
          setData={setAirports}
          handlePrimaryAction={handlePrimaryAction}
          handleSecondaryAction={handleSecondaryAction}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddAirportDialog;
