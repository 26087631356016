import React, {
  type CSSProperties,
  type FC,
  type HTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { type UseFormSetValue } from 'react-hook-form';

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  type DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add, DragHandle } from '@mui/icons-material';
import {
  Chip as PrimitiveChip,
  type GridProps,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import { CircleX } from 'lucide-react';

import { type LaborGroup, type Position, type PositionItem } from '../../types';
import { positionItems as PositionItems } from '../../utils/constants';
import { transformToLabel, transformToValue } from '../../utils/helpers';

import AddPositionDialog from './AddPositionDialog';
import AlertDialog from './AlertDialog';

const Chip = styled(PrimitiveChip)({
  cursor: 'pointer',
  height: '100%',

  '& .MuiChip-root': {
    backgroundColor: 'blue !important',
  },
});

type PositionsSectionProps = {
  defaultValue?: string[];
  group: LaborGroup;
  setFormValue: UseFormSetValue<any>;
};

const Grid: FC<GridProps> = ({ children, columns }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridGap: 10,
    }}
  >
    {children}
  </div>
);

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  isSelected?: boolean;
  position: PositionItem;
  handleSelectPosition: (position: Position) => void;
  handleRemovePosition: (position: string) => void;
};

const SortableItem: FC<ItemProps> = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const {
    id,
    isSelected,
    position,
    handleRemovePosition,
    handleSelectPosition,
  } = props;

  const style: CSSProperties = {
    opacity: isDragging ? '0.5' : '1',
    // width: '100%',
    borderRadius: '100px',
    cursor: isDragging ? 'grabbing' : 'grab',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Chip
        key={position.value}
        label={
          <div className="flex items-center gap-[6px]">
            <DragHandle {...attributes} {...listeners} />
            <p
              className="h-full py-1"
              onMouseDown={() =>
                handleSelectPosition(position.value as Position)
              }
            >
              {transformToLabel(position.label)}
            </p>
            {position.removable && (
              <CircleX
                onClick={() => {
                  handleRemovePosition(position.value);
                }}
                className="cursor-pointer"
                width={20}
                height={20}
              />
            )}
          </div>
        }
        style={{
          fontSize: '16px',
          backgroundColor: isSelected ? '#01285F' : '#F8F8F8',
          color: isSelected ? '#FFF' : '#666',
        }}
      />
    </div>
  );
};

const PositionsSection = ({
  group,
  setFormValue,
  defaultValue,
}: PositionsSectionProps) => {
  const [items, setItems] = useState<string[]>([]);
  const [openDialogAddPosition, setOpenDialogAddPosition] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);

  const [alertDialogContent, setAlertDialogContent] =
    useState<React.ReactNode>();

  const [positions, setPositions] = useState<Record<LaborGroup, Position[]>>({
    pilot: [],
    flight_attendant: [],
  });

  const [positionItems, setPositionItems] = useState(PositionItems);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const transformDefaultValue = defaultValue.map((d) => transformToValue(d));

    const tempPositionItems = Object.assign({}, positionItems);

    const defaultOptions = tempPositionItems[group].map((pos) => pos.value);

    const restOfOptions = tempPositionItems[group]
      .map((t) => t.value)
      .filter((v) => !transformDefaultValue.includes(v));

    tempPositionItems[group] =
      [...transformDefaultValue, ...restOfOptions].map((d: string) => ({
        label: transformToLabel(d).trim(),
        value: d,
        removable: !defaultOptions.includes(d),
        default: defaultOptions.includes(d),
      })) ?? tempPositionItems[group];

    setPositionItems(tempPositionItems);

    const newPositions = transformDefaultValue.map((pos) => pos) ?? [];

    setPositions({
      ...positions,
      [group]: newPositions,
    });
  }, [defaultValue]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const values = defaultValue.map((d) => transformToValue(d));

    setItems([
      ...values,
      ...positionItems[group]
        .filter((pos) => !values.includes(pos.value))
        .map((pos) => pos.value),
    ]);
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      return;
    }

    const result = positionItems[group]
      .filter((position) => position.default)
      .map((position) => position.value as Position | string);

    setItems([
      ...result,
      ...positionItems[group]
        .filter((pos) => !result.includes(pos.value))
        .map((pos) => pos.value),
    ]);

    setPositions({
      ...positions,
      [group]: result,
    });
    setFormValue(
      'positions',
      positionItems[group]
        .filter((position) => result.includes(position.value))
        .map((position) => position.label)
    );
  }, [group]);

  const handleOpenDialogAddPosition = () => {
    setOpenDialogAddPosition(true);
  };

  const handleCloseDialogAddPosition = () => {
    setOpenDialogAddPosition(false);
  };

  const handleSelectPosition = (position: Position) => {
    const isSelected = positions[group].includes(position);

    if (isSelected) {
      setPositions({
        ...positions,
        [group]: positions[group].filter((pos) => pos !== position),
      });
      setFormValue(
        'positions',
        positions[group].filter((pos) => pos !== position)
      );
      return;
    }

    setPositions({
      ...positions,
      [group]: [...positions[group], position],
    });
    setFormValue('positions', [...positions[group], position]);
  };

  const handleRemovePosition = (position: string) => {
    const tempPositionItems = positionItems[group];

    setPositionItems({
      ...positionItems,
      [group]: tempPositionItems.filter((pos) => pos.value !== position),
    });

    setPositions({
      ...positions,
      [group]: positions[group].filter((pos) => pos !== position),
    });
    setFormValue(
      'positions',
      positions[group].filter((pos) => pos !== position)
    );
  };

  const handleAddPosition = (data: string[]) => {
    const tempPositionItems = positionItems[group];

    const existPositions = data
      .map((d) => tempPositionItems.find((pos) => pos.label === d))
      .filter((pos) => pos);

    if (existPositions.length > 0) {
      handleCloseDialogAddPosition();

      setOpenAlertDialog(true);
      setAlertDialogContent(
        <p>
          The positions{' '}
          <span className="font-bold">
            {existPositions.map((pos) => pos?.label).join(', ')}
          </span>{' '}
          already exists
        </p>
      );

      data = data.filter(
        (d) => !existPositions.find((pos) => pos?.label === d)
      );
    }

    setPositionItems({
      ...positionItems,
      [group]: [
        ...tempPositionItems,
        ...data.map((d) => ({
          label: d,
          value: transformToValue(d),
          removable: true,
        })),
      ],
    });

    const newPositions = [
      ...positions[group],
      ...data.map((d) => transformToValue(d)),
    ];

    setPositions({
      ...positions,
      [group]: newPositions,
    });

    setItems([...items, ...data.map((d) => transformToValue(d))]);

    setFormValue('positions', [
      ...tempPositionItems
        .filter((pos) => positions[group].includes(pos.value))
        .map((pos) => pos.label),
      ...data,
    ]);
    handleCloseDialogAddPosition();
  };

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.indexOf(active.id.toString());
          const newIndex = items.indexOf(over.id.toString());

          const updateArray = arrayMove(items, oldIndex, newIndex);

          setPositions({
            ...positions,
            [group]: updateArray.filter((pos) =>
              positions[group].includes(pos)
            ),
          });

          setFormValue(
            'positions',
            updateArray.filter((pos) => positions[group].includes(pos))
          );

          return updateArray;
        });
      }

      setActiveId(null);
    },
    [positions]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <div>
      <div className="space-y-2">
        <label className="mb-2 text-sm text-[#666]">Positions</label>
        <Stack
          direction="row"
          style={{
            flexWrap: 'wrap',
            gap: '0.5rem',
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              <Grid columns={6}>
                {items.map((id) => {
                  const position = positionItems[group].find(
                    (pos) => pos.value === id
                  );

                  const isSelected = positions[group].includes(
                    transformToValue(id)
                  );

                  if (!position) {
                    return;
                  }

                  return (
                    <SortableItem
                      key={id}
                      id={id}
                      position={position}
                      isSelected={isSelected}
                      handleSelectPosition={handleSelectPosition}
                      handleRemovePosition={handleRemovePosition}
                    />
                  );
                })}
              </Grid>
            </SortableContext>
          </DndContext>
          <Chip
            style={{
              fontSize: '16px',
              backgroundColor: 'white',
            }}
            onClick={handleOpenDialogAddPosition}
            label={
              <div className="flex items-center text-base">
                <IconButton
                  style={{
                    fontSize: '16px',
                    color: '#B8341B',
                    padding: '3px 12px',
                    borderRadius: '16px',
                  }}
                >
                  <Add
                    style={{
                      width: '16px',
                      height: '16px',
                      color: '#B8341B',
                    }}
                  />
                  Add
                </IconButton>
              </div>
            }
          />
        </Stack>
      </div>
      <AlertDialog
        open={openAlertDialog}
        handleOnClose={() => setOpenAlertDialog(false)}
        content={alertDialogContent}
      />
      <AddPositionDialog
        defaultValue={PositionItems[group].map((pos) =>
          pos.label.toLowerCase()
        )}
        open={openDialogAddPosition}
        group={group}
        handlePrimaryAction={handleAddPosition}
        handleSecondaryAction={handleCloseDialogAddPosition}
      />
    </div>
  );
};

export default PositionsSection;
