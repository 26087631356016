import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import photoISBEGroup from './logo_ISBE_Group.png';

function Base({ children }: { children: JSX.Element }) {
  return (
    <Box sx={{ bgcolor: '#F8FAFC' }}>
      <Container maxWidth="lg" sx={{ bgColor: '#00FF00' }}>
        <Grid container>
          <Grid item xs={0} md={6}>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
                pr: 2,
              }}
            >
              <img
                src={photoISBEGroup}
                alt="ISBE Group Logo"
                style={{ maxWidth: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ marginTop: 10 }}>{children}</Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Base;
