import { mainApi } from '../utils/api';

export type GetSentInvitationsInput = {
  orgId: number;
};

const getSentInvitations = ({ orgId }: GetSentInvitationsInput) =>
  mainApi.get(`organizations/${orgId}/sent_sharing_invitations/`);

export { getSentInvitations };
