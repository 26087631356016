import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  activateBaseScenario,
  type ActivateBaseScenarioInput,
  activateScenario,
  type ActivateScenarioInput,
  cloneScenario,
  type CloneScenarioInput,
  createScenario,
  type CreateScenarioInput,
  deleteScenarioById,
  type DeleteScenarioInput,
  getActiveScenarioByDataTableName,
  type GetActiveScenarioByDataTableNameInput,
  getActiveScenarios,
  getBaseScenario,
  getBaseScenarioData,
  type GetBaseScenarioDataInput,
  type GetBaseScenarioInput,
  getScenarioById,
  type GetScenarioByIdInput,
  getScenarioData,
  type GetScenarioDataInput,
  getScenarios,
  getScenariosByDataTableName,
  type GetScenariosByDataTableNameInput,
  type GetScenariosInput,
  getScenarioVectors,
  type GetScenarioVectorsInput,
  updateScenarioById,
  type UpdateScenarioByIdInput,
} from '../api/scenarios';
import {
  type TypeModel,
  type TypeScenario,
  type TypeScenarioVector,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useCreateScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeScenario>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createScenario'],
    mutationFn: async (input: CreateScenarioInput) => {
      const { data }: AxiosResponse<TypeScenario> = await createScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarios'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableName'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useUpdateScenarioById = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeModel>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useUpdateScenarioById'],
    mutationFn: async (input: UpdateScenarioByIdInput) => {
      const { data }: AxiosResponse<TypeModel> =
        await updateScenarioById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarioById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenarios'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useDeleteScenarioById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteScenarioById'],
    mutationFn: async (input: DeleteScenarioInput) => {
      const res: AxiosResponse = await deleteScenarioById(input);

      return res;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableName'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGetScenarioData = (
  input: GetScenarioDataInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, (string | number)[]>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getScenarioData', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Record<string, (string | number)[]>> =
        await getScenarioData(input);

      return data;
    },
    ...opts,
  });

export const useGetBaseScenarioData = (
  input: GetBaseScenarioDataInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, (string | number)[]>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getBaseScenarioData', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Record<string, (string | number)[]>> =
        await getBaseScenarioData(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarioVectors = (
  input: GetScenarioVectorsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeScenarioVector[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getScenarioVectors', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenarioVector[]> =
        await getScenarioVectors(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarios = (
  input: GetScenariosInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenarios', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> = await getScenarios(input);

      return data;
    },
    ...opts,
  });

export const useGetActiveScenarios = (
  input: GetScenariosInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getActiveScenarios', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getActiveScenarios(input);

      return data;
    },
    ...opts,
  });

export const useGetScenariosByDataTableName = (
  input: GetScenariosByDataTableNameInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenariosByDataTableName', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getScenariosByDataTableName(input);

      return data;
    },
    ...opts,
  });

export const useGetActiveScenarioByDataTableName = (
  input: GetActiveScenarioByDataTableNameInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getActiveScenarioByDataTableName', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getActiveScenarioByDataTableName(input);

      return data[0];
    },
    ...opts,
  });

export const useGetScenarioById = (
  input: GetScenarioByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenarioById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario> =
        await getScenarioById(input);

      return data;
    },
    ...opts,
  });

export const useGetBaseScenario = (
  input: GetBaseScenarioInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getBaseScenario', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario> =
        await getBaseScenario(input);

      return data;
    },
    ...opts,
  });

export const useCloneScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeScenario>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['cloneModel'],
    mutationFn: async (input: CloneScenarioInput) => {
      const { data } = await cloneScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarios'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useActivateScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeScenario>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['activateScenario'],
    mutationFn: async (input: ActivateScenarioInput) => {
      const { data } = await activateScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableName'],
      });
      queryClient.refetchQueries({
        queryKey: ['getBaseScenario'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenarioById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useActivateBaseScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeScenario>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['activateBaseScenario'],
    mutationFn: async (input: ActivateBaseScenarioInput) => {
      const { data } = await activateBaseScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableName'],
      });
      queryClient.refetchQueries({
        queryKey: ['getBaseScenario'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenarioById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};
