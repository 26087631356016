import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Link from '../components/Link';
import routes from '../routes';

import Base from './Base';

function LoginByLinkEmailSent() {
  return (
    <Base>
      <div>
        <Typography
          variant="h4"
          sx={{
            mt: 10,
            mb: 2,
          }}
        >
          Done!
        </Typography>
        <Box sx={{ mb: 2 }}>Check your email for the login link.</Box>
        <Box sx={{ marginTop: 10 }}>
          <Button
            href={routes.login}
            component={Link}
            variant="contained"
            fullWidth
          >
            Back to Login
          </Button>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            marginTop: 8,
          }}
        >
          &copy;{new Date().getFullYear()} ISBE Group
        </Box>
      </div>
    </Base>
  );
}

export default LoginByLinkEmailSent;
