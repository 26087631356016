import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

type AlertDialogProps = {
  title?: string;
  open: boolean;
  content: React.ReactNode;
  handleOnClose: () => void;
};

const AlertDialog = ({
  open,
  title = 'Alert',
  handleOnClose,
  content,
}: AlertDialogProps) => (
  <Dialog
    open={open}
    onClose={handleOnClose}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <DialogTitle id="alertTitle" style={{ cursor: 'move' }}>
      <h2 className="font-bold">{title}</h2>
    </DialogTitle>
    <DialogContent id="alertContent">{content}</DialogContent>
    <DialogActions>
      <Button
        autoFocus
        onClick={handleOnClose}
        tabIndex={3}
        style={{
          backgroundColor: '#B8341B',
          color: '#FFF',
        }}
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
