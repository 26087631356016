import React from 'react';

import { Button, Dialog } from '@mui/material';

type IfsNavigateErrorDialogProps = {
  open: boolean;
  handleOnClose: () => void;
};

const IfsNavigateErrorDialog = ({
  open,
  handleOnClose,
}: IfsNavigateErrorDialogProps) => (
  <Dialog
    open={open}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-6 p-6">
      <h2 className="text-xl font-bold">Dataset Import Required!</h2>
      <div className="text-base text-[#666]">
        You cannot view the data in this tab because no dataset has been
        imported. Please import the necessary dataset to proceed.
      </div>
      <div className="flex justify-end gap-3">
        <Button
          onClick={handleOnClose}
          tabIndex={2}
          variant="contained"
          style={{
            backgroundColor: '#B8341B',
            color: '#FFF',
          }}
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
);

export default IfsNavigateErrorDialog;
