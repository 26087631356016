import React, { useEffect, useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { FileUp } from 'lucide-react';

import { API_SERVER_HOST } from '../../common/urls';
import {
  useGetDatasetById,
  useGetDownloadInputFileLink,
} from '../../hooks/useDatasetsHook';
import { useGetIFS } from '../../hooks/useIFSHook';
import {
  useGetRouteParams,
  useHandleImportIFS,
} from '../../hooks/useUtilsHook';
import { type IfsStatus } from '../../types';
import cn from '../../utils/cn';
import LoadingDialog from '../LoadingDialog';
import SuccessDialog from '../SuccessDialog';

import AlertDialog from './AlertDialog';
import DownloadTemplatesDialog from './DownloadTemplatesDialog';

type IFSTableData = {
  id: number;
  inputFile: string | null;
  status: IfsStatus;
  importDate: string | null;
};

const defaultData = [] as IFSTableData[];

const columnHelper = createColumnHelper<IFSTableData>();

const columns = [
  columnHelper.accessor('inputFile', {
    id: 'inputFile',
    cell: (info) => {
      const id = info.row.original.id;

      const { orgId, projectId, datasetId } = useGetRouteParams();

      const status = info.row.original.status;

      const { data: ifsDownloadLink } = useGetDownloadInputFileLink(
        {
          projectId,
          orgId,
          datasetId,
          fileId: id,
        },
        {
          enabled: !!orgId && !!projectId && !!datasetId && !!id,
        }
      );

      const downloadOrigin =
        process.env.NODE_ENV === 'development' ? API_SERVER_HOST : '';

      return (
        <div className="px-6 text-start flex flex-wrap break-all">
          {status === 'imported' && ifsDownloadLink && (
            <a
              href={downloadOrigin + ifsDownloadLink}
              className="text-[#2196F3] underline cursor-pointer"
            >
              {info.getValue()}
            </a>
          )}
          {status === 'not-imported' && <p>{info.getValue()}</p>}
        </div>
      );
    },
    header: () => <div className="flex text-nowrap text-start">Input File</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'imported' | 'fail' | 'not-imported';
        label: string;
      }) => (
        <div className="flex justify-start px-6 py-2">
          <p
            className={cn(
              'w-fit rounded-full capitalize bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#E1FBE7] text-green-600': value === 'imported',
                'text-red-600 bg-[#FFF5F2]': value === 'fail',
                'text-[#666] bg-gray-100': value === 'not-imported',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      switch (status) {
        case 'imported':
          return <StatusLabel value="imported" label="Imported" />;
        case 'fail':
          return <StatusLabel value="fail" label="Fail" />;
        case 'not-imported':
          return <StatusLabel value="not-imported" label="Not Imported" />;
      }
    },
    header: () => <div className="text-start">Status</div>,
  }),
  columnHelper.accessor('importDate', {
    id: 'importDate',
    cell: (info) => (
      <p className="px-6 py-1 text-[#333]">
        {info.getValue()
          ? format(info.getValue() as string, 'Y/MM/dd hh:mm')
          : '--'}
      </p>
    ),
    header: () => (
      <div className="flex w-full shrink text-start">Import Date</div>
    ),
  }),
];

export const ErrorContent = (data: string[]) => (
  <div className="flex flex-col gap-2">
    <p>Invalid files:</p>
    <ul className="list-disc px-8">
      {data?.map((error: string, index: number) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  </div>
);

export const IFSTable = () => {
  const [data, setData] = useState<IFSTableData[]>(defaultData);

  const { projectId, orgId, datasetId } = useGetRouteParams();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId,
      orgId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  useEffect(() => {
    if (!ifs) {
      return;
    }

    setData(
      ifs.files.map((file) => ({
        id: file.input_file,
        inputFile: file.name,
        status: file.is_imported ? 'imported' : 'not-imported',
        importDate: file.imported_at,
        import: null,
      }))
    );
  }, [ifs]);

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="border border-[#E4E7EC] bg-[#F8F8F8]"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={cn(
                    'border border-[#E4E7EC] px-6 py-2 text-sm text-[#4D4D4D]',
                    {
                      'w-[50%]': index === 0,
                    }
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="border border-[#E4E7EC]">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">
            No IFS data available. Please generate and upload your files.
          </p>
        </div>
      )}
    </div>
  );
};

const IFSTableSection = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const { projectId, orgId, datasetId } = useGetRouteParams();

  const { importIfs, isPending, error } = useHandleImportIFS({
    inputFileRef,
    handleError: () => {
      setOpenErrorDialog(true);
    },
  });

  const { data: dataset } = useGetDatasetById(
    {
      orgId,
      datasetId,
      projectId,
    },
    {
      enabled: !!orgId || !!datasetId || !!projectId,
    }
  );

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId,
      orgId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  return (
    <div className="space-y-3">
      <div className="flex justify-between">
        <div className="flex items-center">
          <p className="text-xl font-bold">Upload files</p>
        </div>
        <div className="flex gap-3">
          {dataset && ifs && (
            <DownloadTemplatesDialog dataset={dataset} ifs={ifs} />
          )}
          <LoadingButton
            variant="contained"
            style={{
              display: 'flex',
              color: 'white',
              position: 'relative',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <input
              ref={inputFileRef}
              type="file"
              accept=".xlsx"
              id="import_ifs"
              onChange={importIfs}
              multiple
              className="absolute w-[140px] cursor-pointer opacity-0"
            />
            <FileUp size={20} />
            Upload File(s)
          </LoadingButton>
        </div>
      </div>
      <IFSTable />
      <div className="h-4" />
      <AlertDialog
        title="Error Found: Please Review"
        open={openErrorDialog}
        content={error?.content}
        handleOnClose={() => {
          setOpenErrorDialog(false);
        }}
      />
      <SuccessDialog
        content="Input File Set imported successfully."
        open={openSuccessDialog}
        handleOnClose={() => {
          setOpenSuccessDialog(false);
        }}
      />
      <LoadingDialog title="Uploading" open={isPending} />
    </div>
  );
};

export default IFSTableSection;
